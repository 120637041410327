import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LogoutService } from "@services/logout.service";
import { SessionService } from "@services/session.service";

@Component({
  selector: "app-log-out",
  templateUrl: "./log-out.component.html",
  styleUrls: ["./log-out.component.scss"],
})
export class LogOutComponent implements OnInit {
  constructor(
    private logoutService: LogoutService,
    private router: Router,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {}

  destroyUserSession(): void {
    const navigateToLogin = (): void => {
      this.sessionService.destroySession();
      this.sessionService.destroyUserType();
      this.sessionService.destroyCompany();

      this.router.navigateByUrl("/");
    };

    if (localStorage.getItem("user_migrated")) {
      navigateToLogin();
      return;
    }
    this.logoutService.logOut().subscribe(navigateToLogin, (error) => {
      navigateToLogin();
    });
  }
}
