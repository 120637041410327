import { ICommissionForm } from "@declarations/commission-form";
import { ICommissionPayload } from "@declarations/commission-payload";
import { ICommissionStaggeredResponse } from "@declarations/commission-staggered-response";
import { IPaymentFrequenciesResponse } from "@declarations/payment-frequencies-response";
import { Commission } from "./commission";
import { CommissionStaggered } from "./commission-staggered";

export class PaymentFrequencies {
  active: boolean;
  id: string;
  maxAmountToWithdraw: number;
  name: string;
  unlimitedWithdrawals: boolean;
  withdrawalLimit: number;
  companyMontoSettingsMaxWithdrawalQuantity: number;
  companyMontoSettingsMinWithdrawalQuantity: number;

  commission: Commission;

  constructor(attrs: IPaymentFrequenciesResponse) {
    this.active = attrs.active;
    this.id = attrs.id;
    this.maxAmountToWithdraw = attrs.max_amount_to_withdraw;
    this.name = attrs.name;
    this.unlimitedWithdrawals = attrs.unlimited_withdrawals;
    this.withdrawalLimit = attrs.withdrawal_limit;
    this.companyMontoSettingsMaxWithdrawalQuantity =
      attrs.max_withdrawal_quantity;
    this.companyMontoSettingsMinWithdrawalQuantity =
      attrs.min_withdrawal_quantity;

    if (attrs.commission) this.commission = new Commission(attrs.commission);
    else this.commission = null;
  }
}
