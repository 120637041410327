import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";

import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private cookies: CookieService) {
    /** Do nothing */
  }

  getSession(): string {
    return this.cookies.get(environment.COOKIE.DASHBOARD);
  }

  setSession(token: string): void {
    this.cookies.put(environment.COOKIE.DASHBOARD, token);
  }

  getCompany(): string {
    return this.cookies.get(environment.COOKIE.CURRENT_USER_COMPANY);
  }

  setCompany(company: string): void {
    this.cookies.put(environment.COOKIE.CURRENT_USER_COMPANY, company);
  }

  destroyCompany(): void {
    this.cookies.remove(environment.COOKIE.CURRENT_USER_COMPANY);
  }

  destroySession(): void {
    this.cookies.remove(environment.COOKIE.DASHBOARD);
  }

  getUserRoles(): Array<string> {
    return this.cookies.get(environment.COOKIE.CURRENT_USER_COOKIE).split(",");
  }

  /** DEPRECATE */
  getUserType() {
    return this.cookies.get(environment.COOKIE.CURRENT_USER_COOKIE);
  }

  setUserRoles(roles: Array<string>): void {
    this.cookies.put(environment.COOKIE.CURRENT_USER_COOKIE, roles.join(","));
  }

  /** DEPRECATE */
  setUserType(role: string): void {
    this.cookies.put(environment.COOKIE.CURRENT_USER_COOKIE, role);
  }

  /** DEPRECATE */
  destroyUserType() {
    this.cookies.remove(environment.COOKIE.CURRENT_USER_COOKIE);
  }

  destroyUserRoles(): void {
    this.cookies.remove(environment.COOKIE.CURRENT_USER_COOKIE);
  }
}
