import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IErrorResponse } from "@declarations/error-response";
import { CompanyDetail } from "@models/company-detail";
import { ImportUsersUpdatePayload } from "@payloads/import-users-update.payload";
import { CollaboratorService } from "@services/collaborators.service";
import { CompaniesService } from "@services/companies.service";
import { Toastr } from "@services/toastr.service";
import { UpdateUsersService } from "@services/update-users.service";
import { saveAs } from "file-saver";

@Component({
  selector: "app-update-users",
  templateUrl: "./update-users.component.html",
  styleUrls: ["./update-users.component.scss"],
})
export class UpdateUsersComponent {
  @Output() onToggleSidebar: EventEmitter<boolean> = new EventEmitter();
  @Output() onToggleSidebarReset: EventEmitter<boolean> = new EventEmitter();

  updateCSVForm: FormGroup = this.fb.group({
    csvFile: [null, Validators.required],
  });
  responseError = null;
  fileChanges = false;
  isLoading: boolean;
  company: CompanyDetail;
  companyId: string;
  url: string;
  constructor(
    private fb: FormBuilder,
    private updateUsersService: UpdateUsersService,
    private toastr: Toastr,
    private collaboratorService: CollaboratorService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompaniesService,
    private router: Router
  ) {
    this.url = this.router.url;
    this.activatedRoute.pathFromRoot[3].url.subscribe((val) => {
      this.companyId = val[0]?.path;
    });
  }

  emitToggleSidebar(reload?: boolean): void {
    this.onToggleSidebar.emit(reload);
  }

  emitToggleSidebarReset(reload?: boolean) {
    this.onToggleSidebarReset.emit(reload);
  }

  getCompany(id: string) {
    this.companyService.getCompany(id).subscribe((response: CompanyDetail) => {
      this.company = response;
    });
  }

  updateCSV(updateCSVForm: FormGroup) {
    this.isLoading = true;

    if (updateCSVForm.invalid) {
      this.responseError = true;
      this.toastr.single_error(
        "Completa la información requerida del fomulario"
      );
      return (this.isLoading = false);
    }

    const payload = new ImportUsersUpdatePayload(updateCSVForm);

    const importUpdateUsers = (): void => {
      setTimeout(() => {
        this.isLoading = false;
        this.responseError = false;
        this.toastr.success("Se han actualizado los usuarios");
        this.emitToggleSidebarReset(true);
      }, 500);
    };
    const handleError = (response: IErrorResponse): void => {
      this.isLoading = false;
      this.responseError = true;
      this.emitToggleSidebarReset(true);
      this.toastr.error(response);
    };

    if (this.url === "/u/collaborators") {
      this.updateUsersService
        .updateUsers(payload.toFormDataObject())
        .subscribe(importUpdateUsers, handleError);
    } else {
      this.updateUsersService
        .superUpdateUsers(payload.toFormDataObject(), this.companyId)
        .subscribe(importUpdateUsers, handleError);
    }
  }

  deleteFile(): void {
    this.updateCSVForm.patchValue({
      csvFile: null,
    });
  }

  downloadTemplate(): void {
    this.collaboratorService
      .downloadTemplateCollaborators()
      .subscribe((data: any) => {
        const blob = new Blob([data], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        const fileName = "Plantilla Colaboradores.xlsx";
        saveAs(blob, fileName);
      });
  }
}
