import { Component, OnInit, ViewChild } from "@angular/core";
import { IPendingInvoiceCollectionResponse } from "@declarations/pending-invoice-collection-response";
import { OutstandingBalanceFilterByCompany } from "@models/outstanding-balance-filter-by-company";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-outstanding-balance-modal",
  templateUrl: "./outstanding-balance-modal.component.html",
})
export class OutstandingBalanceModalComponent implements OnInit {
  @ViewChild("viewOutstandingBalance")
  viewOutstandingBalance;

  pendingInvoices: IPendingInvoiceCollectionResponse;

  outstandingBalance: Array<OutstandingBalanceFilterByCompany> = [];

  totalToPayPending: number = 0;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  async openViewOutstandingBalanceModal(pendingInvoices, totalToPayPending) {
    this.pendingInvoices = pendingInvoices;
    this.totalToPayPending = totalToPayPending;
    this.outstandingBalance = [];
    await this.filterByCompany();
    this.modalService.open(this.viewOutstandingBalance, {
      ...{
        keyboard: false,
        ariaLabelledBy: "ModalOutstandingBalance",
        centered: true,
      },
    });
  }

  filterByCompany() {
    this.pendingInvoices.items.forEach((element, index) => {
      const isInArray = this.outstandingBalance
        .map(function (e) {
          return e["company"];
        })
        .indexOf(element.companyName);
      if (isInArray == -1) {
        this.outstandingBalance.push({
          company: element.companyName,
          totalAmount: element.totalToPay + element.interestWithTax,
          item: [
            {
              amountPaid: element.amountPaid,
              companyName: element.companyName,
              createdAt: element.createdAt,
              daysLate: element.daysLate,
              endDate: element.endDate,
              folio: element.folio,
              generatedInterest: element.generatedInterest,
              id: element.id,
              paymentDate: element.paymentDate,
              paymentStatus: element.paymentStatus,
              payrollPeriod: element.payrollPeriod,
              startDate: element.startDate,
              totalAmount: element.totalAmount,
              totalCommission: element.totalCommission,
              totalInvoicesAmount: element.totalInvoicesAmount,
              totalToPay: element.totalToPay,
              interestWithTax: element.interestWithTax,
            },
          ],
        });
      } else {
        (this.outstandingBalance[isInArray].totalAmount =
          this.outstandingBalance[isInArray].totalAmount +
          element.totalToPay +
          element.interestWithTax),
          this.outstandingBalance[isInArray].item.push({
            amountPaid: element.amountPaid,
            companyName: element.companyName,
            createdAt: element.createdAt,
            daysLate: element.daysLate,
            endDate: element.endDate,
            folio: element.folio,
            generatedInterest: element.generatedInterest,
            id: element.id,
            paymentDate: element.paymentDate,
            paymentStatus: element.paymentStatus,
            payrollPeriod: element.payrollPeriod,
            startDate: element.startDate,
            totalAmount: element.totalAmount,
            totalCommission: element.totalCommission,
            totalInvoicesAmount: element.totalInvoicesAmount,
            totalToPay: element.totalToPay,
            interestWithTax: element.interestWithTax,
          });
      }
    });
  }

  closeOutstandingBalanceModal() {
    this.modalService.dismissAll();
  }
}
