import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { SessionService } from "@services/session.service";
import { CookieService } from "ngx-cookie";

@Injectable({
  providedIn: "root",
})
export class AuthGuards implements CanActivate {
  constructor(
    public router: Router,
    private cookies: CookieService,
    private sessionService: SessionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const token = this.sessionService.getSession();
    if (token) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
