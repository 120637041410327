import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class PageStateService {
  animate: boolean = true;

  autoFocus: boolean = true;

  closeOnClickBackdrop: boolean = true;

  closeOnClickOutside: boolean = true;

  dock: boolean = false;

  isLoading: boolean = false;

  keyClose: boolean = false;

  meridian: boolean = false;

  opened: boolean = false;

  showBackdrop: boolean = true;

  spinners: boolean = false;

  trapFocus: boolean = true;

  responseError: boolean = false;

  constructor(private httpClient: HttpClient) {
    /** Do nothing */
  }
}
