import { RouteInfo } from "../sidebar.metadata";
import { Injectable } from "@angular/core";
import { PermissionsService } from "@services/permissions.service";

@Injectable()
export class SidebarRoutesService {
  private ROUTES: RouteInfo[] = [
    {
      path: "/u/home",
      title: "Inicio",
      icon: "ic_home_white.svg",
      class: "",
      onboardingSelector: "home-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible:
        this.permissionsService.isAdminVisible() ||
        this.permissionsService.isSuperadminVisible(),
    },
    {
      path: "/u/companies",
      title: "Empresas",
      icon: "ic_briefcase-outline_white.svg",
      class: "",
      onboardingSelector: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isSuperadminVisible(),
    },
    {
      path: "/u/withdrawals-companies",
      title: "Retiros",
      icon: "ic_card-outline_white.svg",
      class: "",
      onboardingSelector: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isSuperadminVisible(),
    },
    {
      path: "/u/charges",
      title: "Cobranza",
      icon: "ic_currency_white.svg",
      class: "",
      onboardingSelector: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isSuperadminVisible(),
    },
    {
      path: "/u/my-company",
      title: "Mi empresa",
      icon: "ic_briefcase-outline_white.svg",
      class: "",
      onboardingSelector: "my-company-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
    {
      path: "/u/payroll-periods",
      title: "Periodos de pago",
      icon: "ic_calendar-outline_white.svg",
      class: "",
      onboardingSelector: "payroll-periods-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
    {
      path: "/u/groups",
      title: "Grupos",
      icon: "ic_folder-outline_white.svg",
      class: "",
      onboardingSelector: "groups-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
    {
      path: "/u/collaborators",
      title: "Colaboradores",
      icon: "ic_people-outline_white.svg",
      class: "",
      onboardingSelector: "collaborators-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
    {
      path: "/u/withdrawals-collaborators",
      title: "Retiros",
      icon: "ic_card-outline_white.svg",
      class: "",
      onboardingSelector: "withdrawals-collaborators-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
    {
      path: "/u/deductions",
      title: "Deducciones",
      icon: "ic_remove-circle-outline_white.svg",
      class: "",
      onboardingSelector: "deductions-main-sidebar",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
    {
      path: "/u/about-monto",
      title: "Acerca de Monto",
      icon: "ic_help-circle-outline_white.svg",
      class: "",
      onboardingSelector: "",
      badge: "",
      badgeClass: "bottom",
      isExternalLink: false,
      submenu: [],
      isVisible: this.permissionsService.isAdminVisible(),
    },
  ];

  constructor(private permissionsService: PermissionsService) {}

  getPermittedRoutes(): RouteInfo[] {
    return this.ROUTES.filter((route: RouteInfo) => route.isVisible);
  }
}
