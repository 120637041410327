import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es-MX";
import { LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

registerLocaleData(localeEs, "es-MX");

/** TODO: Add guards to routes */
const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/content-layout-page/unauth-pages.module").then(
        (m) => m.UnauthPagesModule
      ),
  },
  {
    path: "u",
    component: FullLayoutComponent,
    loadChildren: () =>
      import("./pages/full-layout-page/auth-pages.module").then(
        (m) => m.AuthPagesModule
      ),
  },
  // TODO Move the components of this route to UnauthPagesModule when BE
  // change the new route that arrive to the emal
  {
    path: "unauth",
    loadChildren: () =>
      import("./pages/reset-password-page/reset-password-pages.module").then(
        (m) => m.ResetPasswordPagesModule
      ),
  },
  {
    path: "unauth/token/:token",
    loadChildren: () =>
      import("./pages/check-token/check-token-routing.module").then(
        (m) => m.CheckTokenRoutingModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" }, //replace "en-US" with your locale
    //otherProviders...
  ],
})
export class AppRoutingModule {}
