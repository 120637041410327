import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { NgbModal, NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Commission } from "@models/commission";

@Component({
  selector: "app-view-staggered-commission-modal",
  templateUrl: "./view-staggered-commission-modal.component.html",
})
export class ViewStaggeredCommissionModalComponent implements OnInit {
  @ViewChild("viewStaggeredCommission")
  viewStaggeredCommission;

  @Input() staggeredCommission: Commission;

  defaultPanelId: string = "panel2";

  lastPanelId: string = null;

  shouldHideCloseButton = false;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    /** No content */
  }

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  openViewStaggeredCommissionModal(options?): void {
    this.modalService.open(this.viewStaggeredCommission, {
      ...options,
      ...{
        keyboard: false,
        ariaLabelledBy: "ModalViewStaggeredCommission",
        centered: true,
      },
    });
  }
}
