import { IIndustrySubLevelResponse } from "@declarations/industry-sub-level-response";

export class IndustrySubLevel {
  id: string;
  name: string;

  constructor(companyAttrs: IIndustrySubLevelResponse) {
    this.id = companyAttrs.id;
    this.name = companyAttrs.name;
  }
}
