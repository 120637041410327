import { FormGroup } from "@angular/forms";
import { IImportCollaboratorsPayload } from "@declarations/import-collaborators-payload";
import { IImportUsersUpdateForm } from "@declarations/import-users-update-form";

export class ImportUsersUpdatePayload {
  private pImportUsersUpdate: FormGroup;
  private pImportUsersUpdateData = new FormData();

  constructor(importUsersUpdateForm: FormGroup) {
    this.pImportUsersUpdate = importUsersUpdateForm;
  }

  get usersUpdateFormValue(): IImportUsersUpdateForm {
    return this.pImportUsersUpdate.value;
  }

  toJSONObject(): IImportCollaboratorsPayload {
    return {
      file: this.usersUpdateFormValue.csvFile,
    };
  }

  toFormDataObject(): FormData {
    this.pImportUsersUpdateData.append(
      "file",
      this.usersUpdateFormValue.csvFile
    );

    return this.pImportUsersUpdateData;
  }
}
