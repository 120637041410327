import { Injectable, NgZone } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
declare var Pace: any;
@Injectable({ providedIn: "root" })
export class ApiVersionInterceptor implements HttpInterceptor {
  constructor(private _ngZone: NgZone) {}

  intercept(
    req: HttpRequest<string>,
    next: HttpHandler
  ): Observable<HttpEvent<string>> {
    const apiVersionHeaders = {
      headers: req.headers.append("Accept", "application/vnd.mto.v1+json"),
    };
    const request = req.clone(apiVersionHeaders);
    setTimeout(() => {
      this._ngZone.runOutsideAngular(() => {
        Pace.restart();
      });
    });
    return next.handle(request);
  }
}
