<ng-template #viewDeductionsDetail>
  <div
    *ngIf="pendingInvoices && currentView == 2"
    id="section__view-deductions-detail-modal"
  >
    <div class="modal-header">
      <div class="dismiss">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeDeductionsDetailModal()"
        >
          <img src="assets/img/application/icons/ic_close_black.svg" />
        </button>
      </div>
      <div class="title">
        <h4 class="h4-extend-small">Total a pagar</h4>
        <h4 class="h4-extend-small strong">
          {{ totalToPay | currency: "MXN":"symbol-narrow" }}
        </h4>
      </div>
    </div>
    <div class="modal-content">
      <div *ngIf="vigentPendingInvoices" class="current-periods">
        <p class="soft">
          Periodos por pagar
        </p>
        <ngb-accordion
          [destroyOnHide]="false"
          [closeOthers]="true"
          activeIds="static-1"
          #shadow
        >
          <ngb-panel *ngFor="let item of vigentPendingInvoices">
            <ng-template ngbPanelTitle>
              <span>
                <div class="delayed-periods-title">
                  <div
                    *ngIf="item.paymentStatus == 'unpaid'"
                    class="oval--fail"
                  ></div>
                  <h5 class="strong">
                    {{ item.payrollPeriod }}
                    ({{ item.startDate | date: "d" }} -
                    {{ item.endDate | date: "d" }}
                    de
                    {{ item.endDate | date: "LLLL, yyyy" }})
                  </h5>
                </div>
                <h4 class="strong">
                  {{ item.totalToPay | currency: "MXN":"symbol-narrow" }}
                </h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="current-periods-content">
                <h5>Fecha de pago</h5>
                <h5>
                  {{ item.paymentDate | date: "d" }}
                  de
                  {{ item.paymentDate | date: "LLLL" }}
                </h5>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div *ngIf="delayPendingInvoices" class="current-periods">
        <p class="soft">
          Pagos atrasados
        </p>
        <ngb-accordion
          [destroyOnHide]="false"
          [closeOthers]="true"
          activeIds="static-1"
          #shadow
        >
          <ngb-panel *ngFor="let item of delayPendingInvoices">
            <ng-template ngbPanelTitle>
              <span>
                <div class="delayed-periods-title">
                  <div
                    *ngIf="item.paymentStatus == 'unpaid'"
                    class="oval--fail"
                  ></div>
                  <h5 class="strong">
                    {{ item.payrollPeriod }}
                    ({{ item.startDate | date: "d" }} -
                    {{ item.endDate | date: "d" }}
                    de
                    {{ item.endDate | date: "LLLL, yyyy" }})
                  </h5>
                </div>
                <h4 class="strong">
                  {{
                    item.totalToPay + item.interestWithTax
                      | currency: "MXN":"symbol-narrow"
                  }}
                </h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="delayed-periods-content">
                <h5>Fecha de pago</h5>
                <h5>
                  {{ item.paymentDate | date: "d" }}
                  de
                  {{ item.paymentDate | date: "LLLL" }}
                </h5>
              </div>
              <div class="delayed-periods-content">
                <h5>Días atrasados</h5>
                <h5>{{ item.daysLate }} días</h5>
              </div>
              <div class="delayed-periods-content">
                <h5>Total retirado</h5>
                <h5>
                  {{ item.totalToPay | currency: "MXN":"symbol-narrow" }}
                </h5>
              </div>
              <div class="delayed-periods-content">
                <h5>Intereses generados</h5>
                <h5>
                  {{ item.generatedInterest | currency: "MXN":"symbol-narrow" }}
                </h5>
              </div>
              <div class="delayed-periods-content">
                <h5>IVA</h5>
                <h5>
                  {{
                    item.interestWithTax - item.generatedInterest
                      | currency: "MXN":"symbol-narrow"
                  }}
                </h5>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <div
    *ngIf="cyclesByPeriod && currentView == 1"
    id="section__view-deductions-detail-modal"
  >
    <div class="modal-header">
      <div class="dismiss">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeDeductionsDetailModal()"
        >
          <img src="assets/img/application/icons/ic_close_black.svg" />
        </button>
      </div>
      <div class="title">
        <h4 class="h4-extend-small">Próximas fechas de pago</h4>
        <h4 class="h4-extend-small strong"></h4>
      </div>
    </div>
    <div class="modal-content">
      <div class="current-periods">
        <p class="soft">
          Fechas de pago
        </p>
        <ngb-accordion
          [destroyOnHide]="false"
          [closeOthers]="true"
          activeIds="static-1"
          #shadow
        >
          <ngb-panel *ngFor="let item of cyclesByPeriod">
            <ng-template ngbPanelTitle>
              <span>
                <h5 class="strong">
                  {{ item.periodName }}
                </h5>
                <h4 class="strong"></h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div
                *ngFor="let cycle of item.cycles"
                class="current-periods-content"
              >
                <h5>Fecha de pago</h5>
                <h5>
                  {{ cycle.paymentDate | date: "d" }}
                  de
                  {{ cycle.paymentDate | date: "LLLL" }}
                </h5>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</ng-template>
