<ng-template #viewOutstandingBalance>
  <div id="section__outstanding-balance-modal">
    <div class="modal-header">
      <div class="dismiss">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeOutstandingBalanceModal()"
        >
          <img src="assets/img/application/icons/ic_close_black.svg" />
        </button>
      </div>
      <div class="title">
        <h4 class="h4-extend-small">Saldo pendiente</h4>
        <h4 class="h4-extend-small strong">
          {{ totalToPayPending | currency: "MXN":"symbol-narrow" }}
        </h4>
      </div>
    </div>
    <div class="modal-content">
      <div class="company-balance">
        <ngb-accordion
          [destroyOnHide]="false"
          [closeOthers]="true"
          activeIds="static-1"
          #shadow
        >
          <ngb-panel *ngFor="let company of outstandingBalance">
            <ng-template ngbPanelTitle>
              <span>
                <div class="delayed-balance-title">
                  <h4 class="h4-extend-small">
                    {{ company.company }}
                  </h4>
                </div>
                <h4 class="strong">
                  {{ company.totalAmount | currency: "MXN":"symbol-narrow" }}
                </h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="current-balance">
                <!--<p class="soft">
                  Periodos atrasados
                </p>-->
                <ngb-accordion
                  [destroyOnHide]="false"
                  [closeOthers]="true"
                  activeIds="static-1"
                  #shadow
                >
                  <ngb-panel *ngFor="let dates of company.item">
                    <ng-template ngbPanelTitle>
                      <span>
                        <div class="delayed-balance-title">
                          <div
                            class="oval--fail"
                            *ngIf="dates.paymentStatus == 'unpaid'"
                          ></div>
                          <h5 class="strong">
                            {{ dates.payrollPeriod }}
                            ({{ dates.startDate | date: "d" }}
                            -
                            {{ dates.endDate | date: "d" }}
                            de
                            {{ dates.endDate | date: "LLLL, yyyy" }})
                          </h5>
                        </div>
                        <h4 class="strong">
                          {{
                            dates.totalToPay + dates.interestWithTax
                              | currency: "MXN":"symbol-narrow"
                          }}
                        </h4>
                      </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="content-tab">
                        <div class="delayed-balance-content">
                          <h5>Fecha de pago</h5>
                          <h5>
                            {{ dates.paymentDate | date: "d" }}
                            de
                            {{ dates.paymentDate | date: "LLLL" }}
                          </h5>
                        </div>
                        <div class="delayed-balance-content">
                          <h5>Días atrasados</h5>
                          <h5 *ngIf="dates.daysLate == 1; else multipleDays">
                            {{ dates.daysLate }} día
                          </h5>
                          <ng-template #multipleDays>
                            <h5>{{ dates.daysLate }} días</h5>
                          </ng-template>
                        </div>
                        <div class="delayed-balance-content">
                          <h5>Total retirado</h5>
                          <h5>
                            {{
                              dates.totalToPay | currency: "MXN":"symbol-narrow"
                            }}
                          </h5>
                        </div>
                        <div class="delayed-balance-content">
                          <h5>Intereses generados</h5>
                          <h5>
                            {{
                              dates.generatedInterest
                                | currency: "MXN":"symbol-narrow"
                            }}
                          </h5>
                        </div>
                        <div class="delayed-balance-content">
                          <h5>IVA</h5>
                          <h5>
                            {{
                              dates.interestWithTax - dates.generatedInterest
                                | currency: "MXN":"symbol-narrow"
                            }}
                          </h5>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</ng-template>
