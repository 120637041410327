import { IBankListResponse } from "@declarations/bank-list-response";

export class BankList {
  id: string;
  name: string;

  constructor(bankAttrs: IBankListResponse) {
    this.id = bankAttrs.id;
    this.name = bankAttrs.name;
  }
}
