import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class ClientTypeInterceptor implements HttpInterceptor {
  currentRoute: string;

  intercept(
    req: HttpRequest<string>,
    next: HttpHandler
  ): Observable<HttpEvent<string>> {
    const clientTypeHeaders = {
      headers: req.headers.set("X-Client", "admin"),
    };
    const request = req.clone(clientTypeHeaders);

    return next.handle(request);
  }
}
