import { ICommissionResponse } from "@declarations/commission-response";
import { CommissionStaggered } from "./commission-staggered";
import { ICommissionStaggeredResponse } from "@declarations/commission-staggered-response";

export class Commission {
  commissionType: number;
  commissionValue: number;
  commissionValueType: number;
  id: string;
  includeIva: boolean;
  staggeredCommissionSteps: Array<CommissionStaggered>;
  valueCommissionIva: string;

  constructor(attrs: ICommissionResponse) {
    this.commissionType = attrs.commission_type;
    this.commissionValue = attrs.commission_value;
    this.commissionValueType = attrs.commission_value_type;
    this.id = attrs.id;
    this.includeIva = attrs.include_iva;
    this.staggeredCommissionSteps = this.setStaggeredCommissionSteps(
      attrs.staggered_commission_steps
    );
  }

  private setStaggeredCommissionSteps(
    commissionStaggered: Array<ICommissionStaggeredResponse>
  ) {
    let commission: Array<CommissionStaggered> = [];

    if (!commissionStaggered) {
      return commission;
    }

    commissionStaggered.forEach((response: ICommissionStaggeredResponse) => {
      const presponse = new CommissionStaggered(response);
      commission.push(presponse);
    });

    return commission;
  }
}
