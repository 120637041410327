import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-password-updated-successfully",
  templateUrl: "./password-updated-successfully.component.html",
  styleUrls: ["./password-updated-successfully.component.scss"],
})
export class PasswordUpdatedSuccessfullyComponent implements OnInit {
  constructor() {
    /** No content */
  }

  ngOnInit(): void {
    /** No content */
  }
}
