<div class="table-card">
  <div *ngIf="optionsAvailable" class="table-card__options">
    <div class="option__left">
      <form
        *ngIf="tableOptions"
        [formGroup]="formOptions"
        class="companies-header"
      >
        <div *ngFor="let form of tableOptions.inputs">
          <div [ngSwitch]="form.type">
            <div *ngSwitchCase="'select'">
              <app-input
                type="select"
                [id]="form.id"
                [collection]="form.collection"
                [customClass]="'input-card'"
                [parentForm]="formOptions"
                (onSelectChange)="emitSelectOptionsOnChange($event)"
                [labelName]="form.labelName"
                [placeholder]="form.placeholder"
              >
              </app-input>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="options__right">
      <form
        *ngIf="tableSearchBar"
        [formGroup]="formSearchBar"
        class="companies-header"
      >
        <div *ngFor="let forms of tableSearchBar.inputs">
          <div [ngSwitch]="forms.type">
            <div *ngSwitchCase="'search'">
              <app-input
                type="text"
                [id]="forms.id"
                [customClass]="'input-card'"
                [parentForm]="formSearchBar"
                [labelName]="forms.labelName"
                [placeholder]="forms.placeholder"
                (input)="emitSelectSearchBarOnChange($event)"
              >
              </app-input>
            </div>
          </div>
        </div>
      </form>

      <form
        *ngIf="tableSearchBarEmployeeInfo"
        [formGroup]="formSearchBarEmployeeInfo"
        class="companies-header"
      >
        <div *ngFor="let item of tableSearchBarEmployeeInfo.inputs">
          <div [ngSwitch]="item.type">
            <div *ngSwitchCase="'search'">
              <app-input
                type="text"
                [id]="item.id"
                [customClass]="'input-card'"
                [parentForm]="formSearchBarEmployeeInfo"
                [labelName]="item.labelName"
                [placeholder]="item.placeholder"
                (input)="emitSelectSearchBarEmployeeInfoOnChange($event)"
              >
              </app-input>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table-card__header">
    <div
      *ngFor="let header of tableHeaders['columns']; let i = index"
      class="title-header"
      [ngClass]="{ active: !header['is_sorting'] }"
    >
      <input
        *ngIf="header['type'] == 'selectCheckbox'"
        class="custom-ckeckbox"
        type="checkbox"
        name="allitems"
        [checked]="isAllChecked()"
        (change)="checkAll($event)"
      />
      <div
        class="header-icon"
        *ngIf="header['type'] == 'pennyIcon'; else titleText"
      >
        <img
          tooltipClass="penny-check"
          ngbTooltip="Validación Cuenta"
          src="assets/img/application/icons/ic_user_black.svg"
          alt=""
        />
      </div>
      <ng-template #titleText>
        <p
          [disableTooltip]="!header['tooltipMessage']"
          [ngbTooltip]="header['tooltipMessage']"
        >
          {{ header["header"] }}
        </p>
      </ng-template>
      <ul *ngIf="header['sort_type'] && !isSorting" class="sort">
        <li ngbDropdown>
          <a id="sorting" ngbDropdownToggle>
            <img src="assets/img/application/icons/ic_sort_grey.svg" alt="" />
            <div ngbDropdownMenu aria-labelledby="sorting" class="sorting">
              <a
                *ngIf="header['sort_type'] == 'alphabetically'"
                (click)="enableSorting(i)"
                class="dropdown-item"
              >
                <img
                  src="assets/img/application/icons/ic_sort-alph_grey.svg"
                  alt=""
                />
                <span>Alfabéticamente</span>
              </a>
              <a
                *ngIf="header['sort_type'] == 'quantity'"
                (click)="enableSorting(i)"
                class="dropdown-item"
              >
                <img
                  src="assets/img/application/icons/ic_sort-number_grey.svg"
                  alt=""
                />
                <span>Cantidad</span>
              </a>
              <a
                *ngIf="header['sort_type'] == 'numeric'"
                (click)="enableSorting(i)"
                class="dropdown-item"
              >
                <img
                  src="assets/img/application/icons/ic_sort-number_grey.svg"
                  alt=""
                />
                <span>Numeración</span>
              </a>
              <a
                *ngIf="header['sort_type'] == 'antiquity'"
                (click)="enableSorting(i)"
                class="dropdown-item"
              >
                <img
                  src="assets/img/application/icons/ic_calendar_grey.svg"
                  alt=""
                />
                <span>Por fecha</span>
              </a>
            </div>
          </a>
        </li>
      </ul>
      <div
        (click)="toggleAscDescSorting(i)"
        *ngIf="header['is_sorting'] && header['sort_type']"
        class="sort-active"
      >
        <div (click)="disableSorting(i)" class="close-sort">
          <img
            src="assets/img/application/icons/ic_sort-close_grey.svg"
            alt=""
          />
        </div>
        <img
          *ngIf="header['sort_type'] == 'alphabetically'"
          src="assets/img/application/icons/ic_sort-alph_white.svg"
          alt=""
          class="sort-active-icon"
        />
        <img
          *ngIf="header['sort_type'] == 'quantity'"
          src="assets/img/application/icons/ic_sort-number_white.svg"
          alt=""
          class="sort-active-icon"
        />
        <img
          *ngIf="header['sort_type'] == 'numeric'"
          src="assets/img/application/icons/ic_sort-number_white.svg"
          alt=""
          class="sort-active-icon"
        />
        <img
          *ngIf="header['sort_type'] == 'antiquity'"
          src="assets/img/application/icons/ic_calendar-outline_white.svg"
          alt=""
          class="sort-active-icon"
        />
        <img
          *ngIf="!isSortingAsc"
          src="assets/img/application/icons/ic_arrow-down_white.svg"
          alt=""
        />
        <img
          *ngIf="isSortingAsc"
          src="assets/img/application/icons/ic_arrow-top_white.svg"
          alt=""
        />
      </div>
    </div>
    <h5></h5>
  </div>
  <div [class]="title" class="table-card__content">
    <table *ngIf="items">
      <tbody *ngIf="items.length">
        <tr class="table-card__row" *ngFor="let item of items">
          <td *ngFor="let header of tableHeaders['columns']">
            <input
              *ngIf="
                header['type'] == 'selectCheckbox' &&
                !['pending_admin_validation'].includes(item['activeStatus'])
              "
              class="custom-ckeckbox"
              type="checkbox"
              name="allitems"
              value="{{ item['id'] }}"
              [(ngModel)]="item['checkboxValue']"
              (change)="selectCheckbox(item)"
            />
            <div
              [class]="clickRedirect ? '--clickable' : ''"
              [ngClass]="{
                noClickable:
                  item[header['clickableField']] == header['equalValue']
              }"
              (click)="notifyRowClicked(item)"
            >
              <div
                *ngIf="
                  (header['type'] == 'pennyIcon' &&
                    item[header['extraKey']] == 'complete') ||
                  (header['type'] == 'pennyIcon' &&
                    item[header['extraKey']] == 'invited') ||
                  (header['type'] == 'pennyIcon' &&
                    item[header['extraKey']] == 'created')
                "
              >
                <div
                  *ngIf="
                    header['type'] == 'pennyIcon' && item[header['key']] == true
                  "
                >
                  <img
                    class="penny-check-img"
                    tooltipClass="penny-check"
                    src="assets/img/application/icons/ic_penny-check_dark-blue.svg"
                    alt=""
                  />
                </div>

                <div
                  *ngIf="
                    header['type'] == 'pennyIcon' &&
                    item[header['key']] == false
                  "
                >
                  <img
                    tooltipClass="penny-check"
                    [ngbTooltip]="item[header['tooltipMessage']]"
                    src="assets/img/application/icons/ic_person-delete_red.svg"
                    alt=""
                  />
                </div>
              </div>

              <div *ngIf="header['type'] == 'textUpperCase'">
                <p>{{ item[header["key"]] | uppercase }}</p>
              </div>
              <div *ngIf="header['type'] == 'noTitleCase'">
                <p>{{ item[header["key"]] }}</p>
              </div>
              <div *ngIf="header['type'] == 'text'">
                <p *ngIf="item['role'] != 'admin' || header['key'] != 'name'">
                  {{ item[header["key"]] | titlecase }}
                </p>
              </div>
              <div *ngIf="header['type'] == 'paymentByWithdrawal'">
                <p *ngIf="item[header['key']] == true">
                  Empresa
                </p>
                <p *ngIf="item[header['key']] == false">
                  Colaborador
                </p>
              </div>
              <div
                *ngIf="
                  header['type'] == 'collaboratorsName' &&
                  item['role'] != 'admin'
                "
              >
                <p>{{ item[header["key"]] | titlecase }}</p>
              </div>
              <div
                *ngIf="
                  header['type'] == 'collaboratorsName' &&
                  item['role'] == 'admin'
                "
              >
                <p class="statustype--admin">
                  {{ item[header["key"]] | titlecase }}
                </p>
              </div>
              <div *ngIf="header['type'] == 'percentage'">
                <p>{{ item[header["key"]] }}%</p>
              </div>
              <div *ngIf="header['type'] == 'money'">
                <p>
                  {{ item[header["key"]] | currency: "MXN":"symbol-narrow" }}
                </p>
              </div>
              <div *ngIf="header['type'] == 'phone'">
                <p>{{ item[header["key"]] | phone }}</p>
              </div>
              <div *ngIf="header['type'] == 'dateRange'">
                <p *ngIf="item[header['key']]">
                  {{ item[header["key"]] | date: "d" }}
                  -
                  {{ item[header["extraKey1"]] | date: "d" }}
                  de
                  {{ item[header["extraKey1"]] | date: "LLLL, yyyy" }}
                </p>
              </div>
              <div *ngIf="header['type'] == 'date'">
                <p *ngIf="item[header['key']]">
                  {{ item[header["key"]] | date: "d" }}
                  de
                  {{ item[header["key"]] | date: "LLLL, yyyy" }}
                </p>
              </div>
              <div *ngIf="header['type'] == 'datetime'">
                <p *ngIf="item[header['key']]">
                  {{ item[header["key"]] | date: "d" }}
                  de
                  {{ item[header["key"]] | date: "LLLL, yyyy" }}
                </p>
              </div>
              <div *ngIf="header['type'] == 'registerStatusCompany'">
                <div *ngIf="item[header['key']] === 'waiting_for_approval'">
                  <p class="statustype--waiting-for-approval">
                    Por aprobar
                  </p>
                </div>
                <div *ngIf="item[header['key']] === 'invited'">
                  <p>
                    Invitado
                  </p>
                </div>
                <div *ngIf="item[header['key']] === 'account_created'">
                  <p>
                    Cuenta creada
                  </p>
                </div>
                <div *ngIf="item[header['key']] === 'in_review'">
                  <p class="statustype--in-review">
                    Falta información
                  </p>
                </div>
              </div>
              <div *ngIf="header['type'] == 'number'">
                <p>{{ item[header["key"]] }}</p>
              </div>
              <div *ngIf="header['type'] == 'boolean'">
                <div class="boolean-state" *ngIf="item[header['key']]">
                  <div class="oval--true"></div>
                  <p>Activo</p>
                </div>
                <div class="boolean-state" *ngIf="!item[header['key']]">
                  <div class="oval--false"></div>
                  <p>Inactivo</p>
                </div>
              </div>
              <div *ngIf="header['type'] == 'success'">
                <div class="boolean-state" *ngIf="item[header['key']] == 0">
                  <div class="oval--false"></div>
                  <p>Cancelada</p>
                </div>
                <div class="boolean-state" *ngIf="item[header['key']] == 1">
                  <div class="oval--true"></div>
                  <p>Exitoso</p>
                </div>
                <div class="boolean-state" *ngIf="item[header['key']] == 2">
                  <div class="oval--false"></div>
                  <p>Fallido</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 3 || item[header['key']] == 4"
                >
                  <div class="oval--warning"></div>
                  <p>Pendiente</p>
                </div>

                <div
                  class="boolean-state"
                  *ngIf="
                    item[header['key']] != 0 &&
                    item[header['key']] != 1 &&
                    item[header['key']] != 2 &&
                    item[header['key']] != 3 &&
                    item[header['key']] != 4
                  "
                >
                  <div class="oval--false"></div>
                  <p>N/D</p>
                </div>
              </div>
              <div *ngIf="header['type'] == 'chargedStatus'">
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 'paid'"
                >
                  <div class="oval--true"></div>
                  <p>Pagado</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 'unpaid'"
                >
                  <div class="oval--false"></div>
                  <p>Adeudo</p>
                </div>
              </div>
              <div *ngIf="header['type'] == 'paymentStatus'">
                <div class="boolean-state" *ngIf="item[header['key']]">
                  <div class="oval--true"></div>
                  <p>Pagado</p>
                </div>
                <div class="boolean-state" *ngIf="!item[header['key']]">
                  <div class="oval--false"></div>
                  <p>Adeudo</p>
                </div>
              </div>
              <div *ngIf="header['type'] == 'deductionStatus'">
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 'paid'"
                >
                  <div class="oval--true"></div>
                  <p>Pagado</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 'unpaid'"
                >
                  <div class="oval--false"></div>
                  <p>Pendiente</p>
                </div>
              </div>

              <div *ngIf="header['type'] == 'hasUser'" class="text-center">
                <img
                  *ngIf="item[header['key']]"
                  src="assets/img/application/icons/ic_checkmark_accent-success-500.svg"
                  alt=""
                />
              </div>
              <div *ngIf="header['type'] == 'statusCollaborator'">
                <div
                  class="boolean-state"
                  *ngIf="
                    ['active', 'info_available'].includes(item[header['key']])
                  "
                >
                  <div class="oval--true"></div>
                  <p>Activo</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="
                    ['pending_admin_validation'].includes(item[header['key']])
                  "
                >
                  <div class="oval--warning"></div>
                  <p>Informacion pendiente</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 'inactive'"
                >
                  <div class="oval--false"></div>
                  <p>Baja</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="
                    ['suspended', 'seniority_required'].includes(
                      item[header['key']]
                    )
                  "
                >
                  <div class="oval--warning"></div>
                  <p>Inactivo</p>
                </div>
              </div>
              <div *ngIf="header['type'] == 'employeeStatus'">
                <div
                  class="boolean-state"
                  *ngIf="
                    ['active', 'info_available'].includes(item[header['key']])
                  "
                >
                  <div class="oval--true"></div>
                  <p>Activo</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="item[header['key']] == 'inactive'"
                >
                  <div class="oval--false"></div>
                  <p>Baja</p>
                </div>
                <div
                  class="boolean-state"
                  *ngIf="
                    ['suspended', 'seniority_required'].includes(
                      item[header['key']]
                    )
                  "
                >
                  <div class="oval--warning"></div>
                  <p>Inactivo</p>
                </div>
              </div>
            </div>
            <div *ngIf="header['type'] == 'button'">
              <div class="validate">
                <button (click)="emitValidate(item)">Validar</button>
              </div>
            </div>
            <div *ngIf="header['type'] == 'dropdown'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownMoreCompanies" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownMoreCompanies"
                    class="dropdownMoreCompanies"
                  >
                    <a
                      class="dropdown-item"
                      (click)="emitDisable(item)"
                      *ngIf="item[header['key']]"
                    >
                      <img
                        src="assets/img/application/icons/ic_eye-off_grey.svg"
                        alt=""
                      />
                      <span>Deshabilitar</span>
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="emitEnable(item)"
                      *ngIf="!item[header['key']]"
                    >
                      <img
                        src="assets/img/application/icons/ic_eye-open_grey.svg"
                        alt=""
                      />
                      <span>Habilitar</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div
              *ngIf="
                header['type'] == 'dropdownWidtrawal' &&
                (item[header['key']] == true ||
                  item[header['extraKey1']] == 'available')
              "
            >
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownMoreWithdrawal" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownMoreWithdrawal"
                    class="dropdownMoreWithdrawal"
                  >
                    <a
                      *ngIf="item[header['key']] == true"
                      class="dropdown-item"
                      (click)="
                        emitPaymentByWithdrawal(item, item[header['key']])
                      "
                    >
                      <img
                        src="assets/img/application/icons/ic_card-outline_basic-600.svg"
                        alt=""
                      />
                      <span>&nbsp; Pagado por Colaborador</span>
                    </a>
                    <a
                      *ngIf="item[header['extraKey1']] == 'available'"
                      class="dropdown-item"
                      (click)="emitDownloadCEP(item, 'xml')"
                    >
                      <img
                        class="downloadIco"
                        src="assets/img/application/icons/ic_xml_grey.png"
                        alt=""
                      />
                      <span>&nbsp; Descargar comprobante XML</span>
                    </a>
                    <a
                      *ngIf="item[header['extraKey1']] == 'available'"
                      class="dropdown-item"
                      (click)="emitDownloadCEP(item, 'pdf')"
                    >
                      <img
                        class="downloadIco"
                        src="assets/img/application/icons/ic_pdf_grey.png"
                        alt=""
                      />
                      <span>&nbsp; Descargar comprobante PDF</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="header['type'] == 'dropdownDeductions'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownDeductions" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownDeductions"
                    class="dropdownDeductions"
                  >
                    <a class="dropdown-item" (click)="emitDownloadExcel(item)">
                      <img
                        src="assets/img/application/icons/ic_download_grey.svg"
                        alt=""
                      />
                      <span>Descargar excel</span>
                    </a>
                    <a class="dropdown-item" (click)="emitDownloadPDF(item)">
                      <img
                        src="assets/img/application/icons/ic_download_grey.svg"
                        alt=""
                      />
                      <span>Descargar PDF</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="header['type'] == 'dropdownCharges'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownCharges" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownCharges"
                    class="dropdownCharges"
                  >
                    <a
                      *ngIf="item['excelFile']"
                      class="dropdown-item"
                      (click)="emitDownloadExcel(item)"
                    >
                      <img
                        src="assets/img/application/icons/ic_download_grey.svg"
                        alt=""
                      />
                      <span>Descargar excel</span>
                    </a>
                    <a
                      *ngIf="item['pdfFile']"
                      class="dropdown-item"
                      (click)="emitDownloadPDF(item)"
                    >
                      <img
                        src="assets/img/application/icons/ic_download_grey.svg"
                        alt=""
                      />
                      <span>Descargar PDF</span>
                    </a>
                    <a
                      *ngIf="item['excelFile'] && item['pdfFile']"
                      class="dropdown-item"
                      (click)="emitGenerateFiles(item)"
                    >
                      <img
                        src="assets/img/application/icons/ic_download_grey.svg"
                        alt=""
                      />
                      <span>Volver a Generar Reportes</span>
                    </a>
                    <a
                      *ngIf="!item['excelFile']"
                      class="dropdown-item"
                      (click)="emitGenerateFiles(item)"
                    >
                      <img
                        src="assets/img/application/icons/ic_download_grey.svg"
                        alt=""
                      />
                      <span>Generar Reportes</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="header['type'] == 'dropdownStatusCollaborator'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownMoreCollaborators" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownMoreCollaborators"
                    class="dropdownMoreCollaborators"
                  >
                    <a
                      class="dropdown-item"
                      (click)="emitStatus(item, 1)"
                      *ngIf="
                        item[header['key']] == 'inactive' ||
                        item[header['key']] == 'suspended' ||
                        item[header['key']] == 'seniority_required'
                      "
                      ngbTooltip="El usuario tendrá acceso a Monto"
                    >
                      <img
                        src="assets/img/application/icons/ic_eye-open_grey.svg"
                        alt=""
                      />
                      <span>Activar</span>
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="emitStatus(item, 2)"
                      *ngIf="
                        item[header['key']] == 'active' ||
                        item[header['key']] == 'inactive' ||
                        item[header['key']] == 'info_available'
                      "
                      ngbTooltip="El usuario tendrá acceso a Monto, pero no
                      podrá hacer retiros"
                    >
                      <img
                        src="assets/img/application/icons/ic_eye-off_basic-600.svg"
                        alt=""
                      />
                      <span>Desactivar</span>
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="emitStatus(item, 0)"
                      *ngIf="
                        item[header['key']] == 'active' ||
                        item[header['key']] == 'suspended' ||
                        item[header['key']] == 'info_available'
                      "
                      ngbTooltip="El usuario dejará de tener acceso a Monto"
                    >
                      <img
                        src="assets/img/application/icons/ic_person-remove_basic-600.svg"
                        alt=""
                      />
                      <span>Dar de baja</span>
                    </a>

                    <a
                      class="dropdown-item"
                      (click)="emitValidate(item)"
                      *ngIf="item[header['key']] == 'pending_admin_validation'"
                      ngbTooltip="El usuario tendra acceso a Monto"
                    >
                      <img
                        src="assets/img/application/icons/ic_approve-company_grey.svg"
                        alt=""
                      />
                      <span>Validar</span>
                    </a>
                    <a
                      *ngIf="item[header['extraKey2']] == false && superadmin"
                      class="dropdown-item"
                      (click)="emitPennyCheck(item, true)"
                    >
                      <img
                        src="assets/img/application/icons/ic_approve-company_grey.svg"
                        alt=""
                      />
                      <span>
                        Confirmar prueba del centavo
                      </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="header['type'] == 'dropdownDrawals'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownDrawals" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownDrawals"
                    class="dropdownDrawals"
                  >
                    <a
                      *ngIf="item[header['extraKey1']] == 'available'"
                      class="dropdown-item"
                      (click)="emitDownloadCEP(item, 'xml')"
                    >
                      <img
                        class="downloadIco"
                        src="assets/img/application/icons/ic_xml_grey.png"
                        alt=""
                      />
                      <span>&nbsp; Descargar CEP</span>
                    </a>
                    <a
                      *ngIf="item[header['extraKey1']] == 'available'"
                      class="dropdown-item"
                      (click)="emitDownloadCEP(item, 'pdf')"
                    >
                      <img
                        class="downloadIco"
                        src="assets/img/application/icons/ic_pdf_grey.png"
                        alt=""
                      />
                      <span>&nbsp; Descargar CEP</span>
                    </a>
                    <a
                      *ngIf="item[header['extraKey1']] != 'available'"
                      class="dropdown-item"
                    >
                      No disponible
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="header['type'] == 'dropdownActions'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownDrawals" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownDrawals"
                    class="dropdownDrawals"
                  >
                    <a
                      class="dropdown-item"
                      (click)="emitDownloadCEP(item, 'xml')"
                    >
                      <img
                        class="downloadIco"
                        src="assets/img/application/icons/ic_pencil-outline_grey.svg"
                        alt=""
                      />
                      <span>&nbsp; Editar</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="header['type'] == 'dropdownStatusInfoCollab'">
              <ul class="table-card__more">
                <li ngbDropdown>
                  <a id="dropdownMoreCollaborators" ngbDropdownToggle>
                    <img
                      src="assets/img/application/icons/ic_more-vertical_grey.svg"
                      alt=""
                    />
                  </a>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownMoreCollaborators"
                    class="dropdownMoreCollaborators"
                  >
                    <a
                      class="dropdown-item"
                      (click)="emitStatusInfoCollab(item, 1)"
                      *ngIf="
                        item[header['extraKey']] == 'inactive' ||
                        item[header['extraKey']] == 'suspended'
                      "
                    >
                      <img
                        src="assets/img/application/icons/ic_eye-open_grey.svg"
                        alt=""
                      />
                      <span>Activar</span>
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="emitStatusInfoCollab(item, 2)"
                      *ngIf="
                        item[header['extraKey']] == 'active' ||
                        item[header['extraKey']] == 'inactive' ||
                        item[header['extraKey']] == 'info_available'
                      "
                    >
                      <img
                        src="assets/img/application/icons/ic_eye-off_basic-600.svg"
                        alt=""
                      />
                      <span>Desactivar</span>
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="emitStatusInfoCollab(item, 0)"
                      *ngIf="
                        item[header['extraKey']] == 'active' ||
                        item[header['extraKey']] == 'suspended' ||
                        item[header['extraKey']] == 'info_available'
                      "
                    >
                      <img
                        src="assets/img/application/icons/ic_person-remove_basic-600.svg"
                        alt=""
                      />
                      <span>Dar de baja</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isEmptyState" class="table-card__empty-state">
      <div class="empty-state__content">
        <div class="empty-state__box">
          <div class="box-icon">
            <div class="icon">
              <img
                *ngIf="
                  title == 'collaborators-scroll' ||
                  title == 'group-collaborators-scroll' ||
                  title == 'validate-collaborators-scroll'
                "
                width="50"
                height="50"
                src="assets/img/application/icons/ic_people-outline_white.svg"
                alt=""
              />
              <img
                *ngIf="title == 'payroll-periods-scroll'"
                width="50"
                height="50"
                src="assets/img/application/icons/ic_calendar-outline_white.svg"
                alt=""
              />
              <img
                *ngIf="title == 'groups-scroll'"
                width="50"
                height="50"
                src="assets/img/application/icons/ic_folder-outline_white.svg"
                alt=""
              />
              <img
                *ngIf="title == 'deductions-scroll'"
                width="50"
                height="50"
                src="assets/img/application/icons/ic_remove-circle-outline_white.svg"
                alt=""
              />
              <img
                *ngIf="
                  title == 'withdrawals-collaborator-scroll' ||
                  title == 'withdrawals-company-scroll' ||
                  title == 'home-withdrawal-scroll' ||
                  title == 'home-super-withdrawal-scroll'
                "
                width="50"
                height="50"
                src="assets/img/application/icons/ic_card-outline_white.svg"
                alt=""
              />
              <img
                *ngIf="
                  title == 'active-companies-scroll' ||
                  title == 'inprocess-companies-scroll' ||
                  title == 'inadministration-companies-scroll'
                "
                width="50"
                height="50"
                src="assets/img/application/icons/ic_briefcase-outline_white.svg"
                alt=""
              />
            </div>
          </div>
          <div class="box-description">
            <h4
              *ngIf="
                title == 'collaborators-scroll' ||
                title == 'group-collaborators-scroll' ||
                title == 'validate-collaborators-scroll'
              "
              class="strong"
            >
              No se han agregado colaboradores
            </h4>
            <h4 *ngIf="title == 'payroll-periods-scroll'" class="strong">
              No se han agregado periodos de pago
            </h4>
            <h4 *ngIf="title == 'groups-scroll'" class="strong">
              No se han agregado grupos
            </h4>
            <h4 *ngIf="title == 'deductions-scroll'" class="strong">
              No se han generado deducciones
            </h4>
            <h4
              *ngIf="
                title == 'withdrawals-collaborator-scroll' ||
                title == 'withdrawals-company-scroll' ||
                title == 'home-withdrawal-scroll' ||
                title == 'home-super-withdrawal-scroll'
              "
              class="strong"
            >
              No se han realizado retiros
            </h4>
            <h4
              *ngIf="
                title == 'active-companies-scroll' ||
                title == 'inprocess-companies-scroll'
              "
              class="strong"
            >
              No se han agregado empresas
            </h4>
            <h4 *ngIf="title == 'inadministration-companies-scroll'">
              Sin resultados / seleccione un módulo
            </h4>
          </div>
          <div class="box-button">
            <button
              *ngIf="
                title != 'withdrawals-collaborator-scroll' &&
                title != 'withdrawals-company-scroll' &&
                title != 'deductions-scroll' &&
                title != 'group-collaborators-scroll' &&
                title != 'inadministration-companies-scroll' &&
                title != 'home-withdrawal-scroll' &&
                title != 'home-super-withdrawal-scroll' &&
                title != 'validate-collaborators-scroll'
              "
              class="button--thin img--left"
              (click)="emitToggleSidebar()"
            >
              <img
                *ngIf="title != 'validate-collaborators-scroll'"
                src="assets/img/application/icons/ic_plus_white.svg"
                alt=""
              />
              <div *ngIf="title == 'collaborators-scroll'">
                Agregar colaborador
              </div>

              <div *ngIf="title == 'payroll-periods-scroll'">
                Crear periodo
              </div>
              <div *ngIf="title == 'groups-scroll'">
                Crear grupo
              </div>
              <div
                *ngIf="
                  title == 'active-companies-scroll' ||
                  title == 'inprocess-companies-scroll'
                "
              >
                Agregar
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <p>estoy cargando</p>
</ng-template>
<div>
  <app-pagination> </app-pagination>
</div>
