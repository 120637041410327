<div class="section__payroll-preview-calendar">
  <div class="payroll-periods-detail-calendar__navigator">
    <button
      type="button"
      class="button--calendar-cycle-navigator left"
      (click)="previousCycle()"
    >
      <img src="assets/img/application/icons/ic_chevron-left_grey.svg" />
    </button>
    <h5 class="strong">
      {{ this.viewState.currentViewingCycle.startDate | date: "d" }} -
      {{ this.viewState.currentViewingCycle.endDate | date: "d" }} de
      {{ this.viewState.currentViewingCycle.endDate | date: "LLLL" }},
      {{ this.viewState.currentViewingCycle.endDate | date: "yyyy" }}
    </h5>
    <button
      type="button"
      class="button--calendar-cycle-navigator right"
      (click)="nextCycle()"
    >
      <img src="assets/img/application/icons/ic_chevron-right_grey.svg" />
    </button>
  </div>
  <div class="create-group-payroll-periods-calendar__calendar">
    <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [viewDate]="viewDate"
        [events]="events"
        (beforeViewRender)="renderCurrentCycleEvents($event)"
      >
      </mwl-calendar-month-view>
    </div>
  </div>
</div>
