import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PageStateService } from "@services/page-state.service";

@Component({
  selector: "app-add-comments-modal",
  templateUrl: "./add-comments-modal.component.html",
})
export class AddCommentsModalComponent implements OnInit {
  @ViewChild("addCommentsModal")
  addCommentsModal;

  @Output()
  commentSubmited = new EventEmitter<string>();

  addCommentForm: FormGroup = this.fb.group({
    commentBody: [null, Validators.required],
  });

  shouldHideCloseButton = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public pageStateService: PageStateService
  ) {}

  ngOnInit(): void {
    /** No content */
  }

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  openProjectsModal(options?): void {
    this.modalService.open(this.addCommentsModal, {
      ...options,
      ...{
        keyboard: false,
        ariaLabelledBy: "Modal Add Comment",
        centered: true,
      },
    });
  }

  submitComment(addCommentForm: FormGroup): void {
    this.pageStateService.isLoading = true;

    if (addCommentForm.invalid) {
      this.pageStateService.isLoading = false;
      this.pageStateService.responseError = true;
      return;
    }

    this.pageStateService.isLoading = false;
    this.pageStateService.responseError = false;

    const comment = addCommentForm.get("commentBody").value;
    this.addCommentForm.reset();
    this.commentSubmited.emit(comment);
    this.modalService.dismissAll();
  }
}
