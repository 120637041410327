import { IFiltersMaxLimitsResponse } from "@declarations/filters-max-limits-response";

export class FiltersMaxLimits {
  montosCountLimit: number;
  employeesCountLimit: number;

  constructor(attrs: IFiltersMaxLimitsResponse) {
    this.montosCountLimit = attrs.montos_count_limit;
    this.employeesCountLimit = attrs.employees_count_limit;
  }
}
