import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Input,
} from "@angular/core";

@Directive({
  selector: "[appCellPhoneInputMask]",
})
export class CellPhoneMaskDirective implements OnInit {
  @Input()
  set maxDigits(maxDigits: number) {
    this.setRegex(maxDigits);
  }

  private digitRegex: RegExp;

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
    this.setRegex();
  }

  ngOnInit() {
    this.onInputChange(this.el.value, false);
  }

  private setRegex(maxDigits?: number) {
    this.digitRegex = new RegExp(this.regexString(maxDigits), "g");
  }

  private regexString(max?: number) {
    const maxStr = max ? `{0,${max}}` : `+`;
    return `^\s*-?[0-9]${maxStr}\s*$`;
  }

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    let cleanValue;

    if (event) cleanValue = (event.match(this.digitRegex) || []).join("");

    if (cleanValue || !event) this.lastValid = cleanValue;
    //this.el.value = null || this.lastValid;

    if (!this.el.value.includes("(") && event) {
      this.onInputChange(event, false);
    }
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    // on focus remove formatting
    this.el.value = value.replace(/[^0-9.]+/g, "");
    this.el.select();
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    if (!this.el.value.includes("(")) {
      this.onInputChange(value, false);
    }
  }

  @HostListener("keydown.control.z", ["$event.target.value"])
  onUndo(value) {
    this.el.value = "";
  }

  // variable to store last valid input
  private lastValid = "";
  @HostListener("input", ["$event"])
  onInput(event) {
    // on input, run regex to only allow certain characters and format
    const cleanValue = (event.target.value.match(this.digitRegex) || []).join(
      ""
    );
    if (cleanValue || !event.target.value) this.lastValid = cleanValue;

    this.el.value = cleanValue || this.lastValid;
  }

  onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, "");
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, "($1)");
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) ($2)");
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2 $3");
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2 $3");
    }

    this.el.value = newVal;
  }
}
