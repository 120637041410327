import { IPendingInvoiceResponse } from "@declarations/pending-invoice";

export class PendingInvoices {
  amountPaid: string;
  companyName: string;
  createdAt: string;
  daysLate: number;
  endDate: string;
  folio: number;
  generatedInterest: number;
  id: string;
  interestWithTax: number;
  paymentDate: string;
  paymentStatus: string;
  payrollPeriod: string;
  startDate: string;
  totalAmount: number;
  totalCommission: number;
  totalInvoicesAmount: number;
  totalToPay: number;

  constructor(attrs: IPendingInvoiceResponse) {
    this.amountPaid = attrs.amount_paid;
    this.companyName = attrs.company_name;
    this.createdAt = attrs.created_at;
    this.daysLate = attrs.days_late;
    this.endDate = attrs.end_date;
    this.folio = attrs.folio;
    this.generatedInterest = attrs.generated_interest;
    this.id = attrs.id;
    this.interestWithTax = attrs.interest_with_tax;
    this.paymentDate = attrs.payment_date;
    this.paymentStatus = attrs.payment_status;
    this.payrollPeriod = attrs.payroll_period;
    this.startDate = attrs.start_date;
    this.totalAmount = +attrs.total_amount;
    this.totalCommission = +attrs.total_commission;
    this.totalInvoicesAmount = attrs.total_invoices_amount;
    this.totalToPay = attrs.total_to_pay;
  }
}
