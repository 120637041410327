import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  CalendarView,
  CalendarEvent,
  CalendarDateFormatter,
  CalendarMonthViewBeforeRenderEvent,
} from "angular-calendar";
import { CustomDateFormatter } from "../create-payroll-periods-calendar/custom-date-formatter.provider";
import { PayrollPeriodCycle } from "@models/payroll-period-cycle";
import { subDays, addDays, differenceInCalendarDays } from "date-fns";
import * as moment from "moment";
import { Toastr } from "@services/toastr.service";

@Component({
  selector: "app-edit-payroll-periods-calendar",
  templateUrl: "./edit-payroll-periods-calendar.component.html",
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
/** TODO: Add current cycle selection */
export class EditPayrollPeriodsCalendarComponent implements OnInit {
  @Input() cycle: PayrollPeriodCycle;

  @Input() cycles: Array<PayrollPeriodCycle>;

  @Input() cyclePaymentScheme: number;

  @Input() reconciliationOption: number;

  @Input() reconciliationTime: string;

  @Output()
  onSetDates: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  currentCycleSelectionChanged: EventEmitter<
    PayrollPeriodCycle
  > = new EventEmitter<PayrollPeriodCycle>();

  colors: any = {
    green: {
      primary: "#00E096",
      secondary: "#dde7ff",
    },
    orange: {
      primary: "#FF7750",
      secondary: "#dde7ff",
    },
    blue: {
      primary: "#3667bd",
      secondary: "#D1E8FF",
    },
    yellow: {
      primary: "#FFC600",
      secondary: "#FDF1BA",
    },
  };

  dateSelected: Date;

  endDateDateChange: boolean = false;

  endDateConfirmCycle: Date;

  events: Array<CalendarEvent> = [];

  periodButtonStates = { begin: false, end: true, cut: true, payment: true };

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  viewState = {
    currentViewingCycle: undefined,
  };

  constructor(private toastr: Toastr) {}

  ngOnInit(): void {}

  /** HACK: Prevent using ngOnChanges by changing input in template to async */
  ngOnChanges(change: SimpleChanges) {
    if (change["cycle"]) {
      this.viewState.currentViewingCycle = this.cycle;
      this.viewDate = this.viewState.currentViewingCycle.startDate;
      this.cycle.newCycle
        ? (this.endDateConfirmCycle = this.cycle.endDate)
        : null;

      this.setVisibleCalendarEvents(this.cycle);
    }
    if (change["reconciliationOption"]) {
      this.reconciliationOption = this.reconciliationOption;
    }
    if (change["reconciliationTime"]) {
      this.reconciliationTime = this.reconciliationTime;
    }
  }

  private get dateInRange(): boolean {
    return (
      this.dateSelected >= this.viewState.currentViewingCycle.startDate &&
      this.dateSelected <= this.viewState.currentViewingCycle.endDate
    );
  }

  private get validateCutDatePaymentDate(): boolean {
    const currentDate = moment(new Date());
    const dateSelected = moment(this.dateSelected);

    return dateSelected.startOf("day") >= currentDate.startOf("day");
  }

  private get isEndDateValid(): boolean {
    const days =
      differenceInCalendarDays(
        this.dateSelected,
        this.viewState.currentViewingCycle.startDate
      ) + 1;

    if (this.cyclePaymentScheme == 0) {
      if (days >= 5 && days <= 9) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == 1) {
      if (days >= 8 && days <= 12) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == 2) {
      if (days >= 12 && days <= 16) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == 3) {
      if (days >= 13 && days <= 17) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == 4) {
      if (days >= 28 && days <= 33) {
        return true;
      } else {
        return false;
      }
    }
  }

  cutoffDateOnChange(): void {
    this.viewState.currentViewingCycle.cutoffDate = null;
    this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
  }

  paymentDateOnChange(): void {
    this.viewState.currentViewingCycle.paymentDate = null;
    this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
  }

  endDateOnChange() {
    if (
      !this.viewState.currentViewingCycle.endDate ||
      !this.viewState.currentViewingCycle.newCycle
    ) {
      return;
    }

    this.endDateDateChange = true;
    this.viewState.currentViewingCycle.endDate = null;
    this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
  }

  updateCycleDates() {
    if (moment(this.cycle.endDate).isSame(this.dateSelected)) {
      this.cycle.endDate = this.dateSelected;
      this.endDateConfirmCycle = this.cycle.endDate;
    } else {
      const daysDiff = differenceInCalendarDays(
        this.endDateConfirmCycle,
        this.dateSelected
      );
      this.moveAllCicles(daysDiff);
    }

    this.emitDateChanges();
    this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
  }

  moveAllCicles(numberOfDays) {
    this.viewState.currentViewingCycle.endDate = this.dateSelected;
    this.endDateConfirmCycle = this.viewState.currentViewingCycle.endDate;
    let i = this.currentViewingCycleIndex + 1;

    while (i < this.cycles.length) {
      if (this.cycles[i].startDate) {
        if (numberOfDays) {
          this.cycles[i].startDate = subDays(
            this.cycles[i].startDate,
            numberOfDays
          );
        } else {
          this.cycles[i].startDate = addDays(
            this.cycles[i].startDate,
            numberOfDays
          );
        }
      }
      if (this.cycles[i].endDate) {
        if (numberOfDays) {
          this.cycles[i].endDate = subDays(
            this.cycles[i].endDate,
            numberOfDays
          );
        } else {
          this.cycles[i].endDate = addDays(
            this.cycles[i].endDate,
            numberOfDays
          );
        }
      }
      if (this.cycles[i].cutoffDate) {
        if (numberOfDays) {
          this.cycles[i].cutoffDate = subDays(
            this.cycles[i].cutoffDate,
            numberOfDays
          );
        } else {
          this.cycles[i].cutoffDate = addDays(
            this.cycles[i].cutoffDate,
            numberOfDays
          );
        }
      }
      if (this.cycles[i].paymentDate) {
        if (numberOfDays) {
          this.cycles[i].paymentDate = subDays(
            this.cycles[i].paymentDate,
            numberOfDays
          );
        } else {
          this.cycles[i].paymentDate = addDays(
            this.cycles[i].paymentDate,
            numberOfDays
          );
        }
      }

      i = i + 1;
    }
  }

  dayClicked({ date }: { date: Date }): boolean {
    this.dateSelected = date;

    /** Add endDate */
    if (this.endDateDateChange) {
      //** If cutday and payment day exist, validate new endDate */
      if (
        this.viewState.currentViewingCycle.cutoffDate ||
        this.viewState.currentViewingCycle.paymentDate
      ) {
        if (
          this.dateSelected < this.viewState.currentViewingCycle.cutoffDate ||
          this.dateSelected < this.viewState.currentViewingCycle.paymentDate
        ) {
          this.toastr.single_error(
            "La fecha final no puede ser menor que la fecha de corte o pago actuales"
          );
          this.viewState.currentViewingCycle.endDate = this.endDateConfirmCycle;
        }
      }

      if (this.isEndDateValid) {
        this.updateCycleDates();
      } else {
        if (this.cyclePaymentScheme == 0) {
          this.toastr.single_error(
            "El periodo semanal sólo puede durar de 5 a 9 días."
          );
        }
        if (this.cyclePaymentScheme == 1) {
          this.toastr.single_error(
            "El periodo decenal sólo puede durar de 8 a 12 días."
          );
        }
        if (this.cyclePaymentScheme == 2) {
          this.toastr.single_error(
            "El periodo catorcenal sólo puede durar de 12 a 16 días."
          );
        }
        if (this.cyclePaymentScheme == 3) {
          this.toastr.single_error(
            "El periodo quincenal sólo puede durar de 13 a 17 días."
          );
        }
        if (this.cyclePaymentScheme == 4) {
          this.toastr.single_error(
            "El periodo mensual sólo puede durar de 28 a 33 días."
          );
        }

        this.cycle.endDate = this.endDateConfirmCycle;
      }

      this.endDateDateChange = false;
      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
      this.emitDateChanges();
      return;
    }
    /** Add  cutoffDate*/
    if (!this.viewState.currentViewingCycle.cutoffDate) {
      const hour = this.reconciliationTime.substring(2, 0);
      const minute = this.reconciliationTime.substring(5, 3);

      if (this.validateCutDatePaymentDate) {
        if (this.dateInRange) {
          this.viewState.currentViewingCycle.cutoffDate = new Date(
            this.dateSelected
          );
          this.viewState.currentViewingCycle.reconciliationDate = new Date(
            this.viewState.currentViewingCycle.cutoffDate
          );

          //Define reconciliation date
          if (this.reconciliationOption == 0) {
            this.viewState.currentViewingCycle.reconciliationDate.setDate(
              this.dateSelected.getDate()
            );
          } else {
            this.viewState.currentViewingCycle.reconciliationDate.setDate(
              addDays(this.dateSelected, 1).getDate()
            );
          }

          /** Set time selected */
          this.viewState.currentViewingCycle.reconciliationDate.setHours(0);
          this.viewState.currentViewingCycle.reconciliationDate.setMinutes(0);
          this.viewState.currentViewingCycle.reconciliationDate.setSeconds(0);
          this.viewState.currentViewingCycle.reconciliationDate.setHours(hour);
          this.viewState.currentViewingCycle.reconciliationDate.setMinutes(
            minute
          );
        } else {
          this.toastr.single_error(
            "La nueva fecha debe estar dentro del ciclo"
          );
        }
      } else {
        this.toastr.single_error(
          "La fecha de corte no puede ser menor al día de hoy"
        );
      }

      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);

      this.emitDateChanges();
      return true;
    }
    /** Add  paymentDate*/
    if (
      !this.viewState.currentViewingCycle.paymentDate &&
      this.viewState.currentViewingCycle.cutoffDate
    ) {
      if (this.validateCutDatePaymentDate) {
        this.viewState.currentViewingCycle.paymentDate = this.dateSelected;
      } else {
        this.toastr.single_error(
          "La fecha de pago no puede ser menor al día de hoy"
        );
      }

      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);

      this.cycles[
        this.currentViewingCycleIndex
      ] = this.viewState.currentViewingCycle;
      this.emitDateChanges();
      return true;
    }
  }

  nextCycle(): void {
    const cycleIndexCount = this.cycles.length - 1;

    if (this.currentViewingCycleIndex < cycleIndexCount) {
      const previousCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );
      this.viewState.currentViewingCycle = this.cycles[
        this.currentViewingCycleIndex + 1
      ];
      const currentCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );

      /** TODO: DRY this validation because it's duplicated in previous cycle function */
      /** Change calendar month if new cycle is in next month */
      if (
        !currentCycleStartDateMoment.isSame(
          previousCycleStartDateMoment,
          "month"
        )
      ) {
        this.viewDate = this.viewState.currentViewingCycle.startDate;
      }

      this.currentCycleSelectionChanged.emit(
        this.viewState.currentViewingCycle
      );
      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
    }
  }

  previousCycle(): void {
    if (this.currentViewingCycleIndex > 0) {
      const previousCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );
      this.viewState.currentViewingCycle = this.cycles[
        this.currentViewingCycleIndex - 1
      ];
      const currentCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );

      /** TODO: DRY this validation because it's duplicated in next cycle function */
      /** Change calendar month if new cycle is in next month */
      if (
        !currentCycleStartDateMoment.isSame(
          previousCycleStartDateMoment,
          "month"
        )
      ) {
        this.viewDate = this.viewState.currentViewingCycle.startDate;
      }

      this.currentCycleSelectionChanged.emit(
        this.viewState.currentViewingCycle
      );
      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
    }
  }

  renderCurrentCycleEvents(
    renderEvent: CalendarMonthViewBeforeRenderEvent
  ): void {
    renderEvent.body.forEach((day) => {
      const dayAsMoment = moment(day.date);
      const startDateAsMoment = moment(this.cycle.startDate);
      const endDatesAsMoment = moment(this.cycle.endDate);

      const secondDay = moment(addDays(this.cycle.startDate, 1));
      const penultimateDay = moment(subDays(this.cycle.endDate, 1));

      if (dayAsMoment.startOf("day").isSame(startDateAsMoment.startOf("day"))) {
        day.cssClass = "bg_startDay";
      }

      if (dayAsMoment.startOf("day").isSame(endDatesAsMoment.startOf("day"))) {
        day.cssClass = "bg_endDate";
      }

      if (
        dayAsMoment
          .startOf("day")
          .isBetween(
            startDateAsMoment.startOf("day"),
            endDatesAsMoment.startOf("day")
          )
      ) {
        day.cssClass = "bg_periodDays";
      }

      if (dayAsMoment.isSame(secondDay)) {
        day.cssClass = "bg_periodDays-firstDay";
      }

      if (dayAsMoment.startOf("day").isSame(penultimateDay.startOf("day"))) {
        day.cssClass = "bg_periodDays-lastDay";
      }
    });
  }

  setVisibleCalendarEvents(cycle: PayrollPeriodCycle) {
    let cutoffEvent;
    let paymentEvent;

    const cycleEvent = {
      title: "Periodo de pago",
      start: cycle.startDate,
      end: cycle.endDate,
      color: {
        primary: "rgba(0,0,0,0)",
        secondary: "rgba(0,0,0,0)",
      },
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
    };
    if (cycle.cutoffDate) {
      cutoffEvent = {
        title: "Fecha de corte",
        start: cycle.cutoffDate,
        end: cycle.cutoffDate,
        color: this.colors.yellow,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };
    }
    if (cycle.paymentDate) {
      paymentEvent = {
        title: "Fecha de pago",
        start: cycle.paymentDate,
        end: cycle.paymentDate,
        color: this.colors.blue,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };
    }

    this.events = [cycleEvent];
    cycle.cutoffDate ? (this.events = [...this.events, cutoffEvent]) : null;
    cycle.paymentDate ? (this.events = [...this.events, paymentEvent]) : null;
  }

  private get currentViewingCycleIndex(): number {
    return this.cycles.findIndex((cycle: PayrollPeriodCycle) => {
      return cycle == this.viewState.currentViewingCycle;
    });
  }

  emitDateChanges() {
    this.onSetDates.emit({
      cycle: this.viewState.currentViewingCycle,
      index: this.currentViewingCycleIndex,
    });
  }
}
