<ng-template #addCommentsModal>
  <div id="section__add-comments-modal">
    <div class="modal-header">
      <h5>Añadir nuevo comentario</h5>
    </div>
    <div class="modal-content">
      <div class="primary--card">
        <form
          [formGroup]="addCommentForm"
          (ngSubmit)="submitComment(addCommentForm)"
        >
          <div class="row margin-small--bottom">
            <div class="col-12">
              <app-input
                type="textarea"
                id="commentBody"
                [customClass]="'input-card'"
                [parentForm]="addCommentForm"
                [responseError]="pageStateService.responseError"
                [placeholder]="'Ingresa tu comentario'"
              >
              </app-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                [disabled]="pageStateService.isLoading"
                type="submit"
                class="button--primary"
              >
                Añadir comentario
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
