import { Directive, Input, ElementRef, OnChanges } from "@angular/core";

@Directive({
  selector: "[appAutofocus]",
})
export class focusInputDirective implements OnChanges {
  @Input() isFocusable: boolean;

  private elementRef: ElementRef;

  public constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnChanges() {
    if (this.isFocusable) {
      this.elementRef.nativeElement.focus();
    }
  }
}
