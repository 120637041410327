import { Pipe } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe {
  transform(newVal) {
    if (newVal) {
      newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "$1 $2 $3");
    }

    return newVal;
  }
}
