<div id="section__reset-password-success-page">
  <div id="section__unauth-sidebar-page">
    <div class="unauth-sidebar-page__main-wrapper">
      <div class="sidebar-primary__wrapper">
        <div class="sidebar-primary__title">
          <a href="https://www.monto.mx">
            <img
              src="assets/img/application/logo/img_logo-small_white.svg"
              alt=""
            />
          </a>
        </div>
        <div class="sidebar-primary__content">
          <h2>La evolución del</h2>
          <h2 class="subtitle">pago de nómina</h2>
        </div>
        <div class="sidebar-primary__footer">
          <button class="button--secondary img--right">
            <a href="https://www.monto.mx" target="_blank"
              >Conocer más acerca de Monto</a
            >
            <img
              src="assets/img/application/icons/ic_chevron-right_white-6x.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <div class="page-content__wrapper">
        <div class="wrapper__content">
          <div class="reset-password-success-page__content">
            <div class="unauth-hero"></div>
            <div class="unauth-card">
              <div class="unauth-card_title margin-base--bottom">
                <img
                  class="hero-card"
                  src="assets/img/application/pages/unauth/img_account-complete.png"
                  alt=""
                />
                <h3 class="margin-large--bottom">
                  Tu cuenta contraseña ha sido actualizada exitosamente
                </h3>
              </div>
              <div class="unauth-card_content">
                <button
                  [routerLink]="['/login']"
                  class="button--primary auto-w--all"
                >
                  Ir a inicio de sesión
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
