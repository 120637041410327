import { IChargesInvoiceResponse } from "@declarations/charges-invoice-response";

export class ChangesInvoice {
  amountPaid: string;
  company: string;
  createdAt: Date;
  endDate: Date;
  excelFile: string;
  folio: number;
  generatedInterest: number;
  id: string;
  interestWithTax: number;
  paymentDate: Date;
  paymentStatus: string;
  payrollPeriod: string;
  pdfFile: string;
  startDate: Date;
  totalAmount: number;
  totalCommission: number;
  totalToPay: number;
  totalToPayTotalAmount: number;

  constructor(attrs: IChargesInvoiceResponse) {
    this.amountPaid = attrs.invoice.amount_paid;
    this.company = attrs.invoice.company;
    this.createdAt = new Date(attrs.invoice.created_at);
    this.endDate = new Date(attrs.invoice.end_date);
    this.excelFile = attrs.invoice.excel_file.url;
    this.folio = attrs.invoice.folio;
    this.generatedInterest = attrs.invoice.generated_interest;
    this.id = attrs.invoice.id;
    this.interestWithTax = attrs.invoice.interest_with_tax;
    this.paymentDate = new Date(attrs.invoice.payment_date);
    this.paymentStatus = attrs.invoice.payment_status;
    this.payrollPeriod = attrs.invoice.payroll_period;
    this.pdfFile = attrs.invoice.pdf_file.url;
    this.startDate = new Date(attrs.invoice.start_date);
    this.totalAmount = +attrs.invoice.total_amount;
    this.totalCommission = +attrs.invoice.total_commission;
    this.totalToPay = attrs.invoice.total_to_pay;
    this.totalToPayTotalAmount =
      attrs.invoice.total_to_pay + attrs.invoice.interest_with_tax;
  }
}
