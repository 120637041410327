export class TimeZone {
  id: string;
  name: string;

  /** TODO set interfaces */
  constructor(atrributes) {
    this.id = atrributes["name"];
    this.name = atrributes["offset"];
  }
}
