import { Component, OnInit, ViewChild } from "@angular/core";
import { IUpComingCyclesCollectionResponse } from "@declarations/upcoming-cycles-collection-response";
import { NextPaymentsFilterByCompany } from "@models/next-payments-filter-by-company";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-next-payments",
  templateUrl: "./next-payments.component.html",
})
export class NextPaymentsComponent implements OnInit {
  @ViewChild("viewNextPayments")
  viewNextPayments;

  nextPayments: Array<NextPaymentsFilterByCompany> = [];

  shouldHideCloseButton = false;

  totalToPay: number = 0;

  upcomingCycles: IUpComingCyclesCollectionResponse;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  closeNextPaymentsModal() {
    this.modalService.dismissAll();
  }

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  async openViewNextPaymentsModal(upcomingCycles, totalToPay) {
    this.upcomingCycles = upcomingCycles;
    this.totalToPay = totalToPay;
    this.nextPayments = [];
    await this.filterByCompany();
    this.modalService.open(this.viewNextPayments, {
      ...{
        keyboard: false,
        ariaLabelledBy: "ModalViewNextPayments",
        centered: true,
      },
    });
  }

  filterByCompany() {
    this.upcomingCycles.items.forEach((element, index) => {
      const isInArray = this.nextPayments
        .map(function (e) {
          return e["company"];
        })
        .indexOf(element.companyName);
      if (isInArray == -1) {
        this.nextPayments.push({
          company: element.companyName,
          totalAmount: element.totalAmount + element.totalCommission,
          item: [
            {
              companyName: element.companyName,
              cutoffDate: element.cutoffDate,
              endDate: element.endDate,
              id: element.id,
              paymentDate: element.paymentDate,
              periodName: element.periodName,
              startDate: element.startDate,
              totalAmount: element.totalAmount,
              totalCommission: element.totalCommission,
            },
          ],
        });
      } else {
        this.nextPayments[isInArray].totalAmount =
          this.nextPayments[isInArray].totalAmount +
          element.totalAmount +
          element.totalCommission;

        this.nextPayments[isInArray].item.push({
          companyName: element.companyName,
          cutoffDate: element.cutoffDate,
          endDate: element.endDate,
          id: element.id,
          paymentDate: element.paymentDate,
          periodName: element.periodName,
          startDate: element.startDate,
          totalAmount: element.totalAmount,
          totalCommission: element.totalCommission,
        });
      }
    });
  }
}
