import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
} from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Directive({ selector: "[appCurrencyInputMask]", providers: [CurrencyPipe] })
export class CurrencyMaskDirective implements OnInit {
  @Input() hasDecimal: boolean;

  // build the regex based on max pre decimal digits allowed
  private regexString(hasDecimal?: boolean) {
    let regrex: string;

    if (!this.hasDecimal) {
      regrex = `^(\\d+)$`;
    } else {
      regrex = `^(\\d+(\\.\\d{0,2})?|\\.\\d{0,2})$`;
    }

    return regrex;
  }

  private digitRegex: RegExp;

  private setRegex(hasDecimal?: boolean) {
    this.digitRegex = new RegExp(this.regexString(hasDecimal), "g");
  }

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    this.el = this.elementRef.nativeElement;
    this.setRegex();
  }

  ngOnInit() {
    this.setTransformPipe(this.el.value);
    this.setRegex();
  }

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    // on input, run regex to only allow certain characters and format
    let cleanValue;

    if (!event) {
      return;
    }

    if (event) cleanValue = (event.match(this.digitRegex) || []).join("");

    if (cleanValue || !event) this.lastValid = cleanValue;
    this.el.value = cleanValue || this.lastValid;

    if (!this.el.value.includes("$")) {
      this.setTransformPipe(+event);
    }
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    // on focus remove currency formatting
    this.el.value = value.replace(/[^0-9.]+/g, "");
    this.el.select();
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    // on blur, add currency formatting
    if (!this.el.value.includes("$") && value) {
      this.setTransformPipe(value);
    }
  }

  @HostListener("keydown.control.z", ["$event.target.value"])
  onUndo(value) {
    this.el.value = "";
  }

  private setTransformPipe(value) {
    if (!this.hasDecimal) {
      this.el.value = this.currencyPipe.transform(
        value,
        "USD",
        "symbol-narrow",
        "1.0-0"
      );
    } else {
      this.el.value = this.currencyPipe.transform(
        value,
        "USD",
        "symbol-narrow"
      );
    }
  }

  // variable to store last valid input
  private lastValid = "";
  @HostListener("input", ["$event"])
  onInput(event) {
    // on input, run regex to only allow certain characters and format
    const cleanValue = (event.target.value.match(this.digitRegex) || []).join(
      ""
    );
    if (cleanValue || !event.target.value) this.lastValid = cleanValue;
    this.el.value = cleanValue || this.lastValid;
  }
}
