// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API: "https://dev-api.monto.mx",
  COOKIE: {
    DASHBOARD: "dev-monto-session-dashboard",
    CURRENT_USER_COOKIE: "dev-monto-session-cookie",
    CURRENT_USER_COMPANY: "dev-monto-company-cookie",
  },
};
