import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { interval } from "rxjs";
import { finalize, map, take } from "rxjs/operators";

@Component({
  selector: "app-countdown",
  templateUrl: "./countdown.component.html",
  styleUrls: ["./countdown.component.scss"],
})
export class CountdownComponent {
  @Input() countdown = 0; // seconds
  @Input() legend = "Reintentar en "; // seconds
  @Input() limitExceeded: boolean;
  time: string = null;
  includesUpdatePass;
  includesCreateAcc;

  constructor(private router: Router) {
    this.includesUpdatePass = this.router.url.includes(
      "/unauth/update-password"
    );
  }

  onClickCountdown(): void {
    if (this.time) {
      return;
    }

    if (this.limitExceeded) {
      this.countdown = 40 * 60;
      interval(1000)
        .pipe(
          take(this.countdown),
          map((count) => this.countdown - count),
          finalize(() => (this.time = null))
        )
        .subscribe((seconds) => {
          const min = Math.floor(seconds / 60);
          this.time =
            ("00" + min).slice(-2) +
            ":" +
            ("00" + Math.floor(seconds - min * 60)).slice(-2);
          return this.time;
        });
    } else {
      this.countdown = 40;

      interval(1000)
        .pipe(
          take(this.countdown),
          map((count) => this.countdown - count),
          finalize(() => (this.time = null))
        )
        .subscribe((seconds) => {
          this.time = seconds + "";
          return this.time;
        });
    }
  }
}
