<label [for]="id" [formGroup]="parentForm" [class]="customClass">
  <div *ngIf="type != 'uploadimage' && labelName" class="input-title">
    <span class="input-name" [ngClass]="{ required: required }"
      >{{ labelName
      }}<span *ngIf="hasRequiredField" class="text-danger">&nbsp;*</span>
    </span>
  </div>

  <!-- Text input -->
  <input
    *ngIf="inputTypes.includes(type)"
    [type]="type"
    [readonly]="readonly"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
    (input)="verifyContent($event.target.value)"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    appAutofocus
    [isFocusable]="focus"
  />

  <div *ngIf="type == 'search'" class="input-group search">
    <input
      type="text"
      [formControlName]="id"
      [id]="id"
      [placeholder]="placeholder"
      (keyup)="search($event.target.value)"
      class="searchIcon"
    />
    <!--     <div class="input-group-append search">
      <span class="input-group-text">
        <img src="assets/img/application/icons/ic_search_grey.svg" alt="" />
      </span>
    </div> -->
  </div>

  <div *ngIf="type == 'textarea'" class="input-group">
    <textarea
      class="textarea"
      [maxlength]="500"
      [readonly]="readonly"
      [formControlName]="id"
      [id]="id"
      [placeholder]="placeholder"
      (keyup)="onKeyUp($event)"
      (input)="verifyContent($event.target.value)"
      [ngClass]="{
        'danger-input':
          responseError &&
          validateRequiredPattern.errors &&
          validateRequiredPattern.pristine
      }"
    >
    </textarea>
    <div class="character-counter">
      {{ numberCharacters }}/{{ maxChars }} caracteres
    </div>
  </div>

  <select
    *ngIf="type == 'select'"
    [formControlName]="id"
    [id]="id"
    (change)="selectItemHandler($event.target.value)"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
  >
    <option value="" [ngValue]="null" disabled selected>
      {{ placeholder }}
    </option>
    <option *ngFor="let item of collection" value="{{ item[collectionId] }}">
      {{ item[collectionName] }}
    </option>
  </select>

  <select
    *ngIf="type == 'selectName'"
    [formControlName]="id"
    [id]="id"
    (change)="selectItemHandler($event.target.value)"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
  >
    <option value="" [ngValue]="null" disabled selected>
      {{ placeholder }}
    </option>
    <option *ngFor="let item of collection" value="{{ item[collectionName] }}">
      {{ item[collectionName] }}
    </option>
  </select>

  <!-- Select for values that accepts zero-->
  <select
    *ngIf="type == 'select-zero'"
    [formControlName]="id"
    [id]="id"
    (change)="selectItemHandler($event.target.value)"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
  >
    <option value="" [ngValue]="" disabled selected>
      {{ placeholder }}
    </option>
    <option *ngFor="let item of collection" value="{{ item[collectionId] }}">
      {{ item[collectionName] }}
    </option>
  </select>

  <div *ngIf="type == 'date'" class="input-group datepicker">
    <input
      type="text"
      class="form-control"
      [formControlName]="id"
      [id]="id"
      [placeholder]="placeholder"
      [min]="minDate"
      [max]="maxDate"
      (keyup)="keyUpHanddler($event)"
      [ngClass]="{
        'danger-input':
          responseError &&
          validateRequiredPattern.errors &&
          validateRequiredPattern.pristine
      }"
      (dateSelect)="patchDate($event)"
      ngbDatepicker
      #d="ngbDatepicker"
      [minDate]="{ year: 1920, month: 1, day: 1 }"
    />
    <div class="input-group-append hide-password">
      <button class="btn calendar" (click)="d.toggle()" type="button">
        <img src="assets/img/application/icons/ic_calendar_grey.svg" />
      </button>
    </div>
  </div>

  <div *ngIf="type == 'date-maxmin-limits'" class="input-group datepicker">
    <input
      type="text"
      class="form-control"
      [formControlName]="id"
      [id]="id"
      [placeholder]="placeholder"
      [min]="minDate"
      [max]="maxDate"
      (keyup)="keyUpHanddler($event)"
      [ngClass]="{
        'danger-input':
          responseError &&
          validateRequiredPattern.errors &&
          validateRequiredPattern.pristine
      }"
      (dateSelect)="patchDate($event)"
      ngbDatepicker
      #d="ngbDatepicker"
      [minDate]="{ year: 1920, month: 1, day: 1 }"
      [maxDate]="{ year: 2020, month: 12, day: 31 }"
    />
    <div class="input-group-append hide-password">
      <button class="btn calendar" (click)="d.toggle()" type="button">
        <img src="assets/img/application/icons/ic_calendar_grey.svg" />
      </button>
    </div>
  </div>

  <input
    *ngIf="type == 'currency'"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    (change)="selectItemHandler($event.target.value)"
    type="text"
    (keyup)="keyUpHanddler($event)"
    [readonly]="readonly"
    appCurrencyInputMask
    [hasDecimal]="hasDecimal"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
  />

  <input
    *ngIf="type == 'percentage'"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    (change)="selectItemHandler($event.target.value)"
    type="text"
    [readonly]="readonly"
    appPercentInputMask
    [customMessageOnMask]="customMessageOnMask"
    [maxDigits]="maxDigitsOnMask"
    [typeRange]="typeRangeOnMask"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
  />

  <input
    *ngIf="type == 'number'"
    type="number"
    min="0"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
    (input)="verifyContent($event.target.value)"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    digit-only
    appAutofocus
    [readonly]="readonly"
    [isFocusable]="focus"
  />

  <input
    *ngIf="type == 'clabe'"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    (change)="selectItemHandler($event.target.value)"
    (input)="verifyContent($event.target.value)"
    type="text"
    [readonly]="readonly"
    appAutofocus
    [isFocusable]="focus"
    appClabeInputMask
    [maxDigits]="maxDigitsOnCLABEMask"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
  />

  <input
    *ngIf="type == 'phone'"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    (change)="selectItemHandler($event.target.value)"
    (input)="verifyContent($event.target.value)"
    type="text"
    [readonly]="readonly"
    appAutofocus
    [isFocusable]="focus"
    appCellPhoneInputMask
    [maxDigits]="maxDigitsOnPhoneMask"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
  />

  <input
    *ngIf="type == 'postal-code'"
    type="number"
    min="0"
    [formControlName]="id"
    [id]="id"
    [placeholder]="placeholder"
    [ngClass]="{
      'danger-input':
        responseError &&
        validateRequiredPattern.errors &&
        validateRequiredPattern.pristine
    }"
    digit-only
    appAutofocus
    [isFocusable]="focus"
  />

  <div *ngIf="type == 'uploadimage'">
    <div class="screen">
      <div
        tooltipClass="primary-tooltip"
        ngbTooltip="Sube una foto de perfil"
        class="custom-picture"
        [ngStyle]="{ backgroundImage: 'url(' + image + ')' }"
        (click)="imgUploader.click()"
        [ngClass]="{
          'danger-input':
            responseError &&
            validateRequiredPattern.errors &&
            validateRequiredPattern.pristine
        }"
      >
        <span *ngIf="!image && imageText" class="text-image">
          <h3>{{ getFirst(imageText) }}</h3>
        </span>
        <div class="update-picture"></div>
        <span *ngIf="!image" class="upload-image__container">
          <img
            src="assets/img/application/icons/ic_lock-closed_blue.svg"
            alt=""
          />
        </span>
        <input
          type="file"
          name="profile_picture"
          accept="image/x-png,image/jpeg"
          (change)="fileChanged($event)"
          #imgUploader
          hidden
        />
      </div>
    </div>
  </div>

  <div *ngIf="type == 'upload-csv'">
    <div
      *ngIf="!filecsv"
      [@fadeIn]
      class="upload-csv-container"
      appDragDrop
      (onFileDropped)="csvChanged($event)"
    >
      <div class="select-file-box">
        <div class="drag-drop-choose margin-small--bottom">
          <p>Arrastra aquí el archivo .xlsx</p>
          <img
            src="assets/img/application/icons/ic_upload-tiny_grey.svg"
            alt=""
          />
        </div>
        <p>ó</p>
        <button
          class="button--primary auto-w margin-small--top"
          type="button"
          (click)="imgUploader.click()"
        >
          Seleccionar archivo
        </button>
        <input
          type="file"
          name="profile_picture"
          id="profile_picture"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          (change)="csvChanged($event, true)"
          #imgUploader
          hidden
        />
      </div>
    </div>
    <div *ngIf="filecsv" [@fadeInOut] class="uploaded-csv-container">
      <div class="uploaded-content">
        <h5>{{ filecsv.csvFile.name }}</h5>
        <img
          (click)="deleteFile()"
          src="assets/img/application/icons/ic_close-circle-outline_grey.svg"
          alt=""
        />
      </div>
    </div>
  </div>

  <div *ngIf="type == 'number-less-more'" class="number-less-more">
    <label for="withdrawalLimit">
      <div class="number-input">
        <button
          type="button"
          class="number-button--left"
          [disabled]="parentForm.get(id).value <= 0"
          (click)="parentForm.get(id).setValue(parentForm.get(id).value - 1)"
        ></button>
        <input
          type="number"
          [min]="0"
          [formControlName]="id"
          [placeholder]="placeholder"
        />
        <button
          type="button"
          class="number-button--right"
          (click)="parentForm.get(id).setValue(parentForm.get(id).value + 1)"
        ></button>
      </div>
    </label>
  </div>

  <!-- Input password -->
  <div *ngIf="type == 'hidepassword'" class="input-group">
    <input
      [type]="fieldTextType ? 'text' : 'password'"
      [readonly]="readonly"
      [formControlName]="id"
      [id]="id"
      [placeholder]="placeholder"
      [ngClass]="{
        'danger-input':
          responseError &&
          validateRequiredPattern.errors &&
          validateRequiredPattern.pristine
      }"
      (input)="verifyContent($event.target.value)"
    />
    <div class="input-group-append hide-password">
      <span class="input-group-text">
        <img
          (click)="toggleFieldPasswordType()"
          *ngIf="fieldTextType"
          src="assets/img/application/icons/ic_eye-open_grey.svg"
          alt=""
        />
        <img
          (click)="toggleFieldPasswordType()"
          *ngIf="!fieldTextType"
          src="assets/img/application/icons/ic_eye-off_grey.svg"
          alt=""
        />
      </span>
    </div>
  </div>

  <p
    *ngIf="currentError"
    class="input-helper"
    [class.--error]="states.includes('--error')"
    [class.--warning]="states.includes('--warning')"
    [class.--success]="states.includes('--success')"
  >
    {{ currentError }}
  </p>
  <p
    *ngIf="
      responseError &&
      validateRequiredPattern.errors &&
      validateRequiredPattern.pristine
    "
    class="input-helper danger-input"
  >
    El campo es requerido
  </p>
</label>
