import { IUpcomingCycleResponse } from "@declarations/upcoming-cycle-response";

export class UpcomingCycle {
  companyName: string;
  cutoffDate: string;
  endDate: string;
  id: string;
  paymentDate: string;
  periodName: string;
  startDate: string;
  totalAmount: number;
  totalCommission: number;

  constructor(attrs: IUpcomingCycleResponse) {
    this.companyName = attrs.company_name;
    this.cutoffDate = attrs.cutoff_date;
    this.endDate = attrs.end_date;
    this.id = attrs.id;
    this.paymentDate = attrs.payment_date;
    this.periodName = attrs.period_name;
    this.startDate = attrs.start_date;
    this.totalAmount = attrs.total_amount;
    this.totalCommission = attrs.total_commission;
  }
}
