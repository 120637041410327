import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  GuidedTour,
  Orientation,
} from "@shared/components/guided-tour/guided-tour.constants";
import { GuidedTourService } from "@shared/components/guided-tour/guided-tour.service";

@Component({
  selector: "app-onboarding-modal",
  templateUrl: "./onboarding-modal.component.html",
})
export class OnboardingModalComponent implements OnInit {
  @ViewChild("onboardingModal") onboarding: any;

  public dashboardTour: GuidedTour = {
    tourId: "purchases-tour",
    useOrb: false,
    preventBackdropFromAdvancing: true,
    steps: [
      /*{
        title: "Inicio",
        selector: ".home-main-sidebar",
        content:
          "<h4 class='description'> En esta pantalla encontraras un resumen en tiempo real de la actividad de tu empresa. </h4> <div class='steps'> <div class='active'></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> ",
        orientation: Orientation.Right,
        skipStep: false,
      },*/
      {
        title: "Mi empresa",
        selector: ".my-company-main-sidebar",
        content:
          "<h4 class='description'> Aqui encontraras la información de perfil de tu empresa. Asegúrate que este actualizada para que podamos brindarles el mejor servicio posible. </h4> <div class='steps'> <div class='active'></div> <div></div> <div></div> <div></div> <div></div> <div></div> ",
        orientation: Orientation.Right,
        skipStep: false,
      },
      {
        title: "Periodos de pago",
        selector: ".payroll-periods-main-sidebar",
        content:
          "<h4 class='description'> En esta sección nos proporcionarán las fechas de nómina de tu empresa. Es importante que las fechas sean correctas y siempre esten actualizadas. </h4> <div class='steps'> <div class='filled'></div> <div class='active'></div> <div></div> <div></div> <div></div> <div></div> ",
        orientation: Orientation.Right,
        skipStep: false,
      },
      {
        title: "Grupos",
        selector: ".groups-main-sidebar",
        content:
          "<h4 class='description'> Todos los colaboradores tienen que estar asignados a un grupo. Aquí definirás los parámetros de retiro para los colaboradores a nivel grupo. </h4> <div class='steps'> <div class='filled'></div> <div class='filled'></div> <div class='active'></div> <div></div> <div></div> <div></div> ",
        orientation: Orientation.Right,
        skipStep: false,
      },
      {
        title: "Colaboradores",
        selector: ".collaborators-main-sidebar",
        content:
          "<h4 class='description'> Aquí puedes encontrar a todos los colaboradores de tu empresa que se han invitado y registrado al servicio de Monto. </h4> <div class='steps'> <div class='filled'></div> <div class='filled'></div> <div class='filled'></div> <div class='active'></div> <div></div> <div></div>",
        orientation: Orientation.Right,
        skipStep: false,
      },
      {
        title: "Retiros",
        selector: ".withdrawals-collaborators-main-sidebar",
        content:
          "<h4 class='description'> Aquí podrás encontrar toda la información relevante sobre los retiros realizados incluyendo los comprobantes de pago. </h4> <div class='steps'> <div class='filled'></div> <div class='filled'></div> <div class='filled'></div> <div class='filled'></div> <div class='active'></div> <div></div> ",
        orientation: Orientation.Right,
        skipStep: false,
      },
      {
        title: "Deducciones",
        selector: ".deductions-main-sidebar",
        content:
          "<h4 class='description'> Aquí podrás encontrar la reconciliación de un periodo al final de cada cíclo de nómina. Te mostraremos quiénes retiraron y cuanto para que puedan hacer los descuentos necesarios en sus calculos de nomina. </h4> <div class='steps'> <div class='filled'></div> <div class='filled'></div> <div class='filled'></div> <div class='filled'></div> <div class='filled'></div> <div class='active'></div> ",
        orientation: Orientation.Right,
        skipStep: false,
      },
    ],
  };

  constructor(
    private modalService: NgbModal,
    private guidedTourService: GuidedTourService
  ) {}

  ngOnInit(): void {
    /** No content */
  }

  openOnboardingModal(options?: Object): void {
    this.modalService.open(this.onboarding, {
      ...options,
      ...{
        backdrop: "static",
        keyboard: false,
        ariaLabelledBy: "ModalOnBoarding",
        centered: true,
      },
    });
  }

  closeOnboardingModal() {
    this.modalService.dismissAll();
  }

  openOnboarding() {
    this.modalService.dismissAll();
    this.guidedTourService.startTour(this.dashboardTour);
  }
}
