import { ICityResponse } from "@declarations/city-response";

export class City {
  id: string;
  name: string;

  constructor(atrributes: ICityResponse) {
    this.id = atrributes.id;
    this.name = atrributes.name;
  }
}
