import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
} from "@angular/core";
import { PercentPipe } from "@angular/common";

@Directive({
  selector: "[appPercentInputMask]",
  providers: [PercentPipe],
})
export class PercentInputDirective implements OnInit {
  @Input() customMessageOnMask: string;

  @Input()
  set maxDigits(maxDigits: number) {
    this.setRegex(maxDigits);
  }

  @Input()
  set typeRange(typeRange: string) {
    this.setRangeRegex(typeRange);
  }

  private digitRegex: RegExp;

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private PercentPipe: PercentPipe
  ) {
    this.el = this.elementRef.nativeElement;
    this.setRegex();
  }

  ngOnInit() {
    this.el.value = this.PercentPipe.transform(+this.el.value / 100, "1.1");
    if (this.customMessageOnMask)
      this.el.value += " " + this.customMessageOnMask;
  }

  private setRegex(maxDigits?: number) {
    this.digitRegex = new RegExp(this.regexString(maxDigits), "g");
  }

  // build the regex based on max pre decimal digits allowed
  private regexString(max?: number) {
    const maxStr = max ? `{0,${max}}` : `+`;
    return `^(\\d${maxStr}(\\.\\d{0,2})?|\\.\\d{0,2})$`;
  }

  private setRangeRegex(typeRange?: string) {
    this.digitRegex = new RegExp(this.regexRange(typeRange), "g");
  }

  private regexRange(typeRange?: string) {
    if (typeRange == "decimal") return `^(?:[0-9]|0[0-9]|10)$`;
    if (typeRange == "centennial") return `^0*(?:[0-9][0-9]?|100)$`;
  }

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    let cleanValue;

    if (!event) {
      return;
    }

    if (event) cleanValue = (event.match(this.digitRegex) || []).join("");

    if (cleanValue || !event) this.lastValid = cleanValue;
    this.el.value = cleanValue || this.lastValid;

    if (!this.el.value.includes("%")) {
      this.el.value = this.PercentPipe.transform(+event / 100, "1.1");
      if (this.customMessageOnMask)
        this.el.value += " " + this.customMessageOnMask;
    }
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    // on focus remove percentage formatting
    this.el.value = value.replace(/[^0-9.]+/g, "");
    this.el.select();
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    // on blur, add percentage formatting
    if (!this.el.value.includes("%") && value) {
      this.el.value = this.PercentPipe.transform(value / 100, "1.1");
      if (this.customMessageOnMask)
        this.el.value += " " + this.customMessageOnMask;
    }
  }

  @HostListener("keydown.control.z", ["$event.target.value"])
  onUndo(value) {
    this.el.value = "";
  }

  // variable to store last valid input
  private lastValid = "";
  @HostListener("input", ["$event"])
  onInput(event) {
    // on input, run regex to only allow certain characters and format
    const cleanValue = (event.target.value.match(this.digitRegex) || []).join(
      ""
    );
    if (cleanValue || !event.target.value) this.lastValid = cleanValue;
    this.el.value = cleanValue || this.lastValid;
  }
}
