import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ICollaboratorsResponse } from "@declarations/collaborators-response";
import { ICollaboratorResponse } from "@declarations/collaborator-response";
import { Collaborator } from "@models/collaborator";
import { IToggleCollaboratorStatusPayload } from "@declarations/toggle-collaborator-status-payload";
import { CollaboratorDetail } from "@models/collaborator-detail";
import { ICollaboratorDetailResponse } from "@declarations/collaborator-detail-response";
import { City } from "@models/city";
import { ICitiesResponse } from "@declarations/cities-response";
import { ICityResponse } from "@declarations/city-response";
import { State } from "@models/state";
import { IStatesResponse } from "@declarations/states-response";
import { IStateResponse } from "@declarations/state-response";
import { ICollaboratorDetailPayload } from "@declarations/collaborator-detail-payload";
import { Pagination } from "@models/pagination";
import { IGroupsListResponse } from "@declarations/groups-list-response";
import { IGroupListResponse } from "@declarations/group-list-response";
import { GroupList } from "@models/group-list";
import { IBanksListResponse } from "@declarations/banks-list-response";
import { IBankListResponse } from "@declarations/bank-list-response";
import { BankList } from "@models/bank-list";
import { ITableCollectionResponse } from "@declarations/table-collection-response";
import { MaxMontosCount } from "@models/max-montos-count";
import { IMaxMontosCountResponse } from "@declarations/max-montos-count-response";
import { IGetCollaboratorsFilterParam } from "@shared/parameters/get-collaborators-filter.param";
import { ITogglePennyCheckPayload } from "@declarations/toggle-penny-check-payload";
import { ISendInvitationPayload } from "@declarations/send-invitation-payload";
import { ISendDeactivateMultipleEmployeesPayload } from "@declarations/send-deactivate-multiple-employees-payload";
import { ISendSuspendMultipleEmployeesPayload } from "@declarations/send-suspend-multiple-employees-payload";
import { ISendActivateMultipleEmployeesPayload } from "@declarations/send-activate-multiple-employees-payload";
import { ISendValidateMultipleEmployeesPayload } from "@declarations/send-validate-multiple-employees-payload";
import { IInfoCollaboratorsResponse } from "@declarations/info-collaborators-response";
import { InfoCollaboratorDetail } from "@models/info-collaborator-detail";
import { IInfoCollaboratorDetailResponse } from "@declarations/info-collaborator-detail-response";
import { IInfoCollaboratorDetailPayload } from "@declarations/info-collaborator-detail-payload";
import { IToggleInfoCollaboratorStatusPayload } from "@declarations/toggle-info-collaborator-status-payload";

@Injectable()
export class CollaboratorService {
  constructor(private httpClient: HttpClient) {
    /** No content */
  }

  inviteCollaborator(payload) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "admin")
      .set("Content-Type", "application/json");
    return this.httpClient.post(
      `${environment.API}/api/v2/employees/single_admin_upload`,
      payload,
      { headers }
    );
  }

  importCollaborators(payload: FormData) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "admin");
    return this.httpClient.post(
      `${environment.API}/api/v2/employees/admin_upload`,
      payload,
      { headers, responseType: "text" }
    );
  }

  downloadTemplateCollaborators() {
    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "arraybuffer";
      withCredentials?: boolean;
    } = {
      responseType: "arraybuffer",
    };

    return this.httpClient
      .get(`${environment.API}/import-user-template`, options)
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  getGroups(): Observable<Array<GroupList>> {
    const mapResponseToGroups = (response: IGroupsListResponse) => {
      const transformJSONToGroup = (group: IGroupListResponse) => {
        return new GroupList(group);
      };

      return response.groups.map(transformJSONToGroup);
    };

    return this.httpClient
      .get(`${environment.API}/groups_select`)
      .pipe(map(mapResponseToGroups));
  }

  getBanks(): Observable<Array<BankList>> {
    const mapResponseToBanks = (response: IBanksListResponse) => {
      const transformJSONToBank = (bank: IBankListResponse) => {
        return new BankList(bank);
      };

      return response.banks.map(transformJSONToBank);
    };
    return this.httpClient
      .get(`${environment.API}/banks`)
      .pipe(map(mapResponseToBanks));
  }

  getCollaborators(
    params: IGetCollaboratorsFilterParam,
    page: number
  ): Observable<ITableCollectionResponse> {
    let RequestHttpParams = new HttpParams();

    if (params.group) {
      RequestHttpParams = RequestHttpParams.set(
        "group",
        params.group.toString()
      );
    }
    if (params.status) {
      RequestHttpParams = RequestHttpParams.set(
        "status",
        params.status.toString()
      );
    }
    if (params.paymentFrequency) {
      RequestHttpParams = RequestHttpParams.set(
        "payment_frequency",
        params.paymentFrequency.toString()
      );
    }
    if (params.requestedMontos) {
      RequestHttpParams = RequestHttpParams.set(
        "montos_count",
        params.requestedMontos.toString()
      );
    }
    if (params.userStatus) {
      RequestHttpParams = RequestHttpParams.set(
        "user_status",
        params.userStatus.toString()
      );
    }
    if (params.query) {
      RequestHttpParams = RequestHttpParams.set(
        "query",
        params.query.toString()
      );
    }

    if (params.sort) {
      if (params.sort["column"]) {
        RequestHttpParams = RequestHttpParams.set(
          params.sort["param"].toString(),
          params.sort["column"].toString() +
            "," +
            params.sort["order"].toString()
        );
      } else {
        RequestHttpParams = RequestHttpParams.set(
          params.sort["param"].toString(),
          params.sort["order"].toString()
        );
      }
    }

    const mapResponseToCollaborators = (response: ICollaboratorsResponse) => {
      const transformJSONToCollaborator = (
        collaborator: ICollaboratorResponse
      ) => {
        return new Collaborator(collaborator);
      };
      const transformJSONToPagination = new Pagination(response.meta);

      const resultJSON = {
        items: response.employees.map(transformJSONToCollaborator),
        pagination: transformJSONToPagination,
      };

      return resultJSON;
    };

    return this.httpClient
      .get(`${environment.API}/employees?page=${page}`, {
        params: RequestHttpParams,
      })
      .pipe(map(mapResponseToCollaborators));
  }

  getCollaborator(collaboratorId: string): Observable<CollaboratorDetail> {
    const mapResponseToCollaborators = (
      collaborator: ICollaboratorDetailResponse
    ) => {
      return new CollaboratorDetail(collaborator);
    };
    return this.httpClient
      .get(`${environment.API}/employees/${collaboratorId}`)
      .pipe(map(mapResponseToCollaborators));
  }

  getInfoCollaborator(
    collaboratorId: string
  ): Observable<InfoCollaboratorDetail> {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "admin");

    const mapResponseToCollaborators = (
      collaborator: IInfoCollaboratorDetailResponse
    ) => {
      return new InfoCollaboratorDetail(collaborator);
    };
    return this.httpClient
      .get(`${environment.API}/api/v2/employee_infos/${collaboratorId}`, {
        headers,
      })
      .pipe(map(mapResponseToCollaborators));
  }

  getSuperInfoCollaborator(
    collaboratorId: string
  ): Observable<InfoCollaboratorDetail> {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "superadmin");

    const mapResponseToCollaborators = (
      collaborator: IInfoCollaboratorDetailResponse
    ) => {
      return new InfoCollaboratorDetail(collaborator);
    };
    return this.httpClient
      .get(`${environment.API}/api/v2/employee_infos/${collaboratorId}`, {
        headers,
      })
      .pipe(map(mapResponseToCollaborators));
  }

  getEmployeesInfo(
    params: IGetCollaboratorsFilterParam,
    page: number
  ): Observable<ITableCollectionResponse> {
    let RequestHttpParams = new HttpParams();
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "admin");

    if (params.query) {
      RequestHttpParams = RequestHttpParams.set(
        "query",
        params.query.toString()
      );
    }
    if (params.sort) {
      if (params.sort["column"]) {
        RequestHttpParams = RequestHttpParams.set(
          params.sort["param"].toString(),
          params.sort["column"].toString() +
            "," +
            params.sort["order"].toString()
        );
      } else {
        RequestHttpParams = RequestHttpParams.set(
          params.sort["param"].toString(),
          params.sort["order"].toString()
        );
      }
    }

    const mapResponseToCollaborators = (
      response: IInfoCollaboratorsResponse
    ) => {
      const transformJSONToPagination = new Pagination(response.meta);
      if (response.employee_infos !== undefined) {
        const resultJSON = {
          items: response.employee_infos,
          pagination: transformJSONToPagination,
        };
        return resultJSON;
      }
    };

    return this.httpClient
      .get(`${environment.API}/api/v2/employee_infos?page=${page}`, {
        headers,
        params: RequestHttpParams,
      })
      .pipe(map(mapResponseToCollaborators));
  }

  updateCollaborator(id: string, payload: ICollaboratorDetailPayload) {
    return this.httpClient.put(`${environment.API}/employees/${id}`, payload);
  }

  updateInfoCollaborator(id: string, payload: IInfoCollaboratorDetailPayload) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("Content-Type", "application/json");
    return this.httpClient.patch(
      `${environment.API}/api/v2/employee_infos`,
      payload,
      { headers }
    );
  }

  updateSuperInfoCollaborator(
    id: string,
    payload: IInfoCollaboratorDetailPayload
  ) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("Content-Type", "application/json")
      .set("X-Client", "superadmin");
    return this.httpClient.patch(
      `${environment.API}/api/v2/employee_infos`,
      payload,
      { headers }
    );
  }

  updateValidateCollaborator(
    id: string,
    payload: Partial<ICollaboratorDetailPayload>
  ) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("Content-Type", "application/json");
    return this.httpClient.put(
      `${environment.API}/api/v2/employees/admin_update_and_confirm?employee_id=${id}`,
      payload,
      { headers }
    );
  }

  sendValidateMultipleEmployeesArray(
    payload: ISendValidateMultipleEmployeesPayload
  ) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("Content-Type", "application/json");
    return this.httpClient.put(
      `${environment.API}/api/v2/employees/admin_batch_confirm`,
      payload,
      { headers }
    );
  }

  validateCollaborator(id: string) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("Content-Type", "application/json");
    return this.httpClient.put(
      `${environment.API}/api/v2/employees/admin_confirm?employee_id=${id}`,
      {},
      { headers }
    );
  }

  getStates(): Observable<Array<State>> {
    const mapResponseToStates = (response: IStatesResponse) => {
      const transformJSONToState = (state: IStateResponse) => {
        return new State(state);
      };
      return response.states.map(transformJSONToState);
    };

    return this.httpClient
      .get(`${environment.API}/states`)
      .pipe(map(mapResponseToStates));
  }

  getCities(id: string): Observable<Array<City>> {
    const mapResponseToCities = (response: ICitiesResponse) => {
      const transformJSONToCities = (city: ICityResponse) => {
        return new City(city);
      };
      return response.municipalities.map(transformJSONToCities);
    };

    return this.httpClient
      .get(`${environment.API}/states/${id}/municipalities`)
      .pipe(map(mapResponseToCities));
  }

  downloadCollaborators() {
    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "arraybuffer";
      withCredentials?: boolean;
    } = {
      responseType: "arraybuffer",
    };

    return this.httpClient
      .get(`${environment.API}/employees/export`, options)
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  // TODO add type of payload and implement toggle collaborator
  toggleCollaboratorStatus(
    collaboratorId: string,
    payload: IToggleCollaboratorStatusPayload
  ) {
    return this.httpClient.put(
      `${environment.API}/employees/${collaboratorId}/toggle_activation`,
      payload,
      { responseType: "text" }
    );
  }

  toggleInfoCollaboratorStatus(payload: IToggleInfoCollaboratorStatusPayload) {
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("Content-Type", "application/json");
    return this.httpClient.patch(
      `${environment.API}/api/v2/employee_infos/employee_status`,
      payload,
      { headers, responseType: "text" }
    );
  }

  /** TODO the endpoint is a put but doesn't receive a payload */
  sendInvitation(collaboratorId: string) {
    return this.httpClient.put(
      `${environment.API}/employees/${collaboratorId}/invite_employee`,
      {},
      { responseType: "text" }
    );
  }

  /** TODO the endpoint is a put but doesn't receive a payload */
  getInvitation(collaboratorId: string) {
    return this.httpClient.put(
      `${environment.API}/employees/${collaboratorId}/get_employee_invitation_url`,
      {},
      { responseType: "text" }
    );
  }

  togglePennyCheck(collaboratorId: string, payload: ITogglePennyCheckPayload) {
    return this.httpClient.put(
      `${environment.API}/employees/${collaboratorId}/penny_checks`,
      payload
    );
  }

  getMaxMontosCount(): Observable<MaxMontosCount> {
    const mapResponseToMaxMontosCount = (
      maxlimits: IMaxMontosCountResponse
    ) => {
      return new MaxMontosCount(maxlimits);
    };

    return this.httpClient
      .get(`${environment.API}/max_montos_count`)
      .pipe(map(mapResponseToMaxMontosCount));
  }

  sendInvitationArray(payload: ISendInvitationPayload) {
    return this.httpClient.put(
      `${environment.API}/invite_multiple_employees`,
      payload,
      { responseType: "text" }
    );
  }

  getInvitationArray(payload: ISendInvitationPayload) {
    return this.httpClient.put(`${environment.API}/get_url`, payload, {
      responseType: "text",
    });
  }

  sendDeactivateMultipleEmployeesArray(
    payload: ISendDeactivateMultipleEmployeesPayload
  ) {
    return this.httpClient.put(
      `${environment.API}/deactivate_multiple_employees`,
      payload,
      { responseType: "text" }
    );
  }

  sendSuspendMultipleEmployeesArray(
    payload: ISendSuspendMultipleEmployeesPayload
  ) {
    return this.httpClient.put(
      `${environment.API}/suspend_multiple_employees`,
      payload,
      { responseType: "text" }
    );
  }

  sendActivateMultipleEmployeesArray(
    payload: ISendActivateMultipleEmployeesPayload
  ) {
    return this.httpClient.put(
      `${environment.API}/activate_multiple_employees`,
      payload,
      { responseType: "text" }
    );
  }

  superDownloadCollaborators(companyId: string) {
    let RequestHttpParams = new HttpParams();

    if (companyId) {
      RequestHttpParams = RequestHttpParams.set("company_id", companyId);
    }

    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "arraybuffer";
      withCredentials?: boolean;
    } = {
      params: RequestHttpParams,
      responseType: "arraybuffer",
    };

    return this.httpClient
      .get(`${environment.API}/employees/export`, options)
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  superGetGroups(companyId: string): Observable<Array<GroupList>> {
    let RequestHttpParams = new HttpParams();

    if (companyId) {
      RequestHttpParams = RequestHttpParams.set("company_id", companyId);
    }

    const mapResponseToGroups = (response: IGroupsListResponse) => {
      const transformJSONToGroup = (group: IGroupListResponse) => {
        return new GroupList(group);
      };

      return response.groups.map(transformJSONToGroup);
    };

    return this.httpClient
      .get(`${environment.API}/groups_select`, {
        params: RequestHttpParams,
      })
      .pipe(map(mapResponseToGroups));
  }

  superImportCollaborators(payload: FormData, companyId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let RequestHttpParams = new HttpParams();

    if (companyId) {
      RequestHttpParams = RequestHttpParams.set("company_id", companyId);
    }
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "superadmin");
    return this.httpClient.post(
      `${environment.API}/api/v2/employees/admin_upload?company_id=${companyId}`,
      payload,
      { headers, responseType: "text" }
    );
  }

  superInviteCollaborator(payload, companyId) {
    let RequestHttpParams = new HttpParams();
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "superadmin");

    if (companyId) {
      RequestHttpParams = RequestHttpParams.set("company_id", companyId);
    }

    return this.httpClient.post(
      `${environment.API}/api/v2/employees/single_admin_upload`,
      payload,
      { headers, params: RequestHttpParams }
    );
  }

  superGetMaxMontosCount(): Observable<MaxMontosCount> {
    const mapResponseToMaxMontosCount = (
      maxlimits: IMaxMontosCountResponse
    ) => {
      return new MaxMontosCount(maxlimits);
    };

    return this.httpClient
      .get(`${environment.API}/max_montos_count`)
      .pipe(map(mapResponseToMaxMontosCount));
  }
}
