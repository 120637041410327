import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChangesInvoice } from "@models/charges-invoice";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ManualPaymentPayload } from "@payloads/manual-payment.payload";
import { ChargesService } from "@services/charges.service";
import { Toastr } from "@services/toastr.service";

@Component({
  selector: "app-deductions-charges-modal",
  templateUrl: "./deductions-charges-modal.component.html",
  providers: [ChargesService],
})
export class DeductionsChargesComponent implements OnInit {
  @ViewChild("viewDeductionsCharges")
  viewDeductionsCharges;

  isLoading: boolean;

  invoiceDetail: ChangesInvoice;

  shouldHideCloseButton = false;

  paymentForm: FormGroup = this.fb.group({
    amount: [null, Validators.required],
  });

  constructor(
    private chargeService: ChargesService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toast: Toastr
  ) {}

  ngOnInit(): void {}

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  async openViewDeductionsChargesModal(invoiceDetail): Promise<FormGroup> {
    this.invoiceDetail = invoiceDetail;
    let option: boolean = false;

    await this.modalService
      .open(this.viewDeductionsCharges, {
        ...{
          keyboard: false,
          ariaLabelledBy: "ModalViewDeductionsCharges",
          centered: true,
        },
      })
      .result.then(
        (result) => {
          return (option = result);
        },
        (reason) => {
          return (option = reason);
        }
      );

    if (option) {
      return this.paymentForm;
    } else {
      return null;
    }
  }

  resetModalForm() {
    this.paymentForm.reset();
  }

  closeDeductionsChargesModal() {
    this.modalService.dismissAll();
  }

  validateAmount(item) {
    if (item.target.value > this.invoiceDetail.totalToPayTotalAmount) {
      this.toast.single_error(
        "La cantidad recibida no puede ser mayor al adeudo"
      );
      this.paymentForm.reset();
    }
  }
}
