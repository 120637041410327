import { IRepresentativeResponse } from "@declarations/representative-response";

export class Representative {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  cellphone: string;
  constructor(attrs: IRepresentativeResponse) {
    this.email = attrs.email;
    this.first_name = attrs.first_name;
    this.last_name = attrs.last_name;
    this.cellphone = attrs.cellphone;
    this.id = attrs.id;
  }
}
