import { HttpClient } from "@angular/common/http";
import { IServerEmptyResponse } from "@declarations/server-empty-response";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LogoutService {
  constructor(private httpClient: HttpClient) {
    /** Do nothing */
  }

  logOut(): Observable<IServerEmptyResponse> {
    return this.httpClient
      .delete(`${environment.API}/sessions`)
      .pipe(map((response: IServerEmptyResponse) => response));
  }
}
