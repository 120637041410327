<div
  *ngIf="cycle"
  class="section__edit-payroll-periods-calendar margin-medium--bottom"
>
  <div class="edit-payroll-periods-calendar__header">
    <div class="payroll-periods-detail-calendar__month-navigator">
      <button
        type="button"
        class="button--calendar-cycle-navigator left"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        <img src="assets/img/application/icons/ic_chevron-left_grey.svg" />
      </button>
      <h4>{{ viewDate | date: "MMMM" | titlecase }}</h4>
      <button
        type="button"
        class="button--calendar-cycle-navigator right"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        <img src="assets/img/application/icons/ic_chevron-right_grey.svg" />
      </button>
    </div>
    <div class="payroll-periods-edit-calendar__navigator">
      <button
        type="button"
        class="button--calendar-cycle-navigator left"
        (click)="previousCycle()"
      >
        <img src="assets/img/application/icons/ic_chevron-left_grey.svg" />
      </button>
      <h5 class="strong">
        {{ this.viewState.currentViewingCycle.startDate | date: "d" }} -
        {{ this.viewState.currentViewingCycle.endDate | date: "d" }} de
        {{ this.viewState.currentViewingCycle.endDate | date: "LLLL" }},
        {{ this.viewState.currentViewingCycle.endDate | date: "yyyy" }}
      </h5>
      <button
        type="button"
        class="button--calendar-cycle-navigator right"
        (click)="nextCycle()"
      >
        <img src="assets/img/application/icons/ic_chevron-right_grey.svg" />
      </button>
    </div>
    <div class="header__content">
      <button
        type="button"
        [ngClass]="{ active: viewState.currentViewingCycle.startDate }"
        class="button--calendar-steps calendar-event__detail-start"
      >
        Inicio
      </button>
      <button
        type="button"
        [ngClass]="{ active: viewState.currentViewingCycle.endDate }"
        class="button--calendar-steps calendar-event__detail-end"
        (click)="endDateOnChange()"
      >
        Fin
      </button>
      <button
        type="button"
        [ngClass]="{ active: viewState.currentViewingCycle.cutoffDate }"
        class="button--calendar-steps calendar-event__detail-cut"
        (click)="cutoffDateOnChange()"
      >
        Corte
      </button>
      <button
        type="button"
        [ngClass]="{ active: viewState.currentViewingCycle.paymentDate }"
        class="button--calendar-steps calendar-event__detail-payment"
        (click)="paymentDateOnChange()"
      >
        Pago
      </button>
    </div>
  </div>
  <div class="edit-payroll-periods-calendar__content">
    <mwl-calendar-month-view
      [events]="events"
      [viewDate]="viewDate"
      (beforeViewRender)="renderCurrentCycleEvents($event)"
      (dayClicked)="dayClicked($event.day)"
    >
    </mwl-calendar-month-view>
  </div>
</div>
