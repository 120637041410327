import { ICompanyResponse } from "@declarations/company-response";

export class Company {
  adminName: string;
  employees: number;
  id: string;
  industryLevel: string;
  industryLevelid: string;
  status: boolean;
  lastStatusChange: string;
  name: string;
  registrationStatus: string;
  requestedMontos: number;
  state: string;

  constructor(companyAttrs: ICompanyResponse) {
    this.adminName = companyAttrs.admin_name;
    this.employees = companyAttrs.employees;
    this.id = companyAttrs.id;
    this.industryLevel = companyAttrs.industry_level;
    this.industryLevelid = companyAttrs.industry_level_id;
    this.status = companyAttrs.is_active;
    this.lastStatusChange = companyAttrs.last_status_change;
    this.name = companyAttrs.name;
    this.registrationStatus = companyAttrs.registration_status;
    this.requestedMontos = companyAttrs.requested_montos;
    this.state = companyAttrs.state;
  }
}
