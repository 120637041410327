<ng-template #viewCycleOutRange let-modal>
  <div id="section__view-cycle-out-range-modal">
    <div class="modal-header">
      <h3 class="strong h3-extend-medium"></h3>
      <img
        (click)="modal.dismiss(false)"
        src="assets/img/application/icons/ic_close_black.svg"
        alt=""
      />
    </div>
    <div class="modal-body">
      <h4>
        La fecha de pago seleccionada está fuera del periodo de pago ingresado
      </h4>
      <br />
      <h4>¿Estas seguro que la fecha de pago es correcta?</h4>
    </div>
    <div class="modal-footer">
      <button (click)="modal.dismiss(false)" class="button--outline-primary">
        Cancelar
      </button>
      <button (click)="modal.close(true)" class="button--primary img--right">
        Continuar
        <img
          src="assets/img/application/icons/ic_chevron-right_white.svg"
          alt=""
        />
      </button>
    </div>
  </div>
</ng-template>
