import { Component, OnInit, ViewChild } from "@angular/core";
import { IInvoicesCollectionResponse } from "@declarations/invoices-collection-response";
import { IUpComingCyclesCollectionResponse } from "@declarations/upcoming-cycles-collection-response";
import { CyclesByPeriod } from "@models/cycles-by-period";
import { Invoice } from "@models/invoice";
import { UpcomingCycle } from "@models/upcoming-cycle";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "app-deductions-detail",
  templateUrl: "./deductions-detail.component.html",
})
export class DeductionsDetailComponent implements OnInit {
  @ViewChild("viewDeductionsDetail")
  viewDeductionsDetail;

  defaultPanelId: string = "panel2";

  lastPanelId: string = null;

  shouldHideCloseButton = false;

  upcomingCycles: IUpComingCyclesCollectionResponse;

  pendingInvoices: IInvoicesCollectionResponse;

  vigentPendingInvoices: Array<Invoice> = [];

  delayPendingInvoices: Array<Invoice> = [];

  totalToPay: number;

  currentView = null;

  cyclesByPeriod: Array<CyclesByPeriod> = [];

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  openViewDeductionsDetailModal(
    options?,
    upcomingCycles?: IUpComingCyclesCollectionResponse,
    invoices?: IInvoicesCollectionResponse,
    totalToPay?: number
  ): void {
    this.upcomingCycles = upcomingCycles;
    this.pendingInvoices = invoices;
    this.totalToPay = totalToPay;

    if (this.upcomingCycles) {
      this.currentView = 1;
      this.cyclesByPeriod = [];
      this.cyclesByPeriodHandler();
    }
    if (this.pendingInvoices) {
      this.currentView = 2;
      this.vigentPendingInvoices = [];
      this.delayPendingInvoices = [];
      this.cleanCycles();
    }

    this.modalService.open(this.viewDeductionsDetail, {
      ...options,
      ...{
        keyboard: true,
        ariaLabelledBy: "ModalViewDeductionsDetail",
        centered: true,
      },
    });
  }

  closeDeductionsDetailModal() {
    this.modalService.dismissAll();
  }

  cyclesByPeriodHandler() {
    if (this.upcomingCycles) {
      this.upcomingCycles.items.forEach((element, index) => {
        let newPeriod = new CyclesByPeriod();
        newPeriod.periodName = element.periodName;

        const isInArray = this.cyclesByPeriod
          .map(function (e) {
            return e["periodName"];
          })
          .indexOf(element.periodName);
        if (isInArray == -1) {
          this.cyclesByPeriod.push(newPeriod);
        }
        this.cyclesByPeriod[this.cyclesByPeriod.length - 1].cycles.push(
          element
        );
      });
    }
  }

  cleanCycles() {
    let today = moment(new Date());

    this.pendingInvoices.items.forEach((item) => {
      if (item.totalToPay > 0) {
        if (
          today.startOf("day") >
          moment(new Date(item.paymentDate)).startOf("day")
        ) {
          this.delayPendingInvoices.push(item);
        } else {
          this.vigentPendingInvoices.push(item);
        }
      }
    });
  }
}
