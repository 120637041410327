import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IChargeResponse } from "@declarations/charge-response";
import { IChargesResponse } from "@declarations/charges-response";
import { ITableCollectionResponse } from "@declarations/table-collection-response";
import { IUpcomingCycleResponse } from "@declarations/upcoming-cycle-response";
import { IUpcomingCyclesResponse } from "@declarations/upcoming-cycles-response";
import { UpcomingCycle } from "@models/upcoming-cycle";
import { environment } from "@environments/environment";
import { Charge } from "@models/charge";
import { Pagination } from "@models/pagination";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IUpComingCyclesCollectionResponse } from "@declarations/upcoming-cycles-collection-response";
import { IPendingInvoicesResponse } from "@declarations/pending-invoices";
import { IPendingInvoiceResponse } from "@declarations/pending-invoice";
import { PendingInvoices } from "@models/pending-invoice";
import { IPendingInvoiceCollectionResponse } from "@declarations/pending-invoice-collection-response";
import { IChargesInvoicesResponse } from "@declarations/charges-invoices-response";
import { IChargesInvoiceResponse } from "@declarations/charges-invoice-response";
import { ChangesInvoice } from "@models/charges-invoice";
import { IManualPaymentPayload } from "@declarations/manual-payment-payload";

@Injectable()
export class ChargesService {
  constructor(private httpClient: HttpClient) {
    /** Do nothing */
  }

  getCharges(page): Observable<ITableCollectionResponse> {
    const mapResponseToDeductions = (response: IChargesResponse) => {
      const transformJSONToDeduction = (charge: IChargeResponse) => {
        return new Charge(charge);
      };
      const transformJSONToPagination = new Pagination(response.meta);
      const resultJSON = {
        items: response.invoices.map(transformJSONToDeduction),
        pagination: transformJSONToPagination,
      };
      return resultJSON;
    };

    return this.httpClient
      .get(`${environment.API}/collections?page=${page}`)
      .pipe(map(mapResponseToDeductions));
  }

  getNextPaymentDates(): Observable<IUpComingCyclesCollectionResponse> {
    const mapResponseToNextPaymentDates = (
      response: IUpcomingCyclesResponse
    ) => {
      const transformJSONToUpcomingCycle = (cycle: IUpcomingCycleResponse) => {
        return new UpcomingCycle(cycle);
      };
      const resultJSON = {
        items: response.upcoming_cycles.map(transformJSONToUpcomingCycle),
      };
      return resultJSON;
    };

    return this.httpClient
      .get(`${environment.API}/collections/next_payment_dates`)
      .pipe(map(mapResponseToNextPaymentDates));
  }

  getPendingInvoices(): Observable<IPendingInvoiceCollectionResponse> {
    const mapResponseToPendingInvoices = (
      response: IPendingInvoicesResponse
    ) => {
      const transformToJSON = (invoice: IPendingInvoiceResponse) => {
        return new PendingInvoices(invoice);
      };
      const resultJSON = {
        items: response.pending_invoices.map(transformToJSON),
      };
      return resultJSON;
    };

    return this.httpClient
      .get(`${environment.API}/collections/pending_invoices`)
      .pipe(map(mapResponseToPendingInvoices));
  }

  getCollectionDetail(id: string): Observable<ChangesInvoice> {
    const transformToJSON = (invoice: IChargesInvoiceResponse) => {
      return new ChangesInvoice(invoice);
    };

    return this.httpClient
      .get(`${environment.API}/collections/${id}`)
      .pipe(map(transformToJSON));
  }

  sendManualPayment(id: string, payload: IManualPaymentPayload) {
    return this.httpClient.put(
      `${environment.API}/collections/manual_payment/${id}`,
      payload,
      { responseType: "text" }
    );
  }

  generateDownloadFiles(folio: string) {
    let requestHttpParams = new HttpParams();
    return this.httpClient.post(
      `${environment.API}/report_deductions`,
      { folio: folio, notify: false },
      {
        params: requestHttpParams,
      }
    );
  }
}
