import { IIndustryLevelResponse } from "@declarations/industry-level-response";

export class IndustryLevel {
  id: string;
  name: string;

  constructor(companyAttrs: IIndustryLevelResponse) {
    this.id = companyAttrs.id;
    this.name = companyAttrs.name;
  }
}
