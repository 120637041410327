import * as moment from "moment";
import { toLocalTz } from "@services/index";

export class PayrollPeriodCycle {
  adminConfirmed: boolean;
  cutoffDate: Date;
  endDate: Date;
  id: string;
  newCycle: boolean;
  paymentDate: Date;
  reconciliationDate: Date;
  startDate: Date;

  /** TODO: Add cycle attrs data type */
  constructor(cycleAttrs: any) {
    this.newCycle = cycleAttrs.new_cycle ? cycleAttrs.new_cycle : false;
    this.adminConfirmed = cycleAttrs.admin_confirmed;
    this.cutoffDate = cycleAttrs.cutoff_date
      ? toLocalTz(cycleAttrs.cutoff_date).toDate()
      : null;
    this.endDate = cycleAttrs.end_date
      ? toLocalTz(cycleAttrs.end_date).toDate()
      : null;
    (this.id = cycleAttrs.id ? cycleAttrs.id : null),
      (this.paymentDate = cycleAttrs.payment_date
        ? toLocalTz(cycleAttrs.payment_date).toDate()
        : null);
    this.reconciliationDate = cycleAttrs.reconciliation_date
      ? toLocalTz(cycleAttrs.reconciliation_date).toDate()
      : null;
    this.startDate = cycleAttrs.start_date
      ? toLocalTz(cycleAttrs.start_date).toDate()
      : null;
  }

  /** TODO: This method should be in a ViewModel */
  daysInCycle(): number {
    return moment(this.endDate).diff(moment(this.startDate), "days") + 1;
  }
}
