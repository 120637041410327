import { ICommissionStaggeredResponse } from "@declarations/commission-staggered-response";

export class CommissionStaggered {
  commissionId: string;
  commissionValue: number;
  createdAt: string;
  id: string;
  maxQuantity: string;
  minQuantity: string;
  stepNumber: number;
  updatedAt: string;

  constructor(attrs: ICommissionStaggeredResponse) {
    this.commissionId = attrs.commission_id;
    this.commissionValue = +attrs.commission_value;
    this.createdAt = attrs.created_at;
    this.id = attrs.id;
    this.maxQuantity = attrs.max_quantity.slice(0, -2);
    this.minQuantity = attrs.min_quantity.slice(0, -2);
    this.stepNumber = attrs.step_number;
    this.updatedAt = attrs.updated_at;
  }
}
