import { Component, OnInit, ViewChild } from "@angular/core";
import { LogsModalComponent } from "@shared/components/modals/logs-modal/logs-modal.component";

@Component({
  selector: "app-go-to-logs",
  templateUrl: "./go-to-logs.component.html",
})
export class GoToLogsComponent implements OnInit {
  @ViewChild(LogsModalComponent)
  logsModalComponent: LogsModalComponent;

  constructor() {}

  ngOnInit(): void {}

  goToLogs(): void {
    this.logsModalComponent.openModal({
      keyboard: false,
    });
  }
}
