import { ICompanyDetailResponse } from "@declarations/company-detail-response";
import { Representative } from "./representative";
import { IImageResponse } from "@declarations/image-response";
import { CompanyMontoSetting } from "./company-monto-setting";
import { PaymentFrequencies } from "./payment-frequencies";
import { IPaymentFrequenciesResponse } from "@declarations/payment-frequencies-response";
import { Commission } from "./commission";
import { ICompanyCommentResponse } from "@declarations/company-comments-response";
import { CompanyComments } from "./company-comments";
import { Address } from "./address";
import { IRepresentativeResponse } from "@declarations/representative-response";

export class CompanyDetail {
  address: Address;
  adminPhone: string;
  clabe: string;
  commission: Commission;
  companyComments: Array<CompanyComments>;
  dailyInterestRate: string;
  employees: number;
  employeesInfo: number;
  formattedTimeZone: string;
  id: string;
  industryLevelName: string;
  industryLevel: string;
  industrySubLevelName: string;
  industrySubLevel: string;
  isActive: boolean;
  legalName: string;
  /** TODO set type to logo */
  logo: IImageResponse;
  municipalityName: string;
  municipality: string;
  name: string;
  payrollManagement: string;
  registrationStatus: string;
  representatives: Array<Representative>;
  requestedMontos: number;
  rfc: string;
  selectedFrequencies: string;
  stateName: string;
  state: string;
  montoSettings: CompanyMontoSetting;
  companyTimeZone: string;
  paymentFrequencies: Array<PaymentFrequencies>;
  totalCompanyComments: number;
  emails: string;

  constructor(attrs: ICompanyDetailResponse) {
    if (attrs.company.address)
      this.address = new Address(attrs.company.address);
    this.adminPhone = attrs.company.admin_phone;
    this.clabe = attrs.company.clabe;
    if (attrs.company.commission)
      this.commission = new Commission(attrs.company.commission);
    this.companyComments = this.setCompanyComment(
      attrs.company.company_comments
    );
    this.dailyInterestRate = attrs.company.daily_interest_rate;
    this.employees = attrs.company.employees_count || 0;
    this.employeesInfo = attrs.company.employee_infos_count || 0;
    this.formattedTimeZone = attrs.company.formatted_time_zone;
    this.id = attrs.company.id;
    this.industryLevelName = attrs.company.industry_level;
    this.industryLevel = attrs.company.industry_level_id;
    this.industrySubLevelName = attrs.company.industry_sublevel;
    this.industrySubLevel = attrs.company.industry_sublevel_id;
    this.isActive = attrs.company.is_active;
    this.legalName = attrs.company.legal_name;
    this.logo = attrs.company.logo;
    this.municipalityName = attrs.company.municipality;
    this.municipality = attrs.company.municipality_id;
    this.name = attrs.company.name;
    this.payrollManagement = attrs.company.payroll_management;
    this.registrationStatus = attrs.company.registration_status;
    this.representatives = this.setRepresentatives(
      attrs.company.representatives
    );
    this.requestedMontos = attrs.company.requested_montos || 0;
    this.rfc = attrs.company.rfc;
    this.selectedFrequencies = attrs.company.selected_frequencies;
    this.stateName = attrs.company.state;
    this.state = attrs.company.state_id;
    if (attrs.company.company_monto_setting) {
      this.montoSettings = new CompanyMontoSetting(
        attrs.company.company_monto_setting
      );
    } else {
      this.montoSettings = null;
    }
    this.companyTimeZone = attrs.company.company_time_zone;
    this.paymentFrequencies = this.setpaymentFrequencies(
      attrs.company.payment_frequencies
    );
    this.totalCompanyComments = attrs.company.total_company_comments;
    this.emails = attrs.company.emails;
  }

  private setpaymentFrequencies(
    paymentFrequencies: Array<IPaymentFrequenciesResponse>
  ) {
    const payment: Array<PaymentFrequencies> = [];

    if (!paymentFrequencies) {
      return payment;
    }

    paymentFrequencies.forEach((response: IPaymentFrequenciesResponse) => {
      const presponse = new PaymentFrequencies(response);
      payment.push(presponse);
    });

    return payment;
  }

  private setCompanyComment(companyComment: Array<ICompanyCommentResponse>) {
    const comments: Array<CompanyComments> = [];

    if (!companyComment) {
      return comments;
    }

    companyComment.forEach((response: ICompanyCommentResponse) => {
      const presponse = new CompanyComments(response);
      comments.push(presponse);
    });

    return comments;
  }

  private setRepresentatives(representatives: Array<IRepresentativeResponse>) {
    const rep: Array<Representative> = [];

    if (!representatives) {
      return rep;
    }

    representatives.forEach((response: IRepresentativeResponse) => {
      const presponse = new Representative(response);
      rep.push(presponse);
    });

    return rep;
  }
}
