import { IInfoCollaboratorDetailResponse } from "@declarations/info-collaborator-detail-response";

export class InfoCollaboratorDetail {
  id: string;
  name: string;
  lastName: string;
  fullName: string;
  rfc: string;
  birthdate: string;
  position: string;
  netSalary: string;
  hiringDate: string;
  cellphone: string;
  clabe: number;
  personalEmail: string;
  collaboratorId: string;
  cardNumber: number;
  gender: string;
  groupId: Record<string, any>;
  bankId: Record<string, any>;
  employeeStatus: any;

  constructor(attrs: IInfoCollaboratorDetailResponse) {
    this.id = attrs.employee_info.id;
    this.name = attrs.employee_info.name;
    this.lastName = attrs.employee_info.last_name;
    this.fullName = attrs.employee_info.full_name;
    this.birthdate = attrs.employee_info.birthdate;
    this.position = attrs.employee_info.position;
    this.rfc = attrs.employee_info.rfc;
    this.netSalary = attrs.employee_info.net_salary;
    this.hiringDate = attrs.employee_info.hiring_date;
    this.cellphone = attrs.employee_info.cellphone;
    this.clabe = attrs.employee_info.clabe;
    this.personalEmail = attrs.employee_info.personal_email;
    this.collaboratorId = attrs.employee_info.collaborator_id;
    this.cardNumber = attrs.employee_info.card_number;
    this.gender = attrs.employee_info.gender;
    this.groupId = attrs.employee_info.group_id;
    this.bankId = attrs.employee_info.bank_id;
    this.employeeStatus = attrs.employee_info;
  }
}
