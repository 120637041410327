<ng-template #viewDeductionsCharges let-modal>
  <div id="section__view-deductions-charges-modal">
    <div class="modal-header">
      <div class="dismiss">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss(false)"
        >
          <img src="assets/img/application/icons/ic_close_black.svg" />
        </button>
      </div>
      <div class="title">
        <h4 class="h4-extend-small">
          {{ invoiceDetail.company }}:
          {{ invoiceDetail.startDate | date: "d" }}
          de
          {{ invoiceDetail.startDate | date: "LLLL" }} -
          {{ invoiceDetail.endDate | date: "d" }}
          de
          {{ invoiceDetail.endDate | date: "LLLL yyyy" }}
        </h4>
        <div class="status">
          <div
            *ngIf="invoiceDetail.paymentStatus == 'unpaid'"
            class="oval--fail"
          ></div>
          <p>No pagado</p>
        </div>
      </div>
    </div>
    <div class="modal-content">
      <div class="charge-data">
        <div class="margin-small--bottom">
          <h4 class="h4-extend-small">Datos de cobranza</h4>
        </div>
        <div class="charge-data-content">
          <div>
            <div class="margin-small--bottom">
              <p class="soft">Empresa</p>
              <p>{{ invoiceDetail.company }}</p>
            </div>
            <div class="margin-small--bottom">
              <p class="soft">Nombre del periodo</p>
              <p>{{ invoiceDetail.payrollPeriod }}</p>
            </div>
            <div class="margin-small--bottom">
              <p class="soft">Inicio y fin</p>
              <p>
                ({{ invoiceDetail.startDate | date: "d" }} -
                {{ invoiceDetail.endDate | date: "d" }}
                de
                {{ invoiceDetail.endDate | date: "LLLL, yyyy" }})
              </p>
            </div>
          </div>
          <div>
            <div class="margin-small--bottom">
              <p class="soft">Total a pagar</p>
              <p>
                {{
                  invoiceDetail.totalToPayTotalAmount
                    | currency: "MXN":"symbol-narrow"
                }}
              </p>
            </div>
            <div class="margin-small--bottom">
              <p class="soft">Pagado</p>
              <p>
                {{ invoiceDetail.amountPaid | currency: "MXN":"symbol-narrow" }}
              </p>
            </div>
            <div class="margin-small--bottom">
              <p class="soft">Folio</p>
              <p>{{ invoiceDetail.folio }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="register-payment">
        <div class="margin-small--bottom">
          <h4 class="h4-extend-small margin-small--bottom">Registrar pago</h4>
        </div>
        <form [formGroup]="paymentForm">
          <div class="margin-small--bottom">
            <app-input
              type="currency"
              id="amount"
              [hasDecimal]="true"
              [customClass]="'input-card'"
              [parentForm]="paymentForm"
              [labelName]="'Cantidad recibida'"
              [placeholder]="'Ingresa la cantidad retirada'"
              (onKeyUpChange)="validateAmount($event)"
            >
            </app-input>
          </div>
          <div class="margin-small--bottom">
            <div class="amount-sumary">
              <p
                placement="top"
                tooltipClass="primary-tooltip"
                [ngbTooltip]="totalToPayDetail"
                [openDelay]="300"
                [closeDelay]="50000"
                class="soft"
              >
                Total a pagar
              </p>
              <p>
                {{
                  invoiceDetail.totalToPayTotalAmount
                    | currency: "MXN":"symbol-narrow"
                }}
              </p>
              <ng-template #totalToPayDetail>
                <p>
                  Total retirado:
                  {{
                    invoiceDetail.totalAmount | currency: "MXN":"symbol-narrow"
                  }}
                </p>
                <p>
                  Intereses:
                  {{
                    invoiceDetail.generatedInterest
                      | currency: "MXN":"symbol-narrow"
                  }}
                </p>
              </ng-template>
            </div>
            <div class="amount-sumary">
              <p class="soft">Intereses (+IVA)</p>
              <p>
                {{
                  invoiceDetail.interestWithTax
                    | currency: "MXN":"symbol-narrow"
                }}
              </p>
            </div>
            <div class="amount-sumary">
              <p class="soft">Pagado</p>
              <p>
                {{ invoiceDetail.amountPaid | currency: "MXN":"symbol-narrow" }}
              </p>
            </div>
            <div class="amount-sumary">
              <p class="soft">Adeudo</p>
              <p>
                {{
                  invoiceDetail.totalToPayTotalAmount -
                    paymentForm.get("amount").value
                    | currency: "MXN":"symbol-narrow"
                }}
              </p>
            </div>
            <div class="amount-sumary">
              <p class="soft">Status</p>
              <p
                *ngIf="
                  invoiceDetail.paymentStatus == 'unpaid' &&
                  invoiceDetail.totalToPayTotalAmount -
                    paymentForm.get('amount').value !=
                    0
                "
              >
                Adeudo
              </p>
              <p
                *ngIf="
                  invoiceDetail.paymentStatus == 'paid' ||
                  invoiceDetail.totalToPayTotalAmount -
                    paymentForm.get('amount').value ==
                    0
                "
              >
                Pagado
              </p>
            </div>
          </div>
          <button
            (click)="modal.close(true)"
            type="button"
            class="button--primary"
            [disabled]="isLoading"
          >
            Guardar cambios
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
