import { IPaginationResponse } from "@declarations/pagination-response";

export class Pagination {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalCount: number;
  totalPages: number;
  isLoading: boolean;

  constructor(attrPagination?: IPaginationResponse) {
    this.currentPage = attrPagination.pagination.current_page || 1;
    this.nextPage = attrPagination.pagination.next_page || null;
    this.prevPage = attrPagination.pagination.prev_page || null;
    this.totalCount = attrPagination.pagination.total_count || 0;
    this.totalPages = attrPagination.pagination.total_pages || 1;
    this.isLoading = false;
  }
}
