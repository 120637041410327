import { ICompanyCommentResponse } from "@declarations/company-comments-response";

export class CompanyComments {
  content: string;
  createdAt: string;
  id: string;

  constructor(attrs: ICompanyCommentResponse) {
    this.content = attrs.content;
    this.createdAt = attrs.created_at;
    this.id = attrs.id;
  }
}
