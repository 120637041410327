<ng-template #viewLogsModal>
  <div
    infiniteScroll
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="30"
    [infiniteScrollContainer]="selector"
    [fromRoot]="true"
    (scrolled)="onScroll()"
    id="section__view-logs-modal"
  >
    <div class="modal-header">
      <div class="dismiss">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closelModal()"
        >
          <img src="assets/img/application/icons/ic_close_black.svg" />
        </button>
      </div>
      <div class="title">
        <h4 class="h4-extend-small">Historial de actividad</h4>
      </div>
    </div>
    <div class="modal-content log-scroll">
      <div *ngIf="vigentLogs.length" class="current-log">
        <h4 class="strong">
          Hoy
        </h4>
        <div *ngFor="let log of vigentLogs" class="current-log__single-log">
          <p class="soft">{{ log.time }}</p>
          <ngb-accordion
            [destroyOnHide]="false"
            [closeOthers]="true"
            activeIds="static-1"
            #shadow
          >
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <span>
                  <div class="current-log-title">
                    <div class="oval">
                      <img
                        *ngIf="
                          log.eventType == 'file_upload' ||
                          log.eventType == 'employee_created' ||
                          log.eventType == 'employee_updated' ||
                          log.eventType == 'employee_status_changed' ||
                          log.eventType == 'employee_invited'
                        "
                        src="assets/img/application/icons/ic_people-outline_white.svg"
                      />
                      <img
                        *ngIf="log.eventType == 'company_update'"
                        src="assets/img/application/icons/ic_briefcase-outline_white.svg"
                      />
                      <img
                        *ngIf="
                          log.eventType == 'group_updated' ||
                          log.eventType == 'group_created' ||
                          log.eventType == 'group_status_changed'
                        "
                        src="assets/img/application/icons/ic_folder-outline_white.svg"
                      />
                      <img
                        *ngIf="
                          log.eventType == 'payroll_period_creation' ||
                          log.eventType == 'payroll_period_cycle_creation' ||
                          log.eventType == 'payroll_period_update' ||
                          log.eventType == 'payroll_period_cycle_update'
                        "
                        src="assets/img/application/icons/ic_calendar-outline_white.svg"
                      />
                    </div>
                    <div class="description">
                      <h5 class="strong main">
                        {{ log.userFullName }}
                      </h5>
                      &nbsp;
                      <!-- ACTION -->
                      <h5 *ngIf="log.eventType == 'employee_updated'">
                        actualizó al colaborador
                      </h5>
                      <h5 *ngIf="log.eventType == 'employee_created'">
                        añadió a
                      </h5>
                      <h5 *ngIf="log.eventType == 'employee_status_changed'">
                        actualizó el status del colaborador
                      </h5>
                      <h5 *ngIf="log.eventType == 'employee_invited'">
                        invitó al colaborador
                      </h5>
                      <h5 *ngIf="log.eventType == 'file_upload'">
                        subió un archivo para importar colaboradores
                      </h5>
                      <h5 *ngIf="log.eventType == 'company_update'">
                        actualizó la información de la empresa
                      </h5>
                      <h5 *ngIf="log.eventType == 'group_updated'">
                        actualizó el grupo
                      </h5>
                      <h5 *ngIf="log.eventType == 'group_created'">
                        creó al grupo
                      </h5>
                      <h5 *ngIf="log.eventType == 'group_status_changed'">
                        actualizó el status del grupo
                      </h5>
                      <h5 *ngIf="log.eventType == 'payroll_period_creation'">
                        creó el nuevo periodo de pago
                      </h5>
                      <h5
                        *ngIf="log.eventType == 'payroll_period_cycle_creation'"
                      >
                        creó un nuevo ciclo en un periodo de pago
                      </h5>
                      <h5 *ngIf="log.eventType == 'payroll_period_update'">
                        actualizó el periodo de pago
                      </h5>
                      <h5
                        *ngIf="log.eventType == 'payroll_period_cycle_update'"
                      >
                        actualizó un ciclo en el periodo de pago
                      </h5>
                      &nbsp;
                      <!-- NAME -->
                      <h5
                        *ngIf="
                          log.eventType == 'employee_created' ||
                          log.eventType == 'employee_updated' ||
                          log.eventType == 'employee_status_changed' ||
                          log.eventType == 'employee_invited'
                        "
                        class="strong secondary"
                      >
                        {{ log.eventData.newData.fullName }}
                      </h5>
                      <h5
                        *ngIf="
                          log.eventType == 'company_update' ||
                          log.eventType == 'group_updated' ||
                          log.eventType == 'group_created' ||
                          log.eventType == 'group_status_changed' ||
                          log.eventType == 'payroll_period_creation' ||
                          log.eventType == 'payroll_period_update'
                        "
                        class="strong secondary"
                      >
                        {{ log.eventData.newData.name }}
                      </h5>
                      <!-- PLUS -->
                      <h5 *ngIf="log.eventType == 'employee_created'">
                        &nbsp; como colaborador
                      </h5>
                      <h5
                        *ngIf="
                          log.eventType == 'payroll_period_cycle_update' &&
                          log.eventData.newData.payrollPeriod
                        "
                      >
                        {{ log.eventData.newData.payrollPeriod.name }}
                      </h5>
                    </div>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                <!-- COLABORATORS MODULE-->
                <div
                  *ngIf="log.eventType == 'employee_updated'"
                  class="card-changes__collaborator"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.oldData.fullName }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Correo electrónico
                      </p>
                      <p>{{ log.eventData.oldData.email }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Celular
                      </p>
                      <p>{{ log.eventData.oldData.cellphone }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Grupo
                      </p>
                      <p>{{ log.eventData.oldData.group }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.oldData.netSalary }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.oldData.netSalary }}</p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.fullName }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Correo electrónico
                      </p>
                      <p>{{ log.eventData.newData.email }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Celular
                      </p>
                      <p>{{ log.eventData.newData.cellphone }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Grupo
                      </p>
                      <p>{{ log.eventData.newData.group }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    log.eventType == 'employee_created' ||
                    log.eventType == 'employee_invited'
                  "
                  class="card-changes__collaborator"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.fullName }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Correo electrónico
                      </p>
                      <p>{{ log.eventData.newData.email }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Celular
                      </p>
                      <p>{{ log.eventData.newData.cellphone }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Grupo
                      </p>
                      <p>{{ log.eventData.newData.group }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'employee_status_changed'"
                  class="card-changes__collaborator"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.activeStatus == 'active'">
                        Activo
                      </p>
                      <p
                        *ngIf="log.eventData.oldData.activeStatus == 'inactive'"
                      >
                        Baja
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.activeStatus == 'suspended'
                        "
                      >
                        Inactivo
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.activeStatus == 'active'">
                        Activo
                      </p>
                      <p
                        *ngIf="log.eventData.newData.activeStatus == 'inactive'"
                      >
                        Baja
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.activeStatus == 'suspended'
                        "
                      >
                        Inactivo
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'file_upload'"
                  class="card-changes__file"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p
                        (click)="openFile(log.eventData.newData.file)"
                        class="soft click"
                      >
                        Click aqui para ver archivo
                      </p>
                    </div>
                  </div>
                </div>
                <!-- COMPANY MODULE -->
                <div
                  *ngIf="log.eventType == 'company_update'"
                  class="card-changes__company"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.oldData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus de registro
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.registrationStatus == 'active'
                        "
                      >
                        Activo
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.registrationStatus ==
                          'waiting_for_approval'
                        "
                      >
                        Esperando aprobación
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.registrationStatus ==
                          'in_review'
                        "
                      >
                        En revisón
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Logo
                      </p>
                      <p>{{ log.eventData.oldData.nameLogoFile }}</p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus de registro
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.registrationStatus == 'active'
                        "
                      >
                        Activo
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.registrationStatus ==
                          'waiting_for_approval'
                        "
                      >
                        Esperando aprobación
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.registrationStatus ==
                          'in_review'
                        "
                      >
                        En revisón
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Logo
                      </p>
                      <p
                        (click)="openFile(log.eventData.newData.logo)"
                        class="click"
                      >
                        {{ log.eventData.newData.nameLogoFile }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- GROUPS MODULE-->
                <div
                  *ngIf="log.eventType == 'group_updated'"
                  class="card-changes__groups"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.oldData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Periodo de pago
                      </p>
                      <p>{{ log.eventData.oldData.payrollPeriod }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>{{ log.eventData.oldData.paymentFrequency }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Porcentaje de salario disponible para retirar
                      </p>
                      <p>
                        {{
                          log.eventData.oldData.percentageSalaryToWithdraw /
                            100 | percent
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Colaboradores
                      </p>
                      <p>{{ log.eventData.oldData.collaboratorsCount }}</p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Periodo de pago
                      </p>
                      <p>{{ log.eventData.newData.payrollPeriod }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>{{ log.eventData.newData.paymentFrequency }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Porcentaje de salario disponible para retirar
                      </p>
                      <p>
                        {{
                          log.eventData.newData.percentageSalaryToWithdraw /
                            100 | percent
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Colaboradores
                      </p>
                      <p>{{ log.eventData.newData.collaboratorsCount }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'group_created'"
                  class="card-changes__groups"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Periodo de pago
                      </p>
                      <p>{{ log.eventData.newData.payrollPeriod }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>{{ log.eventData.newData.paymentFrequency }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Porcentaje de salario disponible para retirar
                      </p>
                      <p>
                        {{
                          log.eventData.newData.percentageSalaryToWithdraw /
                            100 | percent
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Colaboradores
                      </p>
                      <p>{{ log.eventData.newData.collaboratorsCount }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'group_status_changed'"
                  class="card-changes__groups"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.isActive">
                        Inactivo
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                  </div>
                </div>
                <!-- PAYROLL PERIODS MODULE -->
                <div
                  *ngIf="log.eventType == 'payroll_period_creation'"
                  class="card-changes__payroll-periods"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>
                        {{ log.eventData.newData.name }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.active">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.active">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentScheme | titlecase }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'payroll_period_update'"
                  class="card-changes__payroll-periods"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>
                        {{ log.eventData.oldData.name }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.active">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.active">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>
                        {{ log.eventData.oldData.paymentScheme | titlecase }}
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>
                        {{ log.eventData.newData.name }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.active">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.active">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentScheme | titlecase }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- CYCLES OF PAYROLL PERIOD MODULE -->
                <div
                  *ngIf="log.eventType == 'payroll_period_cycle_creation'"
                  class="card-changes__payroll-periods-cycles"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de inicio
                      </p>
                      <p>
                        {{ log.eventData.newData.startDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.startDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de fin
                      </p>
                      <p>
                        {{ log.eventData.newData.endDate | date: "d" }}
                        de
                        {{ log.eventData.newData.endDate | date: "LLLL, yyyy" }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de corte
                      </p>
                      <p>
                        {{ log.eventData.newData.cutoffDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.cutoffDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.paymentDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de reconciliación
                      </p>
                      <p>
                        {{
                          log.eventData.newData.reconciliationDate | date: "d"
                        }}
                        de
                        {{
                          log.eventData.newData.reconciliationDate
                            | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'payroll_period_cycle_update'"
                  class="card-changes__payroll-periods-cycles"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de inicio
                      </p>
                      <p>
                        {{ log.eventData.oldData.startDate | date: "d" }}
                        de
                        {{
                          log.eventData.oldData.startDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de fin
                      </p>
                      <p>
                        {{ log.eventData.oldData.endDate | date: "d" }}
                        de
                        {{ log.eventData.oldData.endDate | date: "LLLL, yyyy" }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de corte
                      </p>
                      <p>
                        {{ log.eventData.oldData.cutoffDate | date: "d" }}
                        de
                        {{
                          log.eventData.oldData.cutoffDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de pago
                      </p>
                      <p>
                        {{ log.eventData.oldData.paymentDate | date: "d" }}
                        de
                        {{
                          log.eventData.oldData.paymentDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de reconciliación
                      </p>
                      <p>
                        {{
                          log.eventData.oldData.reconciliationDate | date: "d"
                        }}
                        de
                        {{
                          log.eventData.oldData.reconciliationDate
                            | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de inicio
                      </p>
                      <p>
                        {{ log.eventData.newData.startDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.startDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de fin
                      </p>
                      <p>
                        {{ log.eventData.newData.endDate | date: "d" }}
                        de
                        {{ log.eventData.newData.endDate | date: "LLLL, yyyy" }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de corte
                      </p>
                      <p>
                        {{ log.eventData.newData.cutoffDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.cutoffDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.paymentDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de reconciliación
                      </p>
                      <p>
                        {{
                          log.eventData.newData.reconciliationDate | date: "d"
                        }}
                        de
                        {{
                          log.eventData.newData.reconciliationDate
                            | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <div *ngFor="let logDay of pastLogsPerDays" class="past-log">
        <h4 class="strong">
          {{ logDay.day | date: "d" }}
          de
          {{ logDay.day | date: "LLLL, yyyy" }}
        </h4>
        <div *ngFor="let log of logDay.logs" class="past-log__single-log">
          <p class="soft">{{ log.time }}</p>
          <ngb-accordion
            [destroyOnHide]="false"
            [closeOthers]="true"
            activeIds="static-1"
            #shadow
          >
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <span>
                  <div class="past-log-title">
                    <div class="oval">
                      <img
                        *ngIf="
                          log.eventType == 'file_upload' ||
                          log.eventType == 'employee_created' ||
                          log.eventType == 'employee_updated' ||
                          log.eventType == 'employee_status_changed' ||
                          log.eventType == 'employee_invited'
                        "
                        src="assets/img/application/icons/ic_people-outline_white.svg"
                      />
                      <img
                        *ngIf="log.eventType == 'company_update'"
                        src="assets/img/application/icons/ic_briefcase-outline_white.svg"
                      />
                      <img
                        *ngIf="
                          log.eventType == 'group_updated' ||
                          log.eventType == 'group_created' ||
                          log.eventType == 'group_status_changed'
                        "
                        src="assets/img/application/icons/ic_folder-outline_white.svg"
                      />
                      <img
                        *ngIf="
                          log.eventType == 'payroll_period_creation' ||
                          log.eventType == 'payroll_period_cycle_creation' ||
                          log.eventType == 'payroll_period_update' ||
                          log.eventType == 'payroll_period_cycle_update'
                        "
                        src="assets/img/application/icons/ic_calendar-outline_white.svg"
                      />
                    </div>
                    <div class="description">
                      <h5 class="strong main">
                        {{ log.userFullName }}
                      </h5>
                      &nbsp;
                      <!-- ACTION -->
                      <h5 *ngIf="log.eventType == 'employee_updated'">
                        actualizó al colaborador
                      </h5>
                      <h5 *ngIf="log.eventType == 'employee_created'">
                        añadió a
                      </h5>
                      <h5 *ngIf="log.eventType == 'employee_status_changed'">
                        actualizó el status del colaborador
                      </h5>
                      <h5 *ngIf="log.eventType == 'employee_invited'">
                        invitó al colaborador
                      </h5>
                      <h5 *ngIf="log.eventType == 'file_upload'">
                        subió un archivo para importar colaboradores
                      </h5>
                      <h5 *ngIf="log.eventType == 'company_update'">
                        actualizó la información de la empresa
                      </h5>
                      <h5 *ngIf="log.eventType == 'group_updated'">
                        actualizó el grupo
                      </h5>
                      <h5 *ngIf="log.eventType == 'group_created'">
                        creó al grupo
                      </h5>
                      <h5 *ngIf="log.eventType == 'group_status_changed'">
                        actualizó el status del grupo
                      </h5>
                      <h5 *ngIf="log.eventType == 'payroll_period_creation'">
                        creó el nuevo periodo de pago
                      </h5>
                      <h5
                        *ngIf="log.eventType == 'payroll_period_cycle_creation'"
                      >
                        creó un nuevo ciclo en un periodo de pago
                      </h5>
                      <h5 *ngIf="log.eventType == 'payroll_period_update'">
                        actualizó el periodo de pago
                      </h5>
                      <h5
                        *ngIf="log.eventType == 'payroll_period_cycle_update'"
                      >
                        actualizó un ciclo en el periodo de pago
                      </h5>
                      &nbsp;
                      <!-- NAME -->
                      <h5
                        *ngIf="
                          log.eventType == 'employee_created' ||
                          log.eventType == 'employee_updated' ||
                          log.eventType == 'employee_status_changed' ||
                          log.eventType == 'employee_invited'
                        "
                        class="strong secondary"
                      >
                        {{ log.eventData.newData.fullName }}
                      </h5>
                      <h5
                        *ngIf="
                          log.eventType == 'company_update' ||
                          log.eventType == 'group_updated' ||
                          log.eventType == 'group_created' ||
                          log.eventType == 'group_status_changed' ||
                          log.eventType == 'payroll_period_creation' ||
                          log.eventType == 'payroll_period_update'
                        "
                        class="strong secondary"
                      >
                        {{ log.eventData.newData.name }}
                      </h5>
                      <!-- PLUS -->
                      <h5 *ngIf="log.eventType == 'employee_created'">
                        &nbsp; como colaborador
                      </h5>
                      <h5
                        *ngIf="
                          log.eventType == 'payroll_period_cycle_update' &&
                          log.eventData.newData.payrollPeriod
                        "
                      >
                        {{ log.eventData.newData.payrollPeriod.name }}
                      </h5>
                    </div>
                  </div>
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                <!-- COLABORATORS MODULE-->
                <div
                  *ngIf="log.eventType == 'employee_updated'"
                  class="card-changes__collaborator"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.oldData.fullName }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Correo electrónico
                      </p>
                      <p>{{ log.eventData.oldData.email }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Celular
                      </p>
                      <p>{{ log.eventData.oldData.cellphone }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Grupo
                      </p>
                      <p>{{ log.eventData.oldData.group }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.oldData.netSalary }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.oldData.netSalary }}</p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.fullName }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Correo electrónico
                      </p>
                      <p>{{ log.eventData.newData.email }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Celular
                      </p>
                      <p>{{ log.eventData.newData.cellphone }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Grupo
                      </p>
                      <p>{{ log.eventData.newData.group }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    log.eventType == 'employee_created' ||
                    log.eventType == 'employee_invited'
                  "
                  class="card-changes__collaborator"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.fullName }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Correo electrónico
                      </p>
                      <p>{{ log.eventData.newData.email }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Celular
                      </p>
                      <p>{{ log.eventData.newData.cellphone }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Grupo
                      </p>
                      <p>{{ log.eventData.newData.group }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Salario neto
                      </p>
                      <p>{{ log.eventData.newData.netSalary }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'employee_status_changed'"
                  class="card-changes__collaborator"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.activeStatus == 'active'">
                        Activo
                      </p>
                      <p
                        *ngIf="log.eventData.oldData.activeStatus == 'inactive'"
                      >
                        Baja
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.activeStatus == 'suspended'
                        "
                      >
                        Inactivo
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.activeStatus == 'active'">
                        Activo
                      </p>
                      <p
                        *ngIf="log.eventData.newData.activeStatus == 'inactive'"
                      >
                        Baja
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.activeStatus == 'suspended'
                        "
                      >
                        Inactivo
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'file_upload'"
                  class="card-changes__file"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p
                        (click)="openFile(log.eventData.newData.file)"
                        class="soft click"
                      >
                        Click aqui para ver archivo
                      </p>
                    </div>
                  </div>
                </div>
                <!-- COMPANY MODULE -->
                <div
                  *ngIf="log.eventType == 'company_update'"
                  class="card-changes__company"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.oldData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus de registro
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.registrationStatus == 'active'
                        "
                      >
                        Activo
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.registrationStatus ==
                          'waiting_for_approval'
                        "
                      >
                        Esperando aprobación
                      </p>
                      <p
                        *ngIf="
                          log.eventData.oldData.registrationStatus ==
                          'in_review'
                        "
                      >
                        En revisón
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Logo
                      </p>
                      <p>{{ log.eventData.oldData.nameLogoFile }}</p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus de registro
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.registrationStatus == 'active'
                        "
                      >
                        Activo
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.registrationStatus ==
                          'waiting_for_approval'
                        "
                      >
                        Esperando aprobación
                      </p>
                      <p
                        *ngIf="
                          log.eventData.newData.registrationStatus ==
                          'in_review'
                        "
                      >
                        En revisón
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Logo
                      </p>
                      <p
                        (click)="openFile(log.eventData.newData.logo)"
                        class="click"
                      >
                        {{ log.eventData.newData.nameLogoFile }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- GROUPS MODULE-->
                <div
                  *ngIf="log.eventType == 'group_updated'"
                  class="card-changes__groups"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.oldData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Periodo de pago
                      </p>
                      <p>{{ log.eventData.oldData.payrollPeriod }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>{{ log.eventData.oldData.paymentFrequency }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Porcentaje de salario disponible para retirar
                      </p>
                      <p>
                        {{
                          log.eventData.oldData.percentageSalaryToWithdraw /
                            100 | percent
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Colaboradores
                      </p>
                      <p>{{ log.eventData.oldData.collaboratorsCount }}</p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Periodo de pago
                      </p>
                      <p>{{ log.eventData.newData.payrollPeriod }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>{{ log.eventData.newData.paymentFrequency }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Porcentaje de salario disponible para retirar
                      </p>
                      <p>
                        {{
                          log.eventData.newData.percentageSalaryToWithdraw /
                            100 | percent
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Colaboradores
                      </p>
                      <p>{{ log.eventData.newData.collaboratorsCount }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'group_created'"
                  class="card-changes__groups"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>{{ log.eventData.newData.name }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Periodo de pago
                      </p>
                      <p>{{ log.eventData.newData.payrollPeriod }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>{{ log.eventData.newData.paymentFrequency }}</p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Porcentaje de salario disponible para retirar
                      </p>
                      <p>
                        {{
                          log.eventData.newData.percentageSalaryToWithdraw /
                            100 | percent
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Colaboradores
                      </p>
                      <p>{{ log.eventData.newData.collaboratorsCount }}</p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'group_status_changed'"
                  class="card-changes__groups"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.isActive">
                        Inactivo
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.isActive">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.isActive">
                        Inactivo
                      </p>
                    </div>
                  </div>
                </div>
                <!-- PAYROLL PERIODS MODULE -->
                <div
                  *ngIf="log.eventType == 'payroll_period_creation'"
                  class="card-changes__payroll-periods"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>
                        {{ log.eventData.newData.name }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.active">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.active">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentScheme | titlecase }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'payroll_period_update'"
                  class="card-changes__payroll-periods"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>
                        {{ log.eventData.oldData.name }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.oldData.active">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.oldData.active">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>
                        {{ log.eventData.oldData.paymentScheme | titlecase }}
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Nombre
                      </p>
                      <p>
                        {{ log.eventData.newData.name }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Estatus
                      </p>
                      <p *ngIf="log.eventData.newData.active">
                        Activo
                      </p>
                      <p *ngIf="!log.eventData.newData.active">
                        Inactivo
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Frecuencia de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentScheme | titlecase }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- CYCLES OF PAYROLL PERIOD MODULE -->
                <div
                  *ngIf="log.eventType == 'payroll_period_cycle_creation'"
                  class="card-changes__payroll-periods-cycles"
                >
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de inicio
                      </p>
                      <p>
                        {{ log.eventData.newData.startDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.startDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de fin
                      </p>
                      <p>
                        {{ log.eventData.newData.endDate | date: "d" }}
                        de
                        {{ log.eventData.newData.endDate | date: "LLLL, yyyy" }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de corte
                      </p>
                      <p>
                        {{ log.eventData.newData.cutoffDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.cutoffDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.paymentDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de reconciliación
                      </p>
                      <p>
                        {{
                          log.eventData.newData.reconciliationDate | date: "d"
                        }}
                        de
                        {{
                          log.eventData.newData.reconciliationDate
                            | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="log.eventType == 'payroll_period_cycle_update'"
                  class="card-changes__payroll-periods-cycles"
                >
                  <div class="card_history">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de inicio
                      </p>
                      <p>
                        {{ log.eventData.oldData.startDate | date: "d" }}
                        de
                        {{
                          log.eventData.oldData.startDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de fin
                      </p>
                      <p>
                        {{ log.eventData.oldData.endDate | date: "d" }}
                        de
                        {{ log.eventData.oldData.endDate | date: "LLLL, yyyy" }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de corte
                      </p>
                      <p>
                        {{ log.eventData.oldData.cutoffDate | date: "d" }}
                        de
                        {{
                          log.eventData.oldData.cutoffDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de pago
                      </p>
                      <p>
                        {{ log.eventData.oldData.paymentDate | date: "d" }}
                        de
                        {{
                          log.eventData.oldData.paymentDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de reconciliación
                      </p>
                      <p>
                        {{
                          log.eventData.oldData.reconciliationDate | date: "d"
                        }}
                        de
                        {{
                          log.eventData.oldData.reconciliationDate
                            | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/img/application/icons/ic_arrow-right_blue-primary.svg"
                    />
                  </div>
                  <div class="card_changes">
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de inicio
                      </p>
                      <p>
                        {{ log.eventData.newData.startDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.startDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de fin
                      </p>
                      <p>
                        {{ log.eventData.newData.endDate | date: "d" }}
                        de
                        {{ log.eventData.newData.endDate | date: "LLLL, yyyy" }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de corte
                      </p>
                      <p>
                        {{ log.eventData.newData.cutoffDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.cutoffDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de pago
                      </p>
                      <p>
                        {{ log.eventData.newData.paymentDate | date: "d" }}
                        de
                        {{
                          log.eventData.newData.paymentDate | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                    <div class="margin-base--bottom">
                      <p class="soft">
                        Fecha de reconciliación
                      </p>
                      <p>
                        {{
                          log.eventData.newData.reconciliationDate | date: "d"
                        }}
                        de
                        {{
                          log.eventData.newData.reconciliationDate
                            | date: "LLLL, yyyy"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>
</ng-template>
