import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-cycle-out-range",
  templateUrl: "./cycle-out-range.component.html",
})
export class CycleOutRangeComponent implements OnInit {
  @ViewChild("viewCycleOutRange")
  viewCycleOutRange;

  shouldHideCloseButton = false;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  async openViewCycleOutRangeModal(options?): Promise<boolean> {
    let option: boolean;
    await this.modalService
      .open(this.viewCycleOutRange, {
        ...options,
        ...{
          keyboard: false,
          ariaLabelledBy: "ModalViewCycleOutRange",
          centered: true,
        },
      })
      .result.then(
        (result) => {
          return (option = result);
        },
        (reason) => {
          return (option = reason);
        }
      );

    return option;
  }

  closeCycleOutRangeModal() {
    this.modalService.dismissAll();
  }
}
