import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { Company } from "@models/company";
import { ICompaniesResponse } from "@declarations/companies-response";
import { ICompanyResponse } from "@declarations/company-response";
import { map } from "rxjs/operators";
import { IIndustryLevelsResponse } from "@declarations/industry-levels-response";
import { IIndustryLevelResponse } from "@declarations/industry-level-response";
import { IndustryLevel } from "@models/industry-level";
import { State } from "@models/state";
import { IStateResponse } from "@declarations/state-response";
import { IStatesResponse } from "@declarations/states-response";
import { IIndustrySubLevelsResponse } from "@declarations/industry-sub-levels-response";
import { IIndustrySubLevelResponse } from "@declarations/industry-sub-level-response";
import { IndustrySubLevel } from "@models/industry-sub-level";
import { ICitiesResponse } from "@declarations/cities-response";
import { ICityResponse } from "@declarations/city-response";
import { City } from "@models/city";
import { ICompaniesProspectsResponse } from "@declarations/companies-prospects-response";
import { Pagination } from "@models/pagination";
import { TimeZone } from "@models/time-zone";
import { ICompanyDetailResponse } from "@declarations/company-detail-response";
import { CompanyDetail } from "@models/company-detail";
import { ITableCollectionResponse } from "@declarations/table-collection-response";
import { IGetCompaniesFilterParam } from "@shared/parameters/get-companies-filter.param";
import { FiltersMaxLimits } from "@models/filters-max-limits";
import { IFiltersMaxLimitsResponse } from "@declarations/filters-max-limits-response";
import { ICollaboratorsResponse } from "@declarations/collaborators-response";
import { ICollaboratorResponse } from "@declarations/collaborator-response";
import { Collaborator } from "@models/collaborator";
import { IGroupsResponse } from "@declarations/groups-response";
import { IGroupResponse } from "@declarations/group-response";
import { Group } from "@models/group";
import { IPayrollPeriodsAdministrationResponse } from "@declarations/payroll-periods-administration-response";
import { IPayrollPeriodResponse } from "@declarations/payroll-period-response";
import { PayrollPeriod } from "@models/payroll-period";
import { IGetCollaboratorsFilterParam } from "@shared/parameters/get-collaborators-filter.param";
import { IGetPayrollPeriodsParam } from "@shared/parameters/get-payroll-periods-filter.param";
import { IGetGroupsFilterParam } from "@shared/parameters/get-groups-filter.param";
import { IInfoCollaboratorsResponse } from "@declarations/info-collaborators-response";

@Injectable()
export class CompaniesService {
  constructor(private httpClient: HttpClient) {
    /** Do nothing */
  }

  /** NOTE: We should be able to confirm company without id because it's
   *        associated to administrator.
   */
  confirmCompanyInformation(companyId: string, payload: any) {
    return this.httpClient.put(
      `${environment.API}/companies/${companyId}`,
      payload
    );
  }

  toggleCompanyStatus(companyId: string, payload: any): Observable<Object> {
    return this.httpClient.put(
      `${environment.API}/companies/${companyId}/toggle_activation`,
      payload
    );
  }

  /** TODO: Move params data type to a shared interface */
  getCompanies(
    params: IGetCompaniesFilterParam,
    page: number
  ): Observable<ITableCollectionResponse> {
    let RequestHttpParams = new HttpParams();

    if (params.registrationStep) {
      RequestHttpParams = RequestHttpParams.set(
        "registration_step",
        params.registrationStep.toString()
      );
    }
    if (params.activeStatus) {
      RequestHttpParams = RequestHttpParams.set(
        "active_status",
        params.activeStatus.toString()
      );
    }
    if (params.employeesCount) {
      RequestHttpParams = RequestHttpParams.set(
        "employees_count",
        params.employeesCount.toString()
      );
    }
    if (params.montosCount) {
      RequestHttpParams = RequestHttpParams.set(
        "montos_count",
        params.montosCount.toString()
      );
    }
    if (params.query) {
      RequestHttpParams = RequestHttpParams.set(
        "query",
        params.query.toString()
      );
    }
    if (params.sort) {
      if (params.sort["column"]) {
        RequestHttpParams = RequestHttpParams.set(
          params.sort["param"].toString(),
          params.sort["column"].toString() +
            "," +
            params.sort["order"].toString()
        );
      } else {
        RequestHttpParams = RequestHttpParams.set(
          params.sort["param"].toString(),
          params.sort["order"].toString()
        );
      }
    }
    const mapResponseToCompanies = (response: ICompaniesResponse) => {
      const transformJSONToCompany = (company: ICompanyResponse) => {
        return new Company(company);
      };
      const transformJSONToPagination = new Pagination(response.meta);

      const resultJSON = {
        items: response.companies.map(transformJSONToCompany),
        pagination: transformJSONToPagination,
      };

      return resultJSON;
    };

    return this.httpClient
      .get(`${environment.API}/companies?page=${page}`, {
        params: RequestHttpParams,
      })
      .pipe(map(mapResponseToCompanies));
  }

  getCompaniesToBeApproved(): Observable<Array<Company>> {
    const mapResponseToCompanies = (response: ICompaniesResponse) => {
      const transformJSONToCompany = (company: ICompanyResponse) => {
        return new Company(company);
      };

      return response.companies.map(transformJSONToCompany);
    };

    return this.httpClient
      .get(`${environment.API}/waiting_for_approval_companies`)
      .pipe(map(mapResponseToCompanies));
  }

  getCompaniesInProcess(): Observable<Array<Company>> {
    const mapResponseToCompanies = (response: ICompaniesResponse) => {
      const transformJSONToCompany = (company: ICompanyResponse) => {
        return new Company(company);
      };

      return response.companies.map(transformJSONToCompany);
    };

    return this.httpClient
      .get(`${environment.API}/companies_in_process`)
      .pipe(map(mapResponseToCompanies));
  }

  getCompaniesInactive(): Observable<Array<Company>> {
    const mapResponseToCompanies = (response: ICompaniesResponse) => {
      const transformJSONToCompany = (company: ICompanyResponse) => {
        return new Company(company);
      };

      return response.companies.map(transformJSONToCompany);
    };

    return this.httpClient
      .get(`${environment.API}/inactive_companies`)
      .pipe(map(mapResponseToCompanies));
  }

  getCompaniesProspects(): Observable<Array<Company>> {
    const mapResponseToCompanies = (response: ICompaniesProspectsResponse) => {
      const transformJSONToCompany = (company: ICompanyResponse) => {
        return new Company(company);
      };

      return response.prospect_companies.map(transformJSONToCompany);
    };

    return this.httpClient
      .get(`${environment.API}/prospect_companies`)
      .pipe(map(mapResponseToCompanies));
  }

  getCompany(id: string): Observable<CompanyDetail> {
    const mapResponseToCompany = (company: ICompanyDetailResponse) => {
      return new CompanyDetail(company);
    };

    return this.httpClient
      .get(`${environment.API}/companies/${id}`)
      .pipe(map(mapResponseToCompany));
  }

  /** TODO: Add payload interface */
  /** TODO: Add observable interface */
  inviteCompany(payload: any): Observable<any> {
    return this.httpClient.post(`${environment.API}/companies`, payload);
  }

  updateCompany(id: string, payload) {
    return this.httpClient.put(`${environment.API}/companies/${id}`, payload);
  }

  updateReviewCompany(id: string, payload) {
    return this.httpClient.put(
      `${environment.API}/companies/waiting_for_approval/${id}`,
      payload
    );
  }

  sendToReviewCompany(id: string, payload) {
    return this.httpClient.put(
      `${environment.API}/companies/send_to_review/${id}`,
      payload
    );
  }

  approveCompany(id: string, payload) {
    return this.httpClient.put(
      `${environment.API}/approve_company/${id}`,
      payload,
      { responseType: "text" }
    );
  }

  getIndustryLevel(): Observable<Array<IndustryLevel>> {
    const mapResponseToIndustryLevel = (response: IIndustryLevelsResponse) => {
      const transformJSONToIndustryLevel = (
        industryLevel: IIndustryLevelResponse
      ) => {
        return new IndustryLevel(industryLevel);
      };

      return response.industry_levels.map(transformJSONToIndustryLevel);
    };

    return this.httpClient
      .get(`${environment.API}/industry_levels`)
      .pipe(map(mapResponseToIndustryLevel));
  }

  getIndustrySubLevel(id: string): Observable<Array<IndustrySubLevel>> {
    const mapResponseToIndustrySubLevel = (
      response: IIndustrySubLevelsResponse
    ) => {
      const transformJSONToIndustrySubLevel = (
        industrySubLevel: IIndustrySubLevelResponse
      ) => {
        return new IndustrySubLevel(industrySubLevel);
      };

      return response.industry_sublevels.map(transformJSONToIndustrySubLevel);
    };

    return this.httpClient
      .get(`${environment.API}/industry_levels/${id}/industry_sublevels`)
      .pipe(map(mapResponseToIndustrySubLevel));
  }

  getStates(): Observable<Array<State>> {
    const mapResponseToStates = (response: IStatesResponse) => {
      const transformJSONToState = (state: IStateResponse) => {
        return new State(state);
      };
      return response.states.map(transformJSONToState);
    };

    return this.httpClient
      .get(`${environment.API}/states`)
      .pipe(map(mapResponseToStates));
  }

  getCities(id: string): Observable<Array<City>> {
    const mapResponseToCities = (response: ICitiesResponse) => {
      const transformJSONToCities = (city: ICityResponse) => {
        return new City(city);
      };
      return response.municipalities.map(transformJSONToCities);
    };

    return this.httpClient
      .get(`${environment.API}/states/${id}/municipalities`)
      .pipe(map(mapResponseToCities));
  }

  /** BE doesnt send a structure */
  getTimeZone() {
    const mapResponseToTimeZones = (response) => {
      const transformJSONToTimeZone = (timezone) => {
        return new TimeZone(timezone);
      };
      return response.map(transformJSONToTimeZone);
    };

    return this.httpClient
      .get(`${environment.API}/time_zone_options`)
      .pipe(map(mapResponseToTimeZones));
  }

  getFiltersMaxLimits(): Observable<FiltersMaxLimits> {
    const mapResponseToFiltersMaxLimits = (
      maxlimits: IFiltersMaxLimitsResponse
    ) => {
      return new FiltersMaxLimits(maxlimits);
    };

    return this.httpClient
      .get(`${environment.API}/filters_max_limits`)
      .pipe(map(mapResponseToFiltersMaxLimits));
  }

  getAdministrationIndex(
    companyId: string,
    infoOption: string,
    paramsCollaborator: IGetCollaboratorsFilterParam,
    paramsGroups: IGetGroupsFilterParam,
    paramsPeriod: IGetPayrollPeriodsParam,
    page: number
  ): Observable<ITableCollectionResponse> {
    let RequestHttpParams = new HttpParams();

    RequestHttpParams = RequestHttpParams.set("info_option", infoOption);
    const headers = new HttpHeaders()
      .set("Accept", "application/vnd.mto.v2+json")
      .set("X-Client", "admin");

    if (infoOption == "employees") {
      if (paramsCollaborator.group) {
        RequestHttpParams = RequestHttpParams.set(
          "group",
          paramsCollaborator.group.toString()
        );
      }
      if (paramsCollaborator.status) {
        RequestHttpParams = RequestHttpParams.set(
          "status",
          paramsCollaborator.status.toString()
        );
      }
      if (paramsCollaborator.paymentFrequency) {
        RequestHttpParams = RequestHttpParams.set(
          "payment_frequency",
          paramsCollaborator.paymentFrequency.toString()
        );
      }
      if (paramsCollaborator.requestedMontos) {
        RequestHttpParams = RequestHttpParams.set(
          "montos_count",
          paramsCollaborator.requestedMontos.toString()
        );
      }
      if (paramsCollaborator.query) {
        RequestHttpParams = RequestHttpParams.set(
          "query",
          paramsCollaborator.query.toString()
        );
      }

      if (paramsCollaborator.sort) {
        if (paramsCollaborator.sort["column"]) {
          RequestHttpParams = RequestHttpParams.set(
            paramsCollaborator.sort["param"].toString(),
            paramsCollaborator.sort["column"].toString() +
              "," +
              paramsCollaborator.sort["order"].toString()
          );
        } else {
          RequestHttpParams = RequestHttpParams.set(
            paramsCollaborator.sort["param"].toString(),
            paramsCollaborator.sort["order"].toString()
          );
        }
      }

      const mapResponseToCollaborators = (response: ICollaboratorsResponse) => {
        const transformJSONToCollaborator = (
          collaborator: ICollaboratorResponse
        ) => {
          return new Collaborator(collaborator);
        };
        const transformJSONToPagination = new Pagination(response.meta);

        const resultJSON = {
          items: response.employees.map(transformJSONToCollaborator),
          pagination: transformJSONToPagination,
        };

        return resultJSON;
      };

      return this.httpClient
        .get(
          `${environment.API}/administration_index/${companyId}?page=${page}`,
          {
            params: RequestHttpParams,
          }
        )
        .pipe(map(mapResponseToCollaborators));
    }
    if (infoOption == "groups") {
      if (paramsGroups.query) {
        RequestHttpParams = RequestHttpParams.set(
          "query",
          paramsGroups.query.toString()
        );
      }
      if (paramsGroups.sort) {
        if (paramsGroups.sort["column"]) {
          RequestHttpParams = RequestHttpParams.set(
            paramsGroups.sort["param"].toString(),
            paramsGroups.sort["column"].toString() +
              "," +
              paramsGroups.sort["order"].toString()
          );
        } else {
          RequestHttpParams = RequestHttpParams.set(
            paramsGroups.sort["param"].toString(),
            paramsGroups.sort["order"].toString()
          );
        }
      }

      const mapResponseToGroups = (response: IGroupsResponse) => {
        const transformJSONToGroups = (groups: IGroupResponse) => {
          return new Group(groups);
        };
        const transformJSONToPagination = new Pagination(response.meta);

        const resultJSON = {
          items: response.groups.map(transformJSONToGroups),
          pagination: transformJSONToPagination,
        };

        return resultJSON;
      };
      return this.httpClient
        .get(
          `${environment.API}/administration_index/${companyId}?page=${page}`,
          {
            params: RequestHttpParams,
          }
        )
        .pipe(map(mapResponseToGroups));
    }
    if (infoOption == "payroll_periods") {
      if (paramsPeriod.query) {
        RequestHttpParams = RequestHttpParams.set(
          "query",
          paramsPeriod.query.toString()
        );
      }
      if (paramsPeriod.sort) {
        RequestHttpParams = RequestHttpParams.set(
          paramsPeriod.sort["param"].toString(),
          paramsPeriod.sort["column"].toString() +
            "," +
            paramsPeriod.sort["order"].toString()
        );
      }

      const mapResponseToPayrollPeriod = (
        response: IPayrollPeriodsAdministrationResponse
      ) => {
        const transformJSONToPayrollPeriod = (
          payrollPeriod: IPayrollPeriodResponse
        ) => {
          return new PayrollPeriod(payrollPeriod);
        };
        const transformJSONToPagination = new Pagination(response.meta);

        const resultJSON = {
          items: response.payroll_periods.map(transformJSONToPayrollPeriod),
          pagination: transformJSONToPagination,
        };

        return resultJSON;
      };
      return this.httpClient
        .get(
          `${environment.API}/administration_index/${companyId}?page=${page}`,
          {
            params: RequestHttpParams,
          }
        )
        .pipe(map(mapResponseToPayrollPeriod));
    }
    if (infoOption == "employee_info") {
      if (paramsCollaborator.group) {
        RequestHttpParams = RequestHttpParams.set(
          "group",
          paramsCollaborator.group.toString()
        );
      }
      if (paramsCollaborator.status) {
        RequestHttpParams = RequestHttpParams.set(
          "status",
          paramsCollaborator.status.toString()
        );
      }
      if (paramsCollaborator.paymentFrequency) {
        RequestHttpParams = RequestHttpParams.set(
          "payment_frequency",
          paramsCollaborator.paymentFrequency.toString()
        );
      }
      if (paramsCollaborator.requestedMontos) {
        RequestHttpParams = RequestHttpParams.set(
          "montos_count",
          paramsCollaborator.requestedMontos.toString()
        );
      }
      if (paramsCollaborator.query) {
        RequestHttpParams = RequestHttpParams.set(
          "query",
          paramsCollaborator.query.toString()
        );
      }
      if (paramsCollaborator.sort) {
        if (paramsCollaborator.sort["column"]) {
          RequestHttpParams = RequestHttpParams.set(
            paramsCollaborator.sort["param"].toString(),
            paramsCollaborator.sort["column"].toString() +
              "," +
              paramsCollaborator.sort["order"].toString()
          );
        } else {
          RequestHttpParams = RequestHttpParams.set(
            paramsCollaborator.sort["param"].toString(),
            paramsCollaborator.sort["order"].toString()
          );
        }
      }

      const mapResponseToCollaborators = (
        response: IInfoCollaboratorsResponse
      ) => {
        const transformJSONToPagination = new Pagination(response.meta);
        if (response.employee_infos !== undefined) {
          const resultJSON = {
            items: response.employee_infos,
            pagination: transformJSONToPagination,
          };
          return resultJSON;
        }
      };

      return this.httpClient
        .get(
          `${environment.API}/api/v2/superadmin/employee_infos?page=${page}&company_id=${companyId}`,
          {
            headers,
            params: RequestHttpParams,
          }
        )
        .pipe(map(mapResponseToCollaborators));
    }
  }

  sendToReview(companyId: string, payload: any) {
    return this.httpClient.put(
      `${environment.API}/companies/send_to_review/${companyId}`,
      payload,
      { responseType: "text" }
    );
  }
}
