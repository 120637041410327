import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  constructor(private sessionService: SessionService) {}

  isSuperadminVisible(): boolean {
    return this.sessionService.getUserRoles().includes("superadmin");
  }

  isAdminVisible(): boolean {
    return this.sessionService.getUserRoles().includes("admin");
  }
}
