import { ICompanyMontoSettingResponse } from "@declarations/company-monto-setting-response";

export class CompanyMontoSetting {
  customPercentage: number;
  id: string;
  maxWithdrawalQuantity: string;
  minWithdrawalQuantity: string;
  percentages: Array<any>;

  constructor(attrs: ICompanyMontoSettingResponse) {
    this.customPercentage = attrs.custom_percentage;
    this.id = attrs.id;
    this.maxWithdrawalQuantity = attrs.max_withdrawal_quantity.slice(0, -2);
    this.minWithdrawalQuantity = attrs.min_withdrawal_quantity.slice(0, -2);
    this.percentages = attrs.percentages;
  }
}
