import { IStateResponse } from "@declarations/state-response";

export class State {
  id: string;
  name: string;

  constructor(atrributes: IStateResponse) {
    this.id = atrributes.id;
    this.name = atrributes.name;
  }
}
