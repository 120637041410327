import { Component, OnInit, ViewChild } from "@angular/core";
import { ILogsPerDays } from "@declarations/logs-per-days";
import { ITableCollectionResponse } from "@declarations/table-collection-response";
import { Logs } from "@models/logs";
import { Pagination } from "@models/pagination";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
/* import { LogsService } from "@services/logs.service"; */
import * as moment from "moment";

@Component({
  selector: "app-logs-modal",
  templateUrl: "./logs-modal.component.html",
})
export class LogsModalComponent implements OnInit {
  @ViewChild("viewLogsModal")
  viewLogsModal;

  actualDate;

  initializePagination = {
    pagination: {
      current_page: 1,
      next_page: 1,
      prev_page: null,
      total_count: 0,
      total_pages: 1,
    },
  };

  logs: ITableCollectionResponse;

  selector: string = ".log-scroll";

  vigentLogs: Array<Logs> = [];

  pastLogs: Array<Logs> = [];

  pastLogsPerDays: Array<ILogsPerDays> = [];

  pagination: Pagination = new Pagination(this.initializePagination);

  constructor(
    private modalService: NgbModal
  ) /*  private logsService: LogsService */
  {}

  ngOnInit(): void {
    this.nextPage(1, true);
  }

  closelModal() {
    this.modalService.dismissAll();
  }

  openModal(options?): void {
    this.modalService.open(this.viewLogsModal, {
      ...options,
      ...{
        keyboard: false,
        ariaLabelledBy: "ModalViewLogs",
        centered: true,
        scrollable: true,
      },
    });
  }

  onScroll() {
    this.nextPage(this.pagination.nextPage);
  }

  nextPage(page: number, update?: boolean) {
    if (update) {
      this.logs = undefined;
      this.pagination = new Pagination(this.initializePagination);
    }

    /*  if (this.pagination.nextPage) {
      this.logsService.getLogs(page).subscribe((response) => {
        this.logs = response;
        this.pagination = response.pagination;

        let today = moment(new Date());

        this.logs.items.forEach((log) => {
          if (
            today.startOf("day") >
            moment(new Date(log.createdAt)).startOf("day")
          ) {
            this.pastLogs.push(log);

            let arrayLogs: Array<Logs> = [];
            let arrayLogsDate: Date;
            if (!this.actualDate) {
              this.actualDate = moment(new Date());
            }

            arrayLogsDate = log.createdAt;
            arrayLogs.push(log);

            if (
              this.actualDate
                .startOf("day")
                .isSame(moment(new Date(log.createdAt)).startOf("day"), "day")
            ) {
              //SAME DAY
              if (this.pastLogsPerDays.length) {
                const isInArray = this.pastLogsPerDays[
                  this.pastLogsPerDays.length - 1
                ].logs
                  .map(function (e) {
                    return e["id"];
                  })
                  .indexOf(log.id);

                if (isInArray == -1) {
                  this.pastLogsPerDays[
                    this.pastLogsPerDays.length - 1
                  ].logs.push(log);
                }
              }
            } else {
              // DIFFERENT DAY
              this.pastLogsPerDays.push({
                day: arrayLogsDate,
                logs: arrayLogs,
              });
            }
            this.actualDate = moment(new Date(log.createdAt));
          } else {
            this.vigentLogs.push(log);
          }
        });
      });
    } */
  }

  openFile(item) {
    window.open(item, "_blank");
  }
}
