import { Injectable } from "@angular/core";

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { CookieService } from "ngx-cookie";
import { environment } from "@environments/environment";
import { catchError } from "rxjs/internal/operators/catchError";
import { SessionService } from "@services/session.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private cookies: CookieService,
    private sessionService: SessionService,
    public router: Router
  ) {}

  intercept(req: HttpRequest<string>, next: HttpHandler) {
    const token = this.cookies.get(environment.COOKIE.DASHBOARD);
    const setAuthorizationHeader = {
      headers: req.headers.append("Authorization", `Bearer ${token}` || ""),
    };
    const request = req.clone(setAuthorizationHeader);

    return next.handle(request).pipe(
      catchError((err, caught) => {
        return next.handle(request).pipe(
          catchError((err) => {
            if (err.status === 401) {
              this.sessionService.destroySession();
              this.router.navigate(["/login"]);
            }

            return throwError(err);
          })
        );
      })
    );
  }
}
