<ng-template #viewStaggeredCommission>
  <div id="section__view-staggered-commission-modal">
    <div class="modal-header">
      <h4 class="h4-extend-small">Escalones</h4>
    </div>
    <div
      *ngIf="staggeredCommission.staggeredCommissionSteps.length"
      class="modal-content"
    >
      <ngb-accordion
        [destroyOnHide]="false"
        [closeOthers]="true"
        activeIds="static-1"
        #shadow
      >
        <ngb-panel
          *ngFor="
            let item of staggeredCommission.staggeredCommissionSteps;
            let i = index
          "
        >
          <ng-template ngbPanelTitle>
            <span>
              <h5 class="strong">Escalón {{ i + 1 }}</h5>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="commission">
              <p class="margin-small--bottom">
                Cantidad mínima
              </p>
              <p>
                {{ item.minQuantity | currency: "MXN":"symbol-narrow" }}
              </p>
            </div>
            <div class="commission">
              <p class="margin-small--bottom">
                Cantidad máxima
              </p>
              <p>
                {{ item.maxQuantity | currency: "MXN":"symbol-narrow" }}
              </p>
            </div>
            <div class="commission">
              <p>Comisión</p>
              <p *ngIf="staggeredCommission.commissionValueType == 'currency'">
                {{ item.commissionValue | currency: "MXN":"symbol-narrow" }}
              </p>
              <p
                *ngIf="staggeredCommission.commissionValueType == 'percentage'"
              >
                {{ item.commissionValue / 100 | percent }}
              </p>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</ng-template>
