import { ConfigService } from "../../services/config.service";
import { LogoutService } from "@services/logout.service";
import { Router } from "@angular/router";
import { SessionService } from "@services/session.service";
import { SidebarRoutesService } from "./routes/routes";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../../animations/custom-animations";

import { Component, OnInit, Renderer2 } from "@angular/core";
import { environment } from "@environments/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations,
  providers: [SidebarRoutesService],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  environment = environment;
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = "assets/img/logo.png";
  public config: any = {};

  constructor(
    public translate: TranslateService,
    private configService: ConfigService,
    private renderer: Renderer2,
    private router: Router,
    private sidebarRoutesService: SidebarRoutesService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.menuItems = this.sidebarRoutesService.getPermittedRoutes();

    if (this.config.layout.sidebar.backgroundColor === "white") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
