import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { SessionService } from "@services/session.service";

@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate() {
    const roleName: string = this.sessionService.getUserType();

    if (!(roleName == "superadmin")) {
      this.router.navigate(["/a/index"]);
      return false;
    }
    return true;
  }
}
