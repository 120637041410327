<ng-template #emptyPayrollPeriodsModal>
  <div id="section__empty-payroll-periods-modal">
    <div class="modal-header">
      <h5>No existen periodos de pago</h5>
    </div>
    <div class="modal-body">
      Aún no hay periodos de pago existentes, necesitas tener al menos un
      periodo de pago para crear un nuevo grupo.
    </div>
    <div class="modal-footer">
      <!-- TODO descomentar cuando HOME este listo-->
      <!--<a
        class="button--outline-primary"
        routerLink="/u/home"
        (click)="closeEmptyPayrollPeriodsModal()"
      >
        Ir al inicio
      </a>-->
      <button
        type="submit"
        class="button--primary"
        routerLink="/u/payroll-periods"
        (click)="closeEmptyPayrollPeriodsModal()"
      >
        Ir a periodos de pago
      </button>
    </div>
  </div>
</ng-template>
