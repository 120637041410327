import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";

@Injectable()
export class AuthGuard {
  constructor(private cookies: CookieService, private router: Router) {}

  /* canActivate(route: ActivatedRouteSnapshot) {
    const tokenName: string = route.data.tokenName;

    if (!this.cookies.get(tokenName)) {
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  } */

  logout(): void {
    this.cookies.removeAll();
  }
}
