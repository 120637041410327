import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-empty-payroll-periods-modal",
  templateUrl: "./empty-payroll-periods-modal.component.html",
})
export class EmptyPayrollPeriodsModalComponent implements OnInit {
  @ViewChild("emptyPayrollPeriodsModal") emptyPayrollPeriods: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  openEmptyPayrollPeriodsModal(options?: Object): void {
    this.modalService.open(this.emptyPayrollPeriods, {
      ...options,
      ...{
        backdrop: "static",
        keyboard: false,
        ariaLabelledBy: "ModalEmptyPayrollPeriods",
        centered: true,
      },
    });
  }

  closeEmptyPayrollPeriodsModal() {
    this.modalService.dismissAll();
  }
}
