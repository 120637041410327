<!--Footer Starts-->
<footer>
  <div class="container-fluid">
    <!--<p class="copyright text-center">
      Copyright &copy; {{ currentDate | date: "yyyy" }}
      <a
        id="pixinventLink"
        href="https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent"
        >PIXINVENT</a
      >, All rights reserved.
    </p>-->
  </div>
</footer>
<!--Footer Ends-->
