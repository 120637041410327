<div class="sidebar-page sidebar-collaborators-page">
  <!-- TODO Separate this content to another component-->
  <div class="sidebar-collaborators-page__header">
    <div class="left__header">
      <img src="assets/img/application/icons/ic_pencil-outline_black.svg" />
      <h4 class="h4-extend-small strong">Actualizar usuarios</h4>
    </div>
    <div class="right__header">
      <img
        (click)="emitToggleSidebar(true)"
        src="assets/img/application/icons/ic_close_black.svg"
      />
    </div>
  </div>

  <div class="switch-tabs">
    <br />
    <br />
    <form [formGroup]="updateCSVForm" (ngSubmit)="updateCSV(updateCSVForm)">
      <div class="sidebar-collaborators-page__content">
        <div id="section__load-csv">
          <app-input
            type="upload-csv"
            id="csvFile"
            [customClass]="'input-card'"
            [parentForm]="updateCSVForm"
            [responseError]="responseError"
            [fileOnChange]="fileChanges"
            (onFileDelete)="deleteFile()"
          >
          </app-input>
          <div
            *ngIf="!updateCSVForm.value.csvFile"
            class="download-template margin-small--top"
          >
            <div (click)="downloadTemplate()" class="download-template-link">
              <img
                src="assets/img/application/icons/ic_download-tiny-blue.svg"
                alt=""
              />
              <small class="strong">
                Descargar plantilla
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-collaborators-page__footer">
        <div class="sidebar-page__footer-content">
          <a (click)="emitToggleSidebar(true)" class="button--outline-primary">
            Cancelar
          </a>
          <button
            type="submit"
            [ngClass]="{
              'button--tertiary': updateCSVForm.invalid,
              'button--primary': updateCSVForm.valid
            }"
            [disabled]="isLoading"
          >
            Importar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
