import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  Injectable,
} from "@angular/core";
import { FormGroup, AbstractControl, Validators } from "@angular/forms";
import { Toastr } from "@services/toastr.service";
import { trigger, transition, style, animate } from "@angular/animations";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}

function isNumber(value: any): boolean {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = "-";

  fromModel(value: string | null): NgbDateStruct | null {
    let date;
    if (value) {
      if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)) {
        date = new Date(value);
      } else {
        const newValue = value.split(this.DELIMITER);
        date = new Date(
          parseInt(newValue[2], 10),
          parseInt(newValue[1], 10) - 1,
          parseInt(newValue[0], 10)
        );
      }

      return {
        day: +date.getDate(),
        month: +date.getMonth() + 1,
        year: +date.getFullYear(),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = new Date(value);
      return {
        day: +date.getDate(),
        month: +date.getMonth() + 1,
        year: +date.getFullYear(),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : "";
  }
}

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(200, style({ opacity: 0 })),
      ]),
    ]),
    trigger("fadeIn", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(200, style({ opacity: 0 })),
      ]),
    ]),
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class InputComponent implements OnInit {
  @Input() type: string;

  @Input() readonly: boolean;

  @Input() id: "";

  @Input() required: boolean;

  @Input() isAdult = false;

  @Input() collection = {};

  @Input() collectionId = "id";

  @Input() collectionName = "name";

  @Input() customError: string;

  @Input() customClass: string;

  @Input() customMessageOnMask: string;

  @Input() focus = false;

  @Input() fileOnChange = false;

  @Input() hasDecimal = false;

  @Input() image: string;

  @Input() imageText: string;

  @Input() labelName: string;

  @Input() parentForm: FormGroup;

  @Input() placeholder: string;

  @Input() responseError: string;

  @Input() maxDate: Date;

  @Input() maxDigitsOnMask: number;

  @Input() maxDigitsOnPhoneMask = 10;

  @Input() maxDigitsOnCLABEMask = 18;

  @Input() typeRangeOnMask = "centennial";

  @Input() minDate: Date;

  @Input() dateStartView = "year";

  @Output() onDateChange: EventEmitter<object> = new EventEmitter();

  @Output() onImageChange: EventEmitter<object> = new EventEmitter();

  @Output() onSelectChange: EventEmitter<object> = new EventEmitter();

  @Output() onKeyUpChange: EventEmitter<object> = new EventEmitter();

  @Output() onFileDelete: EventEmitter<boolean> = new EventEmitter();

  currentError: string;

  inputTypes: Array<string> = ["text", "email", "password"];

  fieldTextType: boolean;

  numberCharacters = 0;

  maxChars = 500;

  selectItem = null;

  states: Array<string> = [];

  filecsv = null;

  constructor(private toastr: Toastr) {
    /** No content */
  }

  ngOnInit(): void {
    /** No content */
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change["fileOnChange"]) {
      this.filecsv = null;
    }
  }

  get validateRequiredPattern(): AbstractControl {
    return this.parentForm.get(this.id.toString());
  }

  toggleFieldPasswordType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  //Count characters
  onKeyUp(event: any): void {
    this.numberCharacters = event.target.value.length;

    if (this.numberCharacters > this.maxChars) {
      event.target.value = event.target.value.slice(0, this.maxChars);
      this.numberCharacters = this.maxChars;
    }
  }

  selectItemHandler(event) {
    const value = {};
    value[this.id] = event;
    this.parentForm.patchValue(value);
    this.emitOnSelectChange(event);
  }

  emitOnSelectChange(id: any) {
    this.onSelectChange.emit(id);
  }

  keyUpHanddler(event) {
    this.emitOnKeyUpChange(event);
  }

  emitOnKeyUpChange(value) {
    this.onKeyUpChange.emit(value);
  }

  verifyContent(value: string) {
    this.verifyValue(value);
    this.checkForErrors();
  }

  verifyValue(value: string) {
    if (value != "" && value != null) {
      this.addState("--filled");
    } else {
      this.removeState("--filled");
    }
  }

  addState(...states: Array<string>) {
    states.forEach((state) => {
      if (!this.states.includes(state)) {
        this.states.push(state);
      }
    });
  }

  removeState(...states: Array<string>) {
    states.forEach((state) => {
      this.states = this.states.filter((value) => value !== state);
    });
  }

  checkForErrors() {
    const control = this.parentForm.controls[this.id];

    if (control.invalid) {
      this.removeState("--error", "--warning", "--success");

      Object.keys(control.errors).forEach((error) => {
        switch (error) {
          case "required": {
            this.addState("--warning");
            this.currentError = "El campo no puede estar vacío";
            break;
          }
          case "pattern": {
            this.addState("--warning");
            const invalidPattern = "El patrón es invalido";

            if (this.type == "email") {
              this.currentError = "Ingresa un correo válido";
            }
            if (this.type == "number") {
              this.currentError = "Ingresa un número válido";
            }
            if (this.type == "phone") {
              this.currentError = "Ingresa un número válido";
            }
            if (this.type == "hidepassword") {
              this.currentError =
                "Usa al menos 8 caracteres, una letra mayúscula, una letra minúscula y un dígito.";
            }
            if (!this.currentError) {
              this.currentError = invalidPattern;
            }

            break;
          }
          case "CLABEPattern": {
            this.addState("--warning");
            this.currentError = "Ingresa una CLABE válida";
            break;
          }
          case "rfcPattern": {
            this.addState("--warning");
            this.currentError = "Ingresa un RFC válido";
            break;
          }
          case "phoneEmptyError": {
            this.addState("--warning");
            this.currentError = "Telefono inválido";
            break;
          }
          case "mustMatchPassword": {
            this.addState("--warning");
            this.currentError = "Las contraseñas deben coincidir";
            break;
          }
          default: {
            this.addState("--warning");
            this.currentError =
              "Hubo un error con este campo, verifique su contenido";
            break;
          }
        }
      });
    } else {
      this.addState("--success");
      this.currentError = null;
    }
  }

  patchDate(event: any) {
    event.value = new Date(event.year, event.month - 1, event.day);
    // Verify content
    this.verifyContent(event.value);

    // Verify +18
    if (this.isAdult && event.value) {
      if (this.calculateAge(event.value) < 18) {
        this.toastr.single_error("Por favor elige una fecha en el pasado");
        this.parentForm.get(this.id).reset();
        return;
      }
    }

    // Patch value
    const dateObject = {};
    if (event.value) {
      dateObject[this.id] = event.value.toISOString();

      this.parentForm.patchValue(dateObject);
      this.onDateChange.emit(event);
    }
  }

  calculateAge(DOB) {
    var today = new Date();
    var birthDate = DOB;
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  fileChanged(event) {
    if (!event) {
      event = window.event;
    }

    const evt = event.target || event.srcElement;
    const file = evt.files;

    if (file && file[0]) {
      const fileSize = file[0].size / 1024 / 1024;

      if (file[0].type != "image/png" && file[0].type != "image/jpeg") {
        this.toastr.single_error("Formato no válido");
        return false;
      } else {
        if (fileSize <= 20) {
          const reader = new FileReader();

          reader.onload = (e: any) => {
            this.image = e.target.result;
          };
          const value = {};
          value[this.id] = file[0];
          reader.readAsDataURL(file[0]);
          this.parentForm.patchValue(value);
          this.onImageChange.emit();
        }
      }
    }
  }

  csvChanged(event, selected?: boolean) {
    let evt = null;
    let file = null;

    if (!event) {
      event = window.event;
    }

    if (selected) {
      evt = event.target || event.srcElement;
      file = evt.files; //FILELIST
    } else {
      file = event;
    }

    if (file && file[0]) {
      const fileSize = file[0].size / 1024 / 1024;

      if (
        file[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.toastr.single_error("Formato no válido");
        return false;
      } else {
        if (fileSize <= 20) {
          const reader = new FileReader();

          reader.onload = (e: any) => {
            this.image = e.target.result;
          };

          this.filecsv = {};
          this.filecsv[this.id] = file[0];
          reader.readAsDataURL(file[0]);
          this.parentForm.patchValue(this.filecsv);
          this.onImageChange.emit();
        }
      }
    }
  }

  deleteFile() {
    this.onFileDelete.emit(true);
    this.filecsv = null;
  }

  getFirst(text) {
    let newText = "";
    if (text) {
      newText = text.substring(0, 1);
    }
    return newText;
  }

  search(value): void {
    this.emitOnKeyUpChange(value);
  }

  get hasRequiredField(): boolean {
    const abstractControl = this.parentForm.get(this.id);
    if (abstractControl?.validator) {
      const validator = abstractControl?.validator({} as AbstractControl);
      if (validator && validator?.required) {
        return true;
      }
    }
    return false;
  }
}
