import { ICollaboratorResponse } from "@declarations/collaborator-response";

/** HACK: Move presenting logic to a ViewModel */
export class Collaborator {
  checkboxValue: boolean;
  activeStatus: boolean;
  cellphone: string;
  collaboratorId: string;
  department: string;
  email: string;
  firstName: string;
  group: string;
  name: string;
  lastName: string;
  id: string;
  isPennyChecked: boolean;
  status: boolean;
  netSalary: number;
  paymentFrequency: string;
  pennyCheckError: string;
  registrationStatus: string;
  amountsWithdrawn: number;
  role: string;
  hasUser: boolean;

  constructor(collaboratorAttrs: ICollaboratorResponse) {
    this.activeStatus = collaboratorAttrs.active_status;
    this.cellphone = collaboratorAttrs.cellphone;
    this.collaboratorId = collaboratorAttrs.collaborator_id;
    this.department = collaboratorAttrs.department;
    this.email = collaboratorAttrs.email;
    this.firstName = collaboratorAttrs.first_name;
    this.group = collaboratorAttrs.group || "Sin definir";
    this.name = collaboratorAttrs.full_name;
    this.lastName = collaboratorAttrs.last_name;
    this.id = collaboratorAttrs.id;
    this.isPennyChecked = collaboratorAttrs.is_penny_checked;
    this.netSalary = collaboratorAttrs.net_salary || 0;
    this.paymentFrequency =
      collaboratorAttrs.payment_frequency || "Sin definir";
    this.pennyCheckError = collaboratorAttrs.penny_check_error;
    this.registrationStatus = collaboratorAttrs.registration_status;
    this.amountsWithdrawn = collaboratorAttrs.requested_montos;
    this.role = collaboratorAttrs.role;
    this.hasUser = collaboratorAttrs.has_user;
  }
}
