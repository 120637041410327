import { IUpcomingCycleResponse } from "@declarations/upcoming-cycle-response";
import { UpcomingCycle } from "./upcoming-cycle";

export class CyclesByPeriod {
  periodName: string;
  cycles: Array<UpcomingCycle>;

  constructor() {
    this.periodName = "";
    this.cycles = [];
  }
}
