import { IGroupListResponse } from "@declarations/group-list-response";

export class GroupList {
  collaboratorsCount: number;
  id: string;
  isActive: boolean;
  name: string;
  paymentFrequency: string;
  payrollPeriod: string;
  percentageSalaryToWithdraw: string;
  withdrawalsCount: number;

  constructor(companyAttrs: IGroupListResponse) {
    this.collaboratorsCount = companyAttrs.collaborators_count;
    this.id = companyAttrs.id;
    this.isActive = companyAttrs.is_active;
    this.name = companyAttrs.name;
    this.paymentFrequency = companyAttrs.payment_frequency;
    this.payrollPeriod = companyAttrs.payroll_period;
    this.percentageSalaryToWithdraw =
      companyAttrs.percentage_salary_to_withdraw;
    this.withdrawalsCount = companyAttrs.withdrawals_count;
  }
}
