<div class="periodicity__steps">
  <div class="steps">
    <div *ngIf="cycles.length > 0">
      <div *ngFor="let cycle of cycles; let i = index">
        <div
          *ngIf="
            !cycle.paymentDate ||
            !cycle.cutoffDate ||
            viewDate == cycle.startDate
          "
          class="step__content"
        >
          <div class="info_step created" (click)="navigateToCycle(i)">
            <div
              class="circle_step blue"
              [ngClass]="{ active: viewDate == cycle.startDate }"
            >
              <h5>{{ i + 1 }}</h5>
            </div>
            <div class="cycle_step">
              <h5>
                {{ cycle.startDate | date: "d" }} -
                {{ cycle.endDate | date: "d" }} de
                {{ cycle.endDate | date: "LLLL" }},
                {{ cycle.endDate | date: "yyyy" }}
              </h5>
            </div>
          </div>
          <div
            *ngIf="cycle.paymentDate && cycle.cutoffDate"
            class="checkmark_step"
          >
            <img
              src="assets/img/application/icons/ic_checkmark_accent-success-500.svg"
              alt=""
            />
          </div>
        </div>
        <div
          *ngIf="
            cycle.paymentDate && cycle.cutoffDate && viewDate != cycle.startDate
          "
          class="step__content"
        >
          <div class="info_step created" (click)="navigateToCycle(i)">
            <div class="circle_step blue">
              <h5>{{ i + 1 }}</h5>
            </div>
            <div class="cycle_step">
              <h5>
                {{ cycle.startDate | date: "d" }} -
                {{ cycle.endDate | date: "d" }} de
                {{ cycle.endDate | date: "LLLL" }},
                {{ cycle.endDate | date: "yyyy" }}
              </h5>
            </div>
          </div>
          <div class="checkmark_step">
            <img
              src="assets/img/application/icons/ic_checkmark_accent-success-500.svg"
              alt=""
            />
          </div>
        </div>
        <div class="middle-dates">
          <div>
            <ul>
              <li *ngIf="cycle.cutoffDate">
                Fecha de Corte: {{ cycle.cutoffDate | date: "d" }} de
                {{ cycle.cutoffDate | date: "LLLL" }},
                {{ cycle.cutoffDate | date: "yyyy" }}
              </li>
              <li *ngIf="cycle.paymentDate">
                Fecha de Pago: {{ cycle.paymentDate | date: "d" }} de
                {{ cycle.paymentDate | date: "LLLL" }},
                {{ cycle.paymentDate | date: "yyyy" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cycles.length < 1" class="step__content">
      <div class="info_step">
        <div class="circle_step grey"><h5>1</h5></div>
        <div class="cycle_step"><h5>...</h5></div>
      </div>
    </div>
    <div class="middle-dates"></div>
    <div *ngIf="cycles.length < 2" class="step__content">
      <div class="info_step">
        <div class="circle_step grey"><h5>2</h5></div>
        <div class="cycle_step"><h5>...</h5></div>
      </div>
    </div>
    <div class="middle-dates"></div>
    <div *ngIf="cycles.length < 3" class="step__content">
      <div class="info_step">
        <div class="circle_step grey"><h5>3</h5></div>
        <div class="cycle_step"><h5>...</h5></div>
      </div>
    </div>
    <div class="middle-dates"></div>
  </div>
</div>
<h5 class="margin-medium--bottom">
  Para agregar fechas adicionales dirígete al detalle del periodo en la sección
  de periodos de pago
</h5>
<div class="section__create-payroll-periods-calendar">
  <div class="create-payroll-periods-calendar__header">
    <div class="payroll-periods-edit-calendar__navigator margin-base--bottom">
      <button
        type="button"
        class="button--calendar-cycle-navigator left"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        <img src="assets/img/application/icons/ic_chevron-left_grey.svg" />
      </button>
      <h4>{{ viewDate | date: "MMMM" | titlecase }}</h4>
      <button
        type="button"
        class="button--calendar-cycle-navigator right"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        <img src="assets/img/application/icons/ic_chevron-right_grey.svg" />
      </button>
    </div>
    <div
      *ngIf="cycle.startDate && cycle.endDate"
      class="payroll-periods-edit-calendar__current-cycle"
    >
      <h5 class="strong">
        {{ cycle.startDate | date: "d" }} - {{ cycle.endDate | date: "d" }} de
        {{ cycle.endDate | date: "LLLL" }},
        {{ cycle.endDate | date: "yyyy" }}
      </h5>
    </div>
    <div class="header__content">
      <button
        type="button"
        class="button--calendar-steps calendar-event__detail-start"
        [ngClass]="{ active: cycle.startDate }"
        [disabled]="cycles.length > 1"
        (click)="startDateOnChange()"
      >
        Inicio
      </button>
      <button
        type="button"
        class="button--calendar-steps calendar-event__detail-end"
        [ngClass]="{ active: cycle.endDate }"
        (click)="endDateOnChange()"
      >
        Fin
      </button>
      <button
        type="button"
        class="button--calendar-steps calendar-event__detail-cut"
        [ngClass]="{ active: cycle.cutoffDate }"
        (click)="cutoffDateOnChange()"
      >
        Corte
      </button>
      <button
        type="button"
        class="button--calendar-steps calendar-event__detail-payment"
        [ngClass]="{ active: cycle.paymentDate }"
        (click)="paymentDateOnChange()"
      >
        Pago
      </button>
    </div>
  </div>
  <div class="create-payroll-periods-calendar__content">
    <mwl-calendar-month-view
      [events]="events"
      [viewDate]="viewDate"
      (beforeViewRender)="renderCurrentCycleEvents($event)"
      (dayClicked)="dayClicked($event.day)"
    >
    </mwl-calendar-month-view>
  </div>
</div>
