import { Injectable } from "@angular/core";
import { SessionService } from "@services/session.service";

import { CanActivate, Router } from "@angular/router";

@Injectable()
export class NoSessionGuard {
  constructor(private router: Router, private sessionService: SessionService) {
    /** Do nothing */
  }

  canActivate(): boolean {
    const token = this.sessionService.getSession();

    if (token) {
      this.router.navigateByUrl("/login");

      return false;
    } else {
      return true;
    }
  }
}
