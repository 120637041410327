import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import {
  CalendarView,
  CalendarEvent,
  CalendarDateFormatter,
  CalendarMonthViewBeforeRenderEvent,
} from "angular-calendar";
import { CustomDateFormatter } from "../create-payroll-periods-calendar/custom-date-formatter.provider";
import { PayrollPeriodCycle } from "@models/payroll-period-cycle";

import { subDays, addDays } from "date-fns";
import * as moment from "moment";

@Component({
  selector: "app-payroll-periods-detail-calendar",
  templateUrl: "./payroll-periods-detail-calendar.component.html",
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class PayrollPeriodsDetailCalendarComponent implements OnInit {
  @Input() cycles: Array<PayrollPeriodCycle>;

  @Input() cycle: PayrollPeriodCycle;

  @Output()
  currentCycleSelectionChanged: EventEmitter<
    PayrollPeriodCycle
  > = new EventEmitter<PayrollPeriodCycle>();

  @Output()
  setNewCycle: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** TODO: Move this to a shared constant. It is used in edit-payroll-periods-calendar */
  colors: any = {
    green: {
      primary: "#00E096",
      secondary: "#dde7ff",
    },
    orange: {
      primary: "#FF7750",
      secondary: "#dde7ff",
    },
    blue: {
      primary: "#3667bd",
      secondary: "#D1E8FF",
    },
    yellow: {
      primary: "#FFC600",
      secondary: "#FDF1BA",
    },
  };

  events: CalendarEvent[] = [];

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  viewState = {
    currentViewingCycle: undefined,
  };

  constructor() {}

  ngOnInit(): void {
    this.viewState.currentViewingCycle = this.cycle;

    this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
  }

  /** HACK: Prevent using ngOnChanges by changing input in template to async */
  ngOnChanges(change: SimpleChanges) {
    if (change["cycle"].currentValue) {
      this.viewState.currentViewingCycle = this.cycle;
      this.viewDate = this.viewState.currentViewingCycle.startDate;
      this.setVisibleCalendarEvents(this.cycle);
    }
  }

  setVisibleCalendarEvents(cycle: PayrollPeriodCycle) {
    let cutoffEvent;
    let paymentEvent;

    const cycleEvent = {
      title: "Periodo de pago",
      start: cycle.startDate,
      end: cycle.endDate,
      color: {
        primary: "rgba(0,0,0,0)",
        secondary: "rgba(0,0,0,0)",
      },
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
    };
    if (cycle.cutoffDate) {
      cutoffEvent = {
        title: "Fecha de corte",
        start: cycle.cutoffDate,
        end: cycle.cutoffDate,
        color: this.colors.yellow,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };
    }
    if (cycle.paymentDate) {
      paymentEvent = {
        title: "Fecha de pago",
        start: cycle.paymentDate,
        end: cycle.paymentDate,
        color: this.colors.blue,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };
    }

    this.events = [cycleEvent];
    cycle.cutoffDate ? (this.events = [...this.events, cutoffEvent]) : null;
    cycle.paymentDate ? (this.events = [...this.events, paymentEvent]) : null;
  }

  renderCurrentCycleEvents(
    renderEvent: CalendarMonthViewBeforeRenderEvent
  ): void {
    const currentCycle = this.viewState.currentViewingCycle;

    /** Style to new cycle */
    if (currentCycle.newCycle) {
      renderEvent.body.forEach((day) => {
        const dayAsMoment = moment(day.date);
        const startDateAsMoment = moment(currentCycle.startDate);
        const endDatesAsMoment = moment(currentCycle.endDate);

        if (
          dayAsMoment.startOf("day").isSame(startDateAsMoment.startOf("day"))
        ) {
          day.cssClass = "bg_periodDaysNewCycle-start";
        }

        if (
          dayAsMoment.startOf("day").isSame(endDatesAsMoment.startOf("day"))
        ) {
          day.cssClass = "bg_periodDaysNewCycle-end";
        }

        if (
          dayAsMoment
            .startOf("day")
            .isBetween(
              startDateAsMoment.startOf("day"),
              endDatesAsMoment.startOf("day")
            )
        ) {
          day.cssClass = "bg_periodDaysNewCycle";
        }
      });

      return;
    }

    renderEvent.body.forEach((day) => {
      const dayAsMoment = moment(day.date);
      const startDateAsMoment = moment(currentCycle.startDate);
      const endDatesAsMoment = moment(currentCycle.endDate);

      const secondDay = moment(addDays(this.cycle.startDate, 1));
      const penultimateDay = moment(subDays(this.cycle.endDate, 1));

      if (dayAsMoment.startOf("day").isSame(startDateAsMoment.startOf("day"))) {
        day.cssClass = "bg_startDay";
      }

      if (dayAsMoment.startOf("day").isSame(endDatesAsMoment.startOf("day"))) {
        day.cssClass = "bg_endDate";
      }

      if (
        dayAsMoment
          .startOf("day")
          .isBetween(
            startDateAsMoment.startOf("day"),
            endDatesAsMoment.startOf("day")
          )
      ) {
        day.cssClass = "bg_periodDays";
      }

      if (dayAsMoment.isSame(secondDay)) {
        day.cssClass = "bg_periodDays-firstDay";
      }
      if (dayAsMoment.startOf("day").isSame(penultimateDay.startOf("day"))) {
        day.cssClass = "bg_periodDays-lastDay";
      }
    });
  }

  nextCycle(): void {
    const cycleIndexCount = this.cycles.length - 1;

    if (this.currentViewingCycleIndex < cycleIndexCount) {
      const previousCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );
      this.viewState.currentViewingCycle = this.cycles[
        this.currentViewingCycleIndex + 1
      ];
      const currentCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );

      /** TODO: DRY this validation because it's duplicated in previous cycle function */
      /** Change calendar month if new cycle is in next month */
      if (
        !currentCycleStartDateMoment.isSame(
          previousCycleStartDateMoment,
          "month"
        )
      ) {
        this.viewDate = this.viewState.currentViewingCycle.startDate;
      }

      this.currentCycleSelectionChanged.emit(
        this.viewState.currentViewingCycle
      );
      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
    }
  }

  previousCycle(): void {
    if (this.currentViewingCycleIndex > 0) {
      const previousCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );
      this.viewState.currentViewingCycle = this.cycles[
        this.currentViewingCycleIndex - 1
      ];
      const currentCycleStartDateMoment = moment(
        this.viewState.currentViewingCycle.startDate
      );

      /** TODO: DRY this validation because it's duplicated in next cycle function */
      /** Change calendar month if new cycle is in next month */
      if (
        !currentCycleStartDateMoment.isSame(
          previousCycleStartDateMoment,
          "month"
        )
      ) {
        this.viewDate = this.viewState.currentViewingCycle.startDate;
      }

      this.currentCycleSelectionChanged.emit(
        this.viewState.currentViewingCycle
      );
      this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
    }
  }

  nextCycleInNewMonth(): void {
    let moveToCycle = null;
    this.cycles.forEach((cycle) => {
      if (cycle.startDate.getMonth() + 1 == this.viewDate.getMonth() + 1) {
        if (!moveToCycle) {
          moveToCycle = cycle;

          this.viewState.currentViewingCycle = moveToCycle;
          this.setVisibleCalendarEvents(this.viewState.currentViewingCycle);
        }
      }
    });
  }

  private get currentViewingCycleIndex(): number {
    return this.cycles.findIndex((cycle: PayrollPeriodCycle) => {
      return cycle == this.viewState.currentViewingCycle;
    });
  }

  emitNewCycle() {
    this.setNewCycle.emit(true);
  }
}
