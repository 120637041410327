import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  startOfMonth,
  subDays,
  addDays,
  addMonths,
  endOfMonth,
  differenceInCalendarDays,
} from "date-fns";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CalendarEvent,
  CalendarView,
  CalendarDateFormatter,
  CalendarMonthViewBeforeRenderEvent,
} from "angular-calendar";

import { CustomDateFormatter } from "./custom-date-formatter.provider";
import { PayrollPeriodCycle } from "@models/payroll-period-cycle";

import * as moment from "moment";
import { Toastr } from "@services/toastr.service";

@Component({
  selector: "app-create-payroll-periods-calendar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./create-payroll-periods-calendar.component.html",
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CreatePayrollPeriodsCalendarComponent implements OnChanges {
  @ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;

  @Input() cyclePaymentScheme: string;

  @Input() resetEvents: boolean = false;

  //** TODO Delete var obsolete */
  @Output()
  onSetDates: EventEmitter<Array<PayrollPeriodCycle>> = new EventEmitter<
    Array<PayrollPeriodCycle>
  >();

  @Output()
  currentCycleSelectionChanged: EventEmitter<
    PayrollPeriodCycle
  > = new EventEmitter<PayrollPeriodCycle>();

  calendarView = CalendarView;

  //clickCount = 0;

  colors: any = {
    green: {
      primary: "#00E096",
      secondary: "#dde7ff",
    },
    orange: {
      primary: "#FF7750",
      secondary: "#dde7ff",
    },
    blue: {
      primary: "#3667bd",
      secondary: "#D1E8FF",
    },
    yellow: {
      primary: "#FFC600",
      secondary: "#FDF1BA",
    },
  };

  cycle: PayrollPeriodCycle = new PayrollPeriodCycle({
    cutoff_date: null,
    end_date: null,
    payment_date: null,
    reconciliation_date: null,
    start_date: null,
  });

  cycles: Array<PayrollPeriodCycle> = [];

  cyclePaymentSchemeDays: number = 0;

  dateSelected: Date;

  events: CalendarEvent[] = [];

  cutoffDateChange: boolean = false;

  endDateDateChange: boolean = false;

  endDateSecondary: Date;

  paymentDateChange: boolean = false;

  refresh: Subject<any> = new Subject();

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  constructor(private modal: NgbModal, private toastr: Toastr) {}

  ngOnChanges(change: SimpleChanges) {
    if (change["resetEvents"]) {
      this.events = [];
      this.cycles = [];
      this.cycle = new PayrollPeriodCycle({
        cutoff_date: null,
        end_date: null,
        payment_date: null,
        reconciliation_date: null,
        start_date: null,
      });
      this.cutoffDateChange = false;
      /** DEPRECATED */
      //this.endDateDateChange = false;
      this.endDateSecondary = null;
      this.paymentDateChange = false;
      this.cyclePaymentSchemeDays = 0;
      this.refresh.next();
    }
    if (change["cyclePaymentScheme"]) {
      const date = endOfMonth(this.viewDate);

      if (this.cyclePaymentScheme == "0") {
        this.cyclePaymentSchemeDays = 7;
      }
      if (this.cyclePaymentScheme == "1") {
        this.cyclePaymentSchemeDays = 10;
      }
      if (this.cyclePaymentScheme == "2") {
        this.cyclePaymentSchemeDays = 14;
      }
      if (this.cyclePaymentScheme == "3") {
        this.cyclePaymentSchemeDays = 15;
      }
      if (this.cyclePaymentScheme == "4") {
        this.cyclePaymentSchemeDays = date.getDate();
      }
    }
  }

  navigateToCycle(index) {
    this.cycle = this.cycles[index];
    this.viewDate = this.cycle.startDate;
    this.cycle.endDate ? (this.endDateSecondary = this.cycle.endDate) : null;
    this.setVisibleCalendarEventsNavigation();
  }

  startDateOnChange() {
    if (this.cycles.length > 1) {
      this.toastr.single_error("Sólo se permite al crear el primer periodo");
      return;
    }

    this.events = [];
    this.cycles = [];
    this.cycle = new PayrollPeriodCycle({
      cutoff_date: null,
      end_date: null,
      payment_date: null,
      reconciliation_date: null,
      start_date: null,
    });
    this.cutoffDateChange = false;
    this.endDateSecondary = null;
    this.paymentDateChange = false;
    this.refresh.next();
  }

  endDateOnChange() {
    if (!this.cycle.endDate) {
      return;
    }

    this.endDateDateChange = true;
    this.cycle.endDate = null;
    this.setVisibleCalendarEventsNavigation();
  }

  cutoffDateOnChange() {
    this.cutoffDateChange = true;
    this.cycle.cutoffDate = null;
    this.setVisibleCalendarEventsNavigation();
  }

  paymentDateOnChange() {
    this.paymentDateChange = true;
    this.cycle.paymentDate = null;
    this.setVisibleCalendarEventsNavigation();
  }

  updateCycleDates() {
    if (moment(this.cycle.endDate).isSame(this.dateSelected)) {
      this.cycle.endDate = this.dateSelected;
      this.endDateSecondary = this.cycle.endDate;
    } else {
      const daysDiff = differenceInCalendarDays(
        this.endDateSecondary,
        this.dateSelected
      );
      this.moveAllCicles(daysDiff);
    }

    this.emitDateChanges(this.cycles);
    this.setVisibleCalendarEventsNavigation();
  }

  moveAllCicles(numberOfDays) {
    this.cycle.endDate = this.dateSelected;
    this.endDateSecondary = this.cycle.endDate;
    let i = this.currentViewingCycleIndex + 1;

    while (i < this.cycles.length) {
      if (this.cycles[i].startDate) {
        if (numberOfDays) {
          this.cycles[i].startDate = subDays(
            this.cycles[i].startDate,
            numberOfDays
          );
        } else {
          this.cycles[i].startDate = addDays(
            this.cycles[i].startDate,
            numberOfDays
          );
        }
      }
      if (this.cycles[i].endDate) {
        if (numberOfDays) {
          this.cycles[i].endDate = subDays(
            this.cycles[i].endDate,
            numberOfDays
          );
        } else {
          this.cycles[i].endDate = addDays(
            this.cycles[i].endDate,
            numberOfDays
          );
        }
      }
      if (this.cycles[i].cutoffDate) {
        if (numberOfDays) {
          this.cycles[i].cutoffDate = subDays(
            this.cycles[i].cutoffDate,
            numberOfDays
          );
        } else {
          this.cycles[i].cutoffDate = addDays(
            this.cycles[i].cutoffDate,
            numberOfDays
          );
        }
      }
      if (this.cycles[i].paymentDate) {
        if (numberOfDays) {
          this.cycles[i].paymentDate = subDays(
            this.cycles[i].paymentDate,
            numberOfDays
          );
        } else {
          this.cycles[i].paymentDate = addDays(
            this.cycles[i].paymentDate,
            numberOfDays
          );
        }
      }

      i = i + 1;
    }
  }

  setVisibleCalendarEventsNavigation() {
    const cycleEvent = {
      title: "Periodo de pago",
      start: this.cycle.startDate,
      end: this.cycle.endDate,
      color: {
        primary: "rgba(0,0,0,0)",
        secondary: "rgba(0,0,0,0)",
      },
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
    };
    this.refresh.next();
    const cutoffEvent = {
      title: "Fecha de corte",
      start: this.cycle.cutoffDate,
      end: this.cycle.cutoffDate,
      color: this.colors.yellow,
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
    };
    this.refresh.next();
    const paymentEvent = {
      title: "Fecha de pago",
      start: this.cycle.paymentDate,
      end: this.cycle.paymentDate,
      color: this.colors.blue,
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
    };

    this.events = [cycleEvent];
    this.cycle.cutoffDate
      ? (this.events = [...this.events, cutoffEvent])
      : null;
    this.cycle.paymentDate
      ? (this.events = [...this.events, paymentEvent])
      : null;

    this.emitDateChanges(this.cycles);
    this.refresh.next();

    /** Add new cycle if user adds paymentDate for his own **/
    this.cycle.paymentDate &&
    (this.cutoffDateChange || this.paymentDateChange) &&
    (this.cycles.length == 1 ||
      (this.currentViewingCycleIndex == 0 && this.cycles.length == 2))
      ? this.generateNextCycle(this.cycle.endDate)
      : null;
  }

  setVisibleCalendarEvents() {
    let cycleEvent, cutoffEvent, paymentEvent;

    if (this.cycle.startDate && this.cycle.endDate && !this.cycle.cutoffDate) {
      cycleEvent = {
        title: "Periodo de pago",
        start: this.cycle.startDate,
        end: this.cycle.endDate,
        color: {
          primary: "rgba(0,0,0,0)",
          secondary: "rgba(0,0,0,0)",
        },
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };

      this.cycles.push(this.cycle);
      this.events = [cycleEvent];
      this.refresh.next();
      return;
    }
    if (
      this.cycle.startDate &&
      this.cycle.endDate &&
      this.cycle.cutoffDate &&
      !this.cycle.paymentDate
    ) {
      cutoffEvent = {
        title: "Fecha de corte",
        start: this.cycle.cutoffDate,
        end: this.cycle.cutoffDate,
        color: this.colors.yellow,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };

      this.events = [...this.events, cutoffEvent];
      this.refresh.next();
      return;
    }
    if (
      this.cycle.startDate &&
      this.cycle.endDate &&
      this.cycle.cutoffDate &&
      this.cycle.paymentDate
    ) {
      paymentEvent = {
        title: "Fecha de pago",
        start: this.cycle.paymentDate,
        end: this.cycle.paymentDate,
        color: this.colors.blue,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
      };

      this.events = [...this.events, paymentEvent];
      this.refresh.next();

      this.cycles.length < 4 ? this.cycles.pop() : null;

      this.cycles.push(this.cycle);
      this.emitDateChanges(this.cycles);

      this.generateNextCycle(this.cycle.endDate);
    }
  }

  private get currentViewingCycleIndex(): number {
    return this.cycles.findIndex((cycle: PayrollPeriodCycle) => {
      return cycle == this.cycle;
    });
  }

  private get dateInRange(): boolean {
    return (
      this.dateSelected >= this.cycle.startDate &&
      this.dateSelected <= this.cycle.endDate
    );
  }

  private get validateCutDatePaymentDate(): boolean {
    const currentDate = moment(new Date());
    const dateSelected = moment(this.dateSelected);

    return dateSelected.startOf("day") >= currentDate.startOf("day");
  }

  private get isEndDateValid(): boolean {
    const days =
      differenceInCalendarDays(this.dateSelected, this.cycle.startDate) + 1;

    if (this.cyclePaymentScheme == "0") {
      if (days >= 5 && days <= 9) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == "1") {
      if (days >= 8 && days <= 12) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == "2") {
      if (days >= 12 && days <= 16) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == "3") {
      if (days >= 13 && days <= 17) {
        return true;
      } else {
        return false;
      }
    }
    if (this.cyclePaymentScheme == "4") {
      if (days >= 28 && days <= 33) {
        return true;
      } else {
        return false;
      }
    }
  }

  dayClicked({ date }: { date: Date }): void {
    this.dateSelected = date;
    if (this.cyclePaymentSchemeDays) this.setDates();
    else this.toastr.single_error("Selecciona una frecuencia de pago primero");
  }

  setDates(): boolean {
    if (this.dateSelected >= startOfMonth(new Date())) {
      if (this.cycles.length <= 3) {
        /** Add startDate and endDate */
        if (
          !this.cycle.startDate &&
          !this.cycle.endDate &&
          !this.cycle.cutoffDate &&
          !this.cycle.paymentDate &&
          !this.cutoffDateChange &&
          !this.paymentDateChange
        ) {
          this.cycle = new PayrollPeriodCycle({
            start_date: subDays(this.dateSelected, 0),
            end_date: addDays(
              this.dateSelected,
              this.cyclePaymentSchemeDays - 1
            ),
          });
          this.endDateSecondary = this.cycle.endDate;
          this.setVisibleCalendarEvents();
          return false;
        } else {
          if (this.endDateDateChange) {
            // If cutday and payment day exist, validate new endDate
            if (this.cycle.cutoffDate || this.cycle.paymentDate) {
              if (
                this.dateSelected < this.cycle.cutoffDate ||
                this.dateSelected < this.cycle.paymentDate
              ) {
                this.toastr.single_error(
                  "La fecha final no puede ser menor que la fecha de corte o pago actuales"
                );
                this.cycle.endDate = this.endDateSecondary;
                this.endDateDateChange = false;
                this.setVisibleCalendarEventsNavigation();
                return;
              }
            }
            const dayAsMoment = moment(this.endDateSecondary);

            if (this.isEndDateValid) {
              this.updateCycleDates();
            } else {
              if (this.cyclePaymentScheme == "0") {
                this.toastr.single_error(
                  "El periodo semanal sólo puede durar de 5 a 9 días."
                );
              }
              if (this.cyclePaymentScheme == "1") {
                this.toastr.single_error(
                  "El periodo decenal sólo puede durar de 8 a 12 días."
                );
              }
              if (this.cyclePaymentScheme == "2") {
                this.toastr.single_error(
                  "El periodo catorcenal sólo puede durar de 12 a 16 días."
                );
              }
              if (this.cyclePaymentScheme == "3") {
                this.toastr.single_error(
                  "El periodo quincenal sólo puede durar de 13 a 17 días."
                );
              }
              if (this.cyclePaymentScheme == "4") {
                this.toastr.single_error(
                  "El periodo mensual sólo puede durar de 28 a 33 días."
                );
              }

              this.cycle.endDate = this.endDateSecondary;
              this.endDateDateChange = false;
              this.setVisibleCalendarEventsNavigation();
            }

            this.endDateDateChange = false;
            return false;
          }
          /** Add cutoffDate */
          if (!this.cycle.cutoffDate) {
            if (this.validateCutDatePaymentDate) {
              if (this.dateInRange) {
                this.cycles.length == 3 ? (this.cutoffDateChange = true) : null;

                this.cycle.cutoffDate = subDays(this.dateSelected, 0);
                this.cutoffDateChange
                  ? this.setVisibleCalendarEventsNavigation()
                  : this.setVisibleCalendarEvents();

                this.cutoffDateChange = false;
              } else {
                this.toastr.single_error(
                  "La fecha de corte sólo puede estar dentro del periodo"
                );
              }
            } else {
              this.toastr.single_error(
                "La fecha de corte no puede ser menor al día de hoy"
              );
            }
            return false;
          }
          /** Add paymentDate */
          if (!this.cycle.paymentDate && this.cycle.cutoffDate) {
            if (this.validateCutDatePaymentDate) {
              this.cycles.length == 3 ? (this.paymentDateChange = true) : null;

              this.cycle.paymentDate = subDays(this.dateSelected, 0);
              this.paymentDateChange
                ? this.setVisibleCalendarEventsNavigation()
                : this.setVisibleCalendarEvents();
              this.paymentDateChange = false;
            } else {
              this.toastr.single_error(
                "La fecha de pago no puede ser menor al día de hoy"
              );
            }
            return false;
          }
          /** Change cycle if exists*/
          if (!this.dateInRange) {
            this.cycles.forEach((dates, index) => {
              if (
                this.dateSelected >= dates.startDate &&
                this.dateSelected <= dates.endDate
              ) {
                this.cycle = this.cycles[index];
                this.viewDate = this.cycle.startDate;

                this.cycle.endDate
                  ? (this.endDateSecondary = this.cycle.endDate)
                  : null;

                this.setVisibleCalendarEventsNavigation();
              }
            });
            return false;
          }
        }
      }
    }
  }

  generateNextCycle(date) {
    /** next month if frequency is monthly */
    if (this.cyclePaymentScheme == "4") {
      this.cyclePaymentSchemeDays = endOfMonth(
        addMonths(this.viewDate, 1)
      ).getDate();
    }

    if (
      this.cycles.length <= 2 &&
      !this.cutoffDateChange &&
      !this.paymentDateChange
    ) {
      this.cycle = new PayrollPeriodCycle({
        start_date: addDays(date, 1),
        end_date: addDays(date, this.cyclePaymentSchemeDays),
      });
      this.endDateSecondary = this.cycle.endDate;
      /** Update view in calendar */
      this.viewDate = this.cycle.endDate;

      this.setVisibleCalendarEvents();
    }
  }

  renderCurrentCycleEvents(
    renderEvent: CalendarMonthViewBeforeRenderEvent
  ): void {
    renderEvent.body.forEach((day) => {
      const dayAsMoment = moment(day.date);

      if (this.cycle) {
        const startDateAsMoment = moment(this.cycle.startDate);
        const endDatesAsMoment = moment(this.cycle.endDate);

        if (dayAsMoment.isSame(startDateAsMoment)) {
          day.cssClass = "bg_startDay";
        }

        if (dayAsMoment.isSame(endDatesAsMoment)) {
          day.cssClass = "bg_endDate";
        }

        if (dayAsMoment.isBetween(startDateAsMoment, endDatesAsMoment)) {
          day.cssClass = "bg_periodDays";
        }
        if (dayAsMoment.isSame(addDays(this.cycle.startDate, 1))) {
          day.cssClass = "bg_periodDays-firstDay";
        }
        if (dayAsMoment.isSame(subDays(this.cycle.endDate, 1))) {
          day.cssClass = "bg_periodDays-lastDay";
        }
      } else {
        day.cssClass = "";
      }
    });
  }

  emitDateChanges(cycles: Array<PayrollPeriodCycle>) {
    this.onSetDates.emit(cycles);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }
}
