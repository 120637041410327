<div class="section__payroll-periods-detail-calendar">
  <div class="payroll-periods-detail-calendar__month-navigator">
    <button
      type="button"
      class="button--calendar-cycle-navigator left"
      mwlCalendarPreviousView
      (click)="nextCycleInNewMonth()"
      [view]="view"
      [(viewDate)]="viewDate"
    >
      <img src="assets/img/application/icons/ic_chevron-left_grey.svg" />
    </button>
    <h4>{{ viewDate | date: "MMMM" | titlecase }}</h4>
    <button
      type="button"
      class="button--calendar-cycle-navigator right"
      mwlCalendarNextView
      (click)="nextCycleInNewMonth()"
      [view]="view"
      [(viewDate)]="viewDate"
    >
      <img src="assets/img/application/icons/ic_chevron-right_grey.svg" />
    </button>
  </div>
  <div class="payroll-periods-detail-calendar__navigator">
    <button
      class="button--calendar-cycle-navigator left"
      (click)="previousCycle()"
    >
      <img src="assets/img/application/icons/ic_chevron-left_grey.svg" />
    </button>
    <h5 class="strong">
      {{ this.viewState.currentViewingCycle.startDate | date: "d" }} -
      {{ this.viewState.currentViewingCycle.endDate | date: "d" }} de
      {{ this.viewState.currentViewingCycle.endDate | date: "LLLL" }},
      {{ this.viewState.currentViewingCycle.endDate | date: "yyyy" }}
    </h5>
    <button
      class="button--calendar-cycle-navigator right"
      (click)="nextCycle()"
    >
      <img src="assets/img/application/icons/ic_chevron-right_grey.svg" />
    </button>
  </div>
  <div class="payroll-periods-detail-calendar__content">
    <div class="payroll-periods-detail-calendar__calendar">
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          (beforeViewRender)="renderCurrentCycleEvents($event)"
        >
        </mwl-calendar-month-view>
      </div>
    </div>
    <div class="payroll-periods-detail-calendar__info">
      <div class="info__header">
        <p class="strong">Fechas importantes</p>
      </div>
      <div
        *ngIf="
          (this.viewState.currentViewingCycle.cutoffDate &&
            this.viewState.currentViewingCycle.paymentDate) ||
            !this.viewState.currentViewingCycle.newCycle;
          else dateUndefined
        "
        class="info__content"
      >
        <div class="info-payroll-dates">
          <div class="kind-of-day">
            <div class="oval--start"></div>
            <div class="title">
              <p class="soft">Inicio</p>
            </div>
          </div>
          <div class="date">
            <p>
              {{
                this.viewState.currentViewingCycle.startDate
                  | date: "EEEE"
                  | titlecase
              }}
              {{ this.viewState.currentViewingCycle.startDate | date: "d" }}
              de
              {{ this.viewState.currentViewingCycle.startDate | date: "LLLL" }},
              {{ this.viewState.currentViewingCycle.startDate | date: "yyyy" }}
            </p>
          </div>
        </div>
        <div class="info-payroll-dates">
          <div class="kind-of-day">
            <div class="oval--end"></div>
            <div class="title">
              <p class="soft">Fin</p>
            </div>
          </div>
          <div class="date">
            <p>
              {{
                this.viewState.currentViewingCycle.endDate
                  | date: "EEEE"
                  | titlecase
              }}
              {{ this.viewState.currentViewingCycle.endDate | date: "d" }}
              de
              {{ this.viewState.currentViewingCycle.endDate | date: "LLLL" }},
              {{ this.viewState.currentViewingCycle.endDate | date: "yyyy" }}
            </p>
          </div>
        </div>
        <div class="info-payroll-dates">
          <div class="kind-of-day">
            <div class="oval--cut"></div>
            <div class="title">
              <p class="soft">Corte</p>
            </div>
          </div>
          <div class="date">
            <p>
              {{
                this.viewState.currentViewingCycle.cutoffDate
                  | date: "EEEE"
                  | titlecase
              }}
              {{ this.viewState.currentViewingCycle.cutoffDate | date: "d" }}
              de
              {{
                this.viewState.currentViewingCycle.cutoffDate | date: "LLLL"
              }},
              {{ this.viewState.currentViewingCycle.cutoffDate | date: "yyyy" }}
            </p>
          </div>
        </div>
        <div class="info-payroll-dates">
          <div class="kind-of-day">
            <div class="oval--payment"></div>
            <div class="title">
              <p class="soft">Pago</p>
            </div>
          </div>
          <div class="date">
            <p>
              {{
                this.viewState.currentViewingCycle.paymentDate
                  | date: "EEEE"
                  | titlecase
              }}
              {{ this.viewState.currentViewingCycle.paymentDate | date: "d" }}
              de
              {{
                this.viewState.currentViewingCycle.paymentDate | date: "LLLL"
              }},
              {{
                this.viewState.currentViewingCycle.paymentDate | date: "yyyy"
              }}
            </p>
          </div>
        </div>
      </div>
      <ng-template #dateUndefined>
        <div class="define-dates">
          <div class="define-box">
            <div class="icon">
              <img
                src="assets/img/application/icons/ic_calendar-circle-blue_blue.svg"
                alt=""
              />
            </div>
            <div class="description">
              <p class="soft">No has definido las fechas del periodo</p>
            </div>
            <div class="define">
              <button
                (click)="emitNewCycle()"
                type="button"
                class="button--ghost-primary auto-w--all"
              >
                Define fechas
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="days-of-schema">
  <p>
    {{ this.viewState.currentViewingCycle.daysInCycle() }} días en el periodo
  </p>
</div>
