<div
  (click)="onClickCountdown()"
  [ngClass]="{
    'pointer-events-none': limitExceeded === undefined
  }"
>
  <div>
    <div *ngIf="!time">
      <ng-content></ng-content>
    </div>
    <div *ngIf="time">
      <a href="javascript:" class="countdown-text-disabled"
        >{{ legend }} {{ time }}</a
      >
    </div>
  </div>
</div>
