import { IPayrollPeriodResponse } from "@declarations/payroll-period-response";

export class PayrollPeriod {
  active: boolean;
  cutoffDate: string;
  paymentDate: string;
  id: string;
  name: string;
  paymentScheme: string;
  reconciliationDate: string;

  constructor(payrollPeriodAttrs: IPayrollPeriodResponse) {
    this.active = payrollPeriodAttrs.active;
    this.cutoffDate = payrollPeriodAttrs.cutoff_date;
    this.paymentDate = payrollPeriodAttrs.payment_date;
    this.id = payrollPeriodAttrs.id;
    this.name = payrollPeriodAttrs.name;
    this.paymentScheme = payrollPeriodAttrs.payment_scheme;
    this.reconciliationDate = payrollPeriodAttrs.reconciliation_date;
  }
}
