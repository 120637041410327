import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ArchwizardModule } from "angular-archwizard";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { SidebarModule } from "ng-sidebar";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NouisliderModule } from "ng2-nouislider";

//MAIN COMPONENTS
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from "./directives/sidebar.directive";
import { SidebarLinkDirective } from "./directives/sidebarlink.directive";
import { SidebarListDirective } from "./directives/sidebarlist.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebaranchortoggle.directive";
import { focusInputDirective } from "./directives/focus-form.directive";
import { CurrencyMaskDirective } from "./directives/currency-mask.directive";
import { PercentInputDirective } from "./directives/percentages-mask.directive";
import { CellPhoneMaskDirective } from "./directives/cellphone-mask.directive";
import { DragDropDirective } from "./directives/drag-drop.directive";
import { ClabeMaskDirective } from "./directives/clabe-mask.directive";
import { ChartsModule } from "ng2-charts";

// COMPONENTS
import { InputComponent } from "./components/input/input.component";
import { TableComponent } from "./components/table/table.component";
import { AddCommentsModalComponent } from "./components/modals/add-comments-modal/add-comments-modal.component";
import { CreatePayrollPeriodsCalendarComponent } from "./components/create-payroll-periods-calendar/create-payroll-periods-calendar.component";
import { PayrollPeriodsDetailCalendarComponent } from "./components/payroll-periods-detail-calendar/payroll-periods-detail-calendar.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { EditPayrollPeriodsCalendarComponent } from "./components/edit-payroll-periods-calendar/edit-payroll-periods-calendar.component";
import { CreateGroupPayrollPeriodsCalendarComponent } from "./components/create-group-payroll-periods-calendar/create-group-payroll-periods-calendar.component";
import { EmptyPayrollPeriodsModalComponent } from "./components/modals/empty-payroll-periods-modal/empty-payroll-periods-modal.component";
import { ViewStaggeredCommissionModalComponent } from "./components/modals/view-staggered-commission-modal/view-staggered-commission-modal.component";
import { OnboardingModalComponent } from "./components/modals/onboarding-modal/onboarding-modal.component";
import { LogOutComponent } from "./components/log-out/log-out.component";
import { DeductionsDetailComponent } from "./components/modals/deductions-detail/deductions-detail.component";
import { CycleOutRangeComponent } from "./components/modals/cycle-out-range/cycle-out-range.component";
import { OutstandingBalanceModalComponent } from "./components/modals/outstanding-balance-modal/outstanding-balance-modal.component";
import { NextPaymentsComponent } from "./components/modals/next-payments/next-payments.component";
import { DeductionsChargesComponent } from "./components/modals/deductions-charges-modal/deductions-charges-modal.component";
import { GoToLogsComponent } from "./components/go-to-logs/go-to-logs.component";
import { LogsModalComponent } from "./components/modals/logs-modal/logs-modal.component";
import { NgOtpInputModule } from "ng-otp-input";
import { CountdownComponent } from "./components/countdown/countdown.component";

// SERVICES
import { PageStateService } from "./services/page-state.service";

// Pipes
import { PhonePipe } from "./pipes/phone.pipe";
import { CollaboratorService } from "@services/collaborators.service";
import { UpdateUsersComponent } from "app/pages/full-layout-page/companies/update-users/update-users.component";
import { PasswordUpdatedSuccessfullyComponent } from "./components/password-updated-successfully/password-updated-successfully.component";

@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    TableComponent,
    NgxSmartModalModule,
    AddCommentsModalComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SidebarModule,
    RouterModule,
    focusInputDirective,
    CurrencyMaskDirective,
    PercentInputDirective,
    CellPhoneMaskDirective,
    DragDropDirective,
    ClabeMaskDirective,
    CreatePayrollPeriodsCalendarComponent,
    PayrollPeriodsDetailCalendarComponent,
    PaginationComponent,
    EditPayrollPeriodsCalendarComponent,
    CreateGroupPayrollPeriodsCalendarComponent,
    EmptyPayrollPeriodsModalComponent,
    ViewStaggeredCommissionModalComponent,
    OnboardingModalComponent,
    DeductionsDetailComponent,
    CycleOutRangeComponent,
    InfiniteScrollModule,
    NouisliderModule,
    ArchwizardModule,
    LogOutComponent,
    OutstandingBalanceModalComponent,
    NextPaymentsComponent,
    DeductionsChargesComponent,
    ChartsModule,
    GoToLogsComponent,
    LogsModalComponent,
    NgOtpInputModule,
    CountdownComponent,
    UpdateUsersComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule.forChild(),
    SidebarModule.forRoot(),
    ArchwizardModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    InfiniteScrollModule,
    NouisliderModule,
    ChartsModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    SidebarLinkDirective,
    SidebarListDirective,
    SidebarAnchorToggleDirective,
    InputComponent,
    TableComponent,
    AddCommentsModalComponent,
    focusInputDirective,
    CurrencyMaskDirective,
    PercentInputDirective,
    CellPhoneMaskDirective,
    DragDropDirective,
    ClabeMaskDirective,
    CreatePayrollPeriodsCalendarComponent,
    PayrollPeriodsDetailCalendarComponent,
    PaginationComponent,
    EditPayrollPeriodsCalendarComponent,
    CreateGroupPayrollPeriodsCalendarComponent,
    EmptyPayrollPeriodsModalComponent,
    ViewStaggeredCommissionModalComponent,
    OnboardingModalComponent,
    LogOutComponent,
    DeductionsDetailComponent,
    PhonePipe,
    CycleOutRangeComponent,
    OutstandingBalanceModalComponent,
    NextPaymentsComponent,
    DeductionsChargesComponent,
    GoToLogsComponent,
    LogsModalComponent,
    CountdownComponent,
    UpdateUsersComponent,
    PasswordUpdatedSuccessfullyComponent,
  ],
  providers: [
    PageStateService,
    CollaboratorService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: "es-MX" },
  ],
})
export class SharedModule {}
