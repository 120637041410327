import * as moment from "moment-timezone";
export const momentTz = (
  date: string,
  tz = localStorage.getItem("timezone")
) => {
  return setTimeZone(date, tz).format();
};

export const setTimeZone = (
  date: string,
  tz = localStorage.getItem("timezone")
) => {
  moment.locale("es-MX");
  return moment(date).tz(tz, true);
};

export const toLocalTz = (date: string, zone = moment().utcOffset()) => {
  return moment(
    moment(date).parseZone().tz(moment.tz.guess(), true).toISOString(true)
  );
};
