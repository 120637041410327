<ng-template #viewNextPayments>
  <div id="section__view-next-payment-modal">
    <div class="modal-header">
      <div class="dismiss">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeNextPaymentsModal()"
        >
          <img src="assets/img/application/icons/ic_close_black.svg" />
        </button>
      </div>
      <div class="title">
        <h4 class="h4-extend-small">Próximos pagos</h4>
        <h4 class="h4-extend-small strong">
          {{ totalToPay | currency: "MXN":"symbol-narrow" }}
        </h4>
      </div>
    </div>
    <div class="modal-content">
      <div class="company-periods">
        <ngb-accordion
          [destroyOnHide]="false"
          [closeOthers]="true"
          activeIds="static-1"
          #shadow
        >
          <ngb-panel *ngFor="let period of nextPayments">
            <ng-template ngbPanelTitle>
              <span>
                <div class="delayed-periods-title">
                  <h4 class="h4-extend-small">
                    {{ period.company }}
                  </h4>
                </div>
                <h4 class="strong">
                  {{ period.totalAmount | currency: "MXN":"symbol-narrow" }}
                </h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="current-periods">
                <ngb-accordion
                  [destroyOnHide]="false"
                  [closeOthers]="true"
                  activeIds="static-1"
                  #shadow
                >
                  <ngb-panel *ngFor="let dates of period.item">
                    <ng-template ngbPanelTitle>
                      <span>
                        <div class="delayed-periods-subtitle">
                          <h5 class="strong">
                            {{ dates.periodName }}
                            ({{ dates.startDate | date: "d" }}
                            -
                            {{ dates.endDate | date: "d" }}
                            de
                            {{ dates.endDate | date: "LLLL, yyyy" }})
                          </h5>
                        </div>
                        <h4 class="strong">
                          {{
                            dates.totalAmount + dates.totalCommission
                              | currency: "MXN":"symbol-narrow"
                          }}
                        </h4>
                      </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="content-tab">
                        <div class="delayed-periods-content">
                          <h5>Fecha de pago</h5>
                          <h5>
                            {{ dates.paymentDate | date: "d" }}
                            de
                            {{ dates.paymentDate | date: "LLLL" }}
                          </h5>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</ng-template>
