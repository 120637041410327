<ng-template #onboardingModal>
  <div id="section__onboarding-modal">
    <div class="modal-header">
      <h3 class="strong h3-extend-medium">
        ¡Bienvenido(a) a Monto!
      </h3>
      <img
        (click)="closeOnboardingModal()"
        src="assets/img/application/icons/ic_close_black.svg"
        alt=""
      />
    </div>
    <div class="modal-body">
      <h4>
        Completa este pequeño tutorial para conocer las principales
        funcionalidades de la plataforma.
      </h4>
    </div>
    <div class="modal-footer">
      <button (click)="openOnboarding()" class="button--thin img--right">
        Iniciar tutorial
        <img
          src="assets/img/application/icons/ic_chevron-right_white.svg"
          alt=""
        />
      </button>
    </div>
  </div>
</ng-template>
