import { IChargeResponse } from "@declarations/charge-response";

export class Charge {
  generatedInterest: number;
  id: string;
  interestWithTax: number;
  totalAmount: string;
  totalCommission: string;
  amountPaid: number;
  excelFile: string;
  pdfFile: string;
  folio: number;
  createdAt: Date;
  paymentStatus: string;
  payrollPeriod: string;
  startDate: Date;
  endDate: Date;
  paymentDate: Date;
  totalToPay: number;
  totalToPayTotalAmount: number;
  company: string;

  constructor(attrs: IChargeResponse) {
    this.generatedInterest = attrs.generated_interest;
    this.id = attrs.id;
    this.interestWithTax = attrs.interest_with_tax;
    this.totalAmount = attrs.total_amount;
    this.totalCommission = attrs.total_commission;
    this.amountPaid = Number(attrs.amount_paid);
    this.excelFile = attrs.excel_file ? attrs.excel_file.url : null;
    this.pdfFile = attrs.pdf_file ? attrs.pdf_file.url : null;
    this.folio = attrs.folio;
    this.createdAt = new Date(attrs.created_at);
    this.paymentStatus = attrs.payment_status;
    this.payrollPeriod = attrs.payroll_period;
    this.startDate = new Date(attrs.start_date);
    this.endDate = new Date(attrs.end_date);
    this.paymentDate = new Date(attrs.payment_date);
    this.totalToPay = attrs.total_to_pay;
    this.totalToPayTotalAmount = this.setTotalToPay(attrs);
    this.company = attrs.company;
  }

  setTotalToPay(attrs: IChargeResponse): number {
    if (attrs.payment_status == "unpaid") {
      return attrs.total_to_pay + attrs.interest_with_tax;
    } else if (attrs.payment_status == "paid") {
      return +attrs.amount_paid;
    }
  }
}
