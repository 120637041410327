import { IAddressResponse } from "@declarations/address-response";

export class Address {
  street: string;
  suburb: string;
  exteriorNumber: string;
  interiorNumber: string;
  postalCode: string;
  fullAddress: string;

  constructor(attrs: IAddressResponse) {
    this.street = attrs.street;
    this.suburb = attrs.suburb;
    this.exteriorNumber = attrs.exterior_number;
    if (attrs.interior_number == "null" || attrs.interior_number == null)
      this.interiorNumber = null;
    else this.interiorNumber = attrs.interior_number;
    this.postalCode = attrs.postal_code;
    this.fullAddress = attrs.full_address;
  }
}
