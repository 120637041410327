import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { CompaniesService } from "@services/companies.service";
import { ITableCollectionResponse } from "@declarations/table-collection-response";
import { Toastr } from "@services/toastr.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ITableOptionsForm } from "@declarations/table-options-form";
import { PermissionsService } from "@services/permissions.service";
import { CollaboratorService } from "@services/collaborators.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { CollaboratorDetail } from "@models/collaborator-detail";
import { IErrorResponse } from "@declarations/error-response";
import { HttpClient } from "@angular/common/http";
import { ITableSearchBarForm } from "@declarations/table-searchbar-form";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  providers: [CompaniesService],
})
export class TableComponent implements OnInit {
  formOptions: FormGroup;

  formSearchBar: FormGroup;

  formSearchBarEmployeeInfo: FormGroup;

  tableOptions: ITableOptionsForm;

  tableSearchBar: ITableSearchBarForm;

  tableSearchBarEmployeeInfo: ITableSearchBarForm;

  idCheckbox: Array<string> = [];

  isEmptyState = false;

  items: Array<object> = [];

  isSorting = false;

  isSortingAsc = false;

  @Input() set tableOptionsForm(tableOptionsForm: ITableOptionsForm) {
    if (tableOptionsForm) {
      this.tableOptions = tableOptionsForm;
      const group = {};
      this.tableOptions.inputs.forEach((res) => {
        group[res.id] = new FormControl(res.collectionSelected);
      });
      this.formOptions = new FormGroup(group);
    }
  }

  @Input() set tableSearchBarForm(tableSearchbarForm: ITableSearchBarForm) {
    if (tableSearchbarForm) {
      this.tableSearchBar = tableSearchbarForm;
      const search = {};
      this.tableSearchBar.inputs.forEach((res) => {
        search[res.id] = new FormControl(res.content);
      });
      this.formSearchBar = new FormGroup(search);
    }
  }

  @Input() set tableSearchBarEmployeeInfoForm(
    tableSearchBarEmployeeInfoForm: ITableSearchBarForm
  ) {
    if (tableSearchBarEmployeeInfoForm) {
      this.tableSearchBarEmployeeInfo = tableSearchBarEmployeeInfoForm;
      const search = {};
      this.tableSearchBarEmployeeInfo.inputs.forEach((res) => {
        search[res.id] = new FormControl(res.content);
      });
      this.formSearchBarEmployeeInfo = new FormGroup(search);
    }
  }

  @Input() optionsAvailable = false;
  @Input() tableHeaders: Array<object> = [];
  @Input() set tableItems(value: ITableCollectionResponse) {
    if (value) {
      if (value.pagination.currentPage == 1) {
        this.items = [];
        this.isEmptyState = false;
        this.idCheckbox = [];
      }
      if (value.items.length < 1) {
        this.isEmptyState = true;
      }
      value.items.forEach((element: object) => {
        this.items.push(element);
      });
      this.emitNextPage(value.pagination);
    }
  }

  /** DEPRECATE clickRedirect. Component should only notify that it was clicked */
  @Input() clickRedirect: string;
  @Input() title: string;

  @Output()
  rowClicked: EventEmitter<object> = new EventEmitter();
  @Output() enable: EventEmitter<object> = new EventEmitter();
  @Output() disable: EventEmitter<object> = new EventEmitter();
  @Output() status: EventEmitter<object> = new EventEmitter();
  @Output() statusCollabInfo: EventEmitter<object> = new EventEmitter();
  @Output() valid: EventEmitter<object> = new EventEmitter();
  @Output() paymentBy: EventEmitter<object> = new EventEmitter();
  @Output() download: EventEmitter<object> = new EventEmitter();
  @Output() generateDownloadFiles: EventEmitter<object> = new EventEmitter();
  @Output() togglePennyCheck: EventEmitter<object> = new EventEmitter();
  @Output() nextPage: EventEmitter<object> = new EventEmitter();
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleSorting: EventEmitter<object> = new EventEmitter();
  @Output() checkboxOnChange: EventEmitter<Array<string>> = new EventEmitter();
  @Output() selectOptionsOnChange: EventEmitter<
    Array<string>
  > = new EventEmitter();
  @Output() selectSearchBarOnChange: EventEmitter<
    Array<string>
  > = new EventEmitter();
  @Output() selectSearchBarEmployeeInfoOnChange: EventEmitter<
    Array<string>
  > = new EventEmitter();

  collaborator: CollaboratorDetail;

  responseError = null;

  isLoading: boolean;

  _opened = false;

  superadmin = false;

  constructor(
    private router: Router,
    private toastr: Toastr,
    private collaboratorService: CollaboratorService,
    private permissionsService: PermissionsService
  ) {
    /** No content */
  }

  ngOnInit(): void {
    this.superadmin = this.permissionsService.isSuperadminVisible();
  }

  emitDownloadCEP(item, format: string) {
    this.download.emit({ id: item["id"], format: format });
  }

  emitDownloadExcel(item: any) {
    this.download.emit(item["excelFile"]);
  }

  emitDownloadPDF(item: any) {
    this.download.emit(item["pdfFile"]);
  }

  emitGenerateFiles(item: any) {
    this.generateDownloadFiles.emit(item["folio"]);
  }

  emitDisable(item: any) {
    this.disable.emit(item["id"]);
  }

  emitEnable(item: any) {
    this.enable.emit(item["id"]);
  }

  emitStatus(item: any, status: any) {
    this.status.emit({ id: item["id"], status: status });
  }

  emitStatusInfoCollab(item: any, status: any) {
    this.statusCollabInfo.emit({ employee_status: status, ids: [item["id"]] });
  }

  emitPaymentByWithdrawal(item: any, status: any) {
    this.paymentBy.emit({ id: item["id"], status: status });
  }

  emitPennyCheck(item: any, status: boolean) {
    this.togglePennyCheck.emit({ id: item["id"], status: status });
  }

  emitNextPage(item: any) {
    this.nextPage.emit(item);
  }

  emitToggleSidebar() {
    this.toggleSidebar.emit(true);
  }

  emitSelectOptionsOnChange(item) {
    this.selectOptionsOnChange.emit(item);
  }

  emitSelectSearchBarOnChange(item) {
    this.selectSearchBarOnChange.emit(item);
  }

  emitSelectSearchBarEmployeeInfoOnChange(item) {
    this.selectSearchBarEmployeeInfoOnChange.emit(item);
  }

  emitValidate(item: any) {
    this.valid.emit(item["id"]);
  }

  handleRedirect(item: object) {
    if (this.clickRedirect) {
      const url = this.clickRedirect.split("/");

      this.router.navigate([...url, item["id"]]);
    }
  }

  notifyRowClicked(item: object) {
    this.rowClicked.emit(item);
  }

  /** TODO type object emit */
  enableSorting(index) {
    this.isSorting = true;
    this.isSortingAsc = true;
    this.tableHeaders["columns"][index]["is_sorting"] = this.isSorting;
    this.toggleSorting.emit({
      sortColumn: this.tableHeaders["columns"][index]["column"],
      sortParam: this.tableHeaders["columns"][index]["sort_param"],
      sortOrder: "ASC",
      isSorting: this.isSorting,
    });
  }

  disableSorting(index) {
    this.isSorting = false;
    this.tableHeaders["columns"][index]["is_sorting"] = this.isSorting;
    this.toggleSorting.emit({
      sortParam: null,
      sortOrder: null,
      isSorting: this.isSorting,
    });
  }

  toggleAscDescSorting(index) {
    this.isSortingAsc = !this.isSortingAsc;
    if (this.isSortingAsc) {
      this.toggleSorting.emit({
        sortColumn: this.tableHeaders["columns"][index]["column"],
        sortParam: this.tableHeaders["columns"][index]["sort_param"],
        sortOrder: "ASC",
        isSorting: true,
      });
    } else {
      this.toggleSorting.emit({
        sortColumn: this.tableHeaders["columns"][index]["column"],
        sortParam: this.tableHeaders["columns"][index]["sort_param"],
        sortOrder: "DESC",
        isSorting: true,
      });
    }
  }

  selectCheckbox(item) {
    const isInArray = this.idCheckbox.indexOf(item["id"]);

    if (isInArray == -1) {
      this.idCheckbox.push(item["id"]);
    } else {
      this.idCheckbox.splice(isInArray, 1);
    }

    this.checkboxOnChange.emit(this.idCheckbox);
  }

  getCollaborator(id: string) {
    const assignCollaboratorsToLocalAttribute = (
      collaborator: CollaboratorDetail
    ) => {
      this.collaborator = collaborator;
    };

    const handleError = (response: IErrorResponse): void => {
      this.toastr.error(response);
    };

    this.collaboratorService
      .getCollaborator(id)
      .subscribe(assignCollaboratorsToLocalAttribute, handleError);
  }

  checkAll(ev) {
    if (ev.target.checked) {
      this.idCheckbox = [];
      this.items.forEach((items) => {
        if (!["pending_admin_validation"].includes(items["activeStatus"])) {
          this.idCheckbox.push(items["id"]);
        }
      });
      this.checkboxOnChange.emit(this.idCheckbox);
    } else {
      this.idCheckbox = [];
      this.checkboxOnChange.emit(this.idCheckbox);
    }

    //Checked toggle
    this.items.forEach((x) => (x["checkboxValue"] = ev.target.checked));
  }

  isAllChecked() {
    return this.items.every((_) => _["checkboxValue"]);
  }
}
