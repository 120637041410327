import { ICollaboratorDetailResponse } from "@declarations/collaborator-detail-response";

export class CollaboratorDetail {
  accountType: string;
  activeStatus: string;
  bankId: string;
  bankName: string;
  cardNumber: string;
  cellphone: string;
  clabe: string;
  collaboratorId: string;
  dateOfBirth: string;
  department: string;
  email: string;
  firstName: string;
  fullName: string;
  group: string;
  groupId: string;
  hiringDate: string;
  id: string;
  isPennyChecked: boolean;
  lastName: string;
  netSalary: string;
  registrationStatus: string;
  requestedMontos: number;
  rfc: string;
  role: string;

  constructor(attrs: ICollaboratorDetailResponse) {
    this.accountType = attrs.employee.account_type;
    this.activeStatus = attrs.employee.active_status;
    this.bankId = attrs.employee.bank_id;
    this.bankName = attrs.employee.bank_name;
    this.cardNumber = attrs.employee.card_number;
    this.cellphone = attrs.employee.cellphone;
    this.clabe = attrs.employee.clabe;
    this.collaboratorId = attrs.employee.collaborator_id;
    this.dateOfBirth = attrs.employee.date_of_birth;
    this.department = attrs.employee.department;
    this.email = attrs.employee.email;
    this.firstName = attrs.employee.first_name;
    this.fullName = attrs.employee.full_name;
    this.group = attrs.employee.group;
    this.groupId = attrs.employee.group_id;
    this.hiringDate = attrs.employee.hiring_date;
    this.id = attrs.employee.id;
    this.isPennyChecked = attrs.employee.is_penny_checked;
    this.lastName = attrs.employee.last_name;
    this.netSalary = attrs.employee.net_salary;
    this.registrationStatus = attrs.employee.registration_status;
    this.requestedMontos = attrs.employee.requested_montos;
    this.rfc = attrs.employee.rfc;
    this.role = attrs.employee.role;
  }
}
