import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class UpdateUsersService {
  constructor(private httpClient: HttpClient) {}

  updateUsers(payload: FormData) {
    return this.httpClient.post(
      `${environment.API}/employees/batch_update`,
      payload,
      { responseType: "text" }
    );
  }

  superUpdateUsers(payload: FormData, companyId: string) {
    return this.httpClient.post(
      `${environment.API}/employees/batch_update?company_id=${companyId}`,
      payload,
      { responseType: "text" }
    );
  }
}
