import { IGroupResponse } from "@declarations/group-response";

export class Group {
  collaboratorsCount: number;
  id: string;
  isActive: boolean;
  name: string;
  paymentFrequency: string;
  payrollPeriod: string;
  percentageSalaryToWithdraw: string;
  withdrawalsLimit: number;

  constructor(attrs: IGroupResponse) {
    this.collaboratorsCount = attrs.collaborators_count;
    this.id = attrs.id;
    this.isActive = attrs.is_active;
    this.name = attrs.name;
    this.paymentFrequency = attrs.payment_frequency;
    this.payrollPeriod = attrs.payroll_period;
    this.percentageSalaryToWithdraw = attrs.percentage_salary_to_withdraw;
    this.withdrawalsLimit = attrs.withdrawals_limit;
  }
}
