import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class Toastr {
  messageArray: Array<string>;

  constructor(private toastr: ToastrService) {}

  success(message?: string, title?: string) {
    this.toastr.success(message, title);
  }

  err;
  error(errorMessages) {
    if (errorMessages.status === 500) {
      const message = "ERROR 500";
      this.single_error(message);
    } else if (errorMessages.status === 404) {
      this.toastr.error(errorMessages.error.errors);
    } else if (errorMessages.status === 400) {
      this.messageArray = errorMessages.error?.errors;
      if (typeof errorMessages.error?.errors === "object") {
        this.messageArray = Object.keys(errorMessages.error?.errors);
        this.messageArray?.forEach((key) => {
          if (errorMessages.error?.errors[key] === "array") {
            errorMessages.error?.errors[key].forEach((error) => {
              this.toastr.error(`${error}`);
            });
          } else {
            this.toastr.error(`${errorMessages.error?.errors[key]}`);
          }
        });
      } else {
        const ans = Array.isArray(this.messageArray);
        if (ans) {
          this.messageArray?.forEach((error) => {
            this.toastr.error(error);
          });
        } else {
          this.err = { errors: this.messageArray };
          this.toastr.error(this.err.errors);
        }
      }
    } else if (errorMessages.status === 0) {
      this.toastr.error(errorMessages.error.errors);
    } else {
      if (errorMessages.length >= 0) {
        this.messageArray = errorMessages.error.errors;
        this.messageArray.forEach((error) => {
          this.toastr.error(error);
        });
      } else {
        this.messageArray = ["error"];
      }
    }
  }

  single_error(message) {
    this.toastr.error(message);
  }
}
